import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import submitForm from "@/helpers/submitForm";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VSimpleTable } from "vuetify/lib";
import { VTextarea } from "vuetify/lib/components";
import { mapGetters } from "vuex";
import Dialog from "../Dialog";

const NlpTesterModelPredictDialog = Vue.extend({
  data: () => ({
    fields: {
      text: "",
      count: 3,
    },
    result: {} as any,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),
  },

  methods: {
    async handleSubmit() {
      const { language, name } = this.GET_DIALOG_DATA(
        Dialogs.NLP_TESTER_MODEL_PREDICT
      );

      const { isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: `${ApiEndpoints.NLP_TESTER_MODELS}/${language}/model/${name}/predict`,
        values: this.fields,
        actionId: Actions.NLP_TESTER_MODEL_PREDICT,
      });
      if (isFormSubmited) {
        this.result = data;
      }
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.NLP_TESTER_MODEL_PREDICT}
        title="Prediction test"
        toggleHandler={() => (this.result = {})}
        actions={[
          {
            title: "Test",
            icon: "mdi-check",
            id: Actions.NLP_TESTER_MODEL_PREDICT,
            action: () => this.handleSubmit(),
          },
        ]}
      >
        <VForm ref="form">
          <VTextarea
            rows={1}
            vModel={this.fields.text}
            label="Text sample"
            spellcheck={false}
          />
        </VForm>

        {this.result?.predictions && (
          <div class="mt-4">
            <span class="text-subtitle-1 font-weight-bold">Results:</span>
            <VSimpleTable class="mt-2">
              <tbody>
                {this.result?.predictions.map(
                  ({ category, probability }: any, index: number) => (
                    <tr>
                      <td
                        class={
                          index === 0 ? "primary--text font-weight-bold" : ""
                        }
                      >
                        {category}
                      </td>
                      <td
                        class={
                          index === 0 ? "primary--text font-weight-bold" : ""
                        }
                      >
                        {(probability * 100).toFixed(2)}%
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </VSimpleTable>
          </div>
        )}
      </Dialog>
    );
  },
});

export default NlpTesterModelPredictDialog;
