import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VSelect } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const LanguageWithVoiceSelect = Vue.extend({
  name: "LanguageWithVoiceSelect",
  data: () => ({
    languageCode: "",
    ttsVoice: "",
  }),

  props: {
    value: String,
    outlined: Boolean,
  },

  computed: {
    ...mapGetters(Modules.RESOURCES, [Getters.LANGUAGES]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    languagesList(): any {
      return (
        this.LANGUAGES.map(({ id, name }: any) => ({
          text: name,
          value: id,
        })) || []
      );
    },

    languageTtsList(): any {
      const selectedLanguage = this.LANGUAGES.find(
        ({ id }: any) => id === this.languageCode
      );

      const ttsVoices = selectedLanguage?.ttsSettings?.map(
        ({ voices, engine }: any) =>
          voices.map(({ tts }: any) => ({
            text: `${tts} (${engine})`,
            value: tts,
          }))
      );

      return ttsVoices
        ? []
            .concat(...ttsVoices)
            .sort((a: any, b: any) => (a.text > b.text ? 1 : -1))
        : [];
    },

    isFetching(): boolean {
      return this.IS_ACTION_FETCHING(Actions.LANGUAGES_FETCH);
    },
  },
  methods: {
    ...mapActions(Modules.RESOURCES, [Actions.LANGUAGES_FETCH]),

    async initDropdowns(): Promise<void> {
      this.languageCode = this.value || "";

      if (this.LANGUAGES.length > 0) {
        this.ttsVoice = this.languageTtsList[0].value;
        return;
      }

      const areLanguagesFetched = await this.LANGUAGES_FETCH();
      areLanguagesFetched
        ? (this.ttsVoice = this.languageTtsList[0].value)
        : null;
    },

    getLanguageConfig(): any {
      const selectedLanguage = this.LANGUAGES.find(
        ({ id }: any) => id === this.languageCode
      );

      const ttsVoiceSettings = selectedLanguage.ttsSettings
        .map(({ engine, voices }: any) => {
          const voiceConfig = voices.find(
            ({ tts }: any) => tts === this.ttsVoice
          );

          if (voiceConfig) {
            return {
              ttsEngine: engine,
              ttsLanguageCode: voiceConfig.code,
              ttsVoiceName: voiceConfig.tts,
            };
          }
        })
        .filter((item: any) => item !== undefined)[0];

      return {
        ...ttsVoiceSettings,
        languageCode: selectedLanguage.id,
        languageName: selectedLanguage.name,
      };
    },
  },
  created() {
    this.initDropdowns();
  },
  render(): VNode {
    return (
      <div>
        <VSelect
          items={this.languagesList}
          label="Default Language"
          vModel={this.languageCode}
          onInput={() => (this.ttsVoice = this.languageTtsList[0].value)}
          loading={this.isFetching}
          disabled={this.isFetching}
          outlined={this.outlined}
        />
        <VSelect
          items={this.languageTtsList}
          label="Default TTS Voice"
          vModel={this.ttsVoice}
          loading={this.isFetching}
          disabled={this.isFetching}
          outlined={this.outlined}
        />
      </div>
    );
  },
});

export default LanguageWithVoiceSelect;
