import { GetterTree } from "vuex";
import { Getters } from "@/models/store";
import ResourcesState from "@/models/store/ResourcesState";

const getters: GetterTree<ResourcesState, []> = {
  [Getters.LANGUAGES]: (state) => state.languages,

  [Getters.TAGS]: (state) => state.tags,
};

export default getters;
