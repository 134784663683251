import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const LineMonitoringRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    monitoringData(): any {
      return this.GET_DIALOG_DATA(Dialogs.LINE_MONITORING_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_MONITORING_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.LINE_MONITORING_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            id: Actions.LINE_MONITORING_REMOVE,
            action: () => this.LINE_MONITORING_REMOVE(this.monitoringData),
          },
        ]}
      >
        Do you really want to remove "
        <strong class="primary--text">{this.monitoringData.name}</strong>"
        monitoring?
      </Dialog>
    );
  },
});

export default LineMonitoringRemoveDialog;
