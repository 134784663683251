import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextarea } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import MonacoEditor from "../MonacoEditor";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";

const GrammarContentEditForm = Vue.extend({
  data: () => ({
    fields: {
      content: "",
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_GRAMMAR]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.GRAMMAR_OVERVIEW_FETCH]),

    async generateSamples(
      category: string,
      samples: string[],
      count: number,
      language: string
    ): Promise<void> {
      const request = await createRequest({
        action: Actions.NLP_CATEGORY_SAMPLES_GENERATE,
        method: "POST",
        url: `${ApiEndpoints.GRAMMAR_GENERATE_SAMPLES}/${language}/entities`,
        data: { category, samples, count },
      });

      const { data, errors } = parseResponse(request);

      if (errors.length > 0) {
        return;
      }

      toggleDialog(Dialogs.NLP_TESTER_SAMPLES_GENERATE, {});

      this.fields.content = `${this.fields.content ?? ""}${
        this.fields.content?.length > 0 ? "\r\n" : ""
      }${data.join("\r\n")}`;
    },

    async handleSubmit() {
      const instance = this.INSPECTED_GRAMMAR?.basic?.content;
      const content =
        instance?.type === 2
          ? this.fields.content
          : this.fields.content.replace(/\n/, "\r\n");

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.GRAMMAR_BASIC,
        values: {
          name: instance.name,
          idTenant: instance.idTenant || null,
          content,
        },
        params: { idGrammar: this.$route.params.idGrammar },
        actionId: Actions.GRAMMAR_OVERVIEW_EDIT,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.GRAMMAR_EDIT_CONTENT, {});
        pushNotify(Notify.SUCCESS, "Grammar created successfully");

        this.GRAMMAR_OVERVIEW_FETCH({
          idGrammar: this.$route.params.idGrammar,
        });
      }
    },
  },

  created() {
    this.fields.content = this.INSPECTED_GRAMMAR?.basic?.content?.content;
  },

  render(): VNode {
    return (
      <VForm ref="form">
        {this.INSPECTED_GRAMMAR?.basic?.content?.type === 2 ? (
          <MonacoEditor
            value={this.fields.content}
            onChange={(val: string) => (this.fields.content = val)}
            language="xml"
          />
        ) : (
          <VTextarea
            vModel={this.fields.content}
            label="Content"
            auto-grow
            autofocus
            spellcheck={false}
          />
        )}
      </VForm>
    );
  },
});

export default GrammarContentEditForm;
