import Vue, { VNode } from "vue";
import { VCard, VCardText, VCol, VRow, VSkeletonLoader } from "vuetify/lib";
import SectionToolbar from "../SectionToolbar";
import DataTextOverview from "../DataTextOverview";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import UserPreferencesEditDialog from "../UserPreferencesEditDialog";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import USER_PREFERENCES from "@/constants/userPreference";

const UserPreferences = Vue.extend({
  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    overviewItems(): any {
      return Object.entries(this.INSPECTED_USER?.preferences ?? {}).map(
        ([key, value]) => ({
          name: key,
          value: USER_PREFERENCES[key]?.textModifier
            ? USER_PREFERENCES[key]?.textModifier(value)
            : value,
          title: USER_PREFERENCES[key]?.label,
        })
      );
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_PREFERENCES_FETCH]),
  },

  created() {
    const idUser = this.$route.params.idUser ?? this.USER_INFO?.id;
    this.USER_PREFERENCES_FETCH({ idUser });
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="User preferences"
          actions={[
            {
              icon: "mdi-pencil",
              action: () => toggleDialog(Dialogs.USER_PREFERENCES, {}),
            },
          ]}
        />
        <VCard flat>
          <VCardText class="mt-0 pt-0">
            {this.IS_ACTION_FETCHING(Actions.USER_PREFERENCES_FETCH) ? (
              <VRow class="mt-4">
                {Object.keys(USER_PREFERENCES).map(() => (
                  <VCol md={6}>
                    <VSkeletonLoader type="chip" />
                  </VCol>
                ))}
              </VRow>
            ) : (
              <DataTextOverview items={this.overviewItems} />
            )}
          </VCardText>
        </VCard>

        <UserPreferencesEditDialog />
      </div>
    );
  },
});

export default UserPreferences;
