import Vue, { VNode } from "vue";
import { VCard, VCardText, VIcon, VTooltip } from "vuetify/lib";

const CampaignDataCard = Vue.extend({
  props: {
    value: [Number, String],
    valueColor: {
      type: String,
      default: "primary",
    },
    title: String,
    icon: String,
    iconColor: {
      type: String,
      default: "primary",
    },
    noRightMargin: {
      default: false,
      type: Boolean,
    },
    hint: String,
  },

  render(): VNode {
    return (
      <VCard
        class={`${this.noRightMargin ? "" : "mr-5"} align-self-start`}
        style="flex: 1"
      >
        <VCardText class="d-flex align-center justify-space-between py-3">
          <div>
            <div class={`text-h5 ${this.valueColor}--text font-weight-bold`}>
              {this.value}
            </div>
            <div class="d-flex align-center">
              {this.title}{" "}
              {this.hint && (
                <VTooltip
                  bottom
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => (
                      <VIcon class="ml-2" color="accent" {...{ on, attrs }}>
                        mdi-help-circle
                      </VIcon>
                    ),
                  }}
                >
                  {this.hint}
                </VTooltip>
              )}
            </div>
          </div>
          <VIcon class="mr-2" color={this.iconColor} xLarge>
            {this.icon}
          </VIcon>
        </VCardText>
      </VCard>
    );
  },
});

export default CampaignDataCard;
