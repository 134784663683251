import TAG_COLOR_PALETTE from "@/constants/tagColorPalette";
import TagColor from "@/models/TagColor";

export const getTagColorConfig = (colorId: string): TagColor | null => {
  return (
    TAG_COLOR_PALETTE.find(
      (colorConfig: TagColor) => colorConfig.id === colorId
    ) ?? null
  );
};

export const getTagColorClass = (colorId: string): string => {
  const tagColorConfig: TagColor | null = getTagColorConfig(colorId);

  return tagColorConfig
    ? `${tagColorConfig.bg} ${tagColorConfig.text}--text`
    : "";
};
