import Dialogs from "@/constants/dialogs";
import getDaysOfWeekFromValues from "@/helpers/getDaysOfWeekFromValues";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VChip,
  VDataTable,
  VIcon,
  VList,
  VListItem,
  VListItemContent,
  VListItemIcon,
  VListItemTitle,
  VMenu,
} from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import LineMonitoringRemoveDialog from "../LineMonitoringRemoveDialog";
import LineMonitoringStateToggleDialog from "../LineMonitoringStateToggleDialog";

const LineMonitoringOverview = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_MONITORING_FETCH]),
  },

  created() {
    this.LINE_MONITORING_FETCH({
      idDialModuleLine: this.$route.params.idDialModuleLine,
    });
  },

  render(): VNode {
    return (
      <div>
        <VDataTable
          headers={[
            { text: "Name", value: "title" },
            { text: "Operating days", value: "dow" },
            { text: "Operating time", value: "time" },
            { text: "Interval (min)", value: "interval" },
            { text: "Notification groups", value: "notifications" },
            { text: "Status", value: "enable" },
            { text: "Actions", value: "actions" },
          ]}
          items={this.INSPECTED_LINE?.monitoring?.content}
          loading={this.IS_ACTION_FETCHING(Actions.LINE_MONITORING_FETCH)}
          hideDefaultFooter
          disableSort
          itemKey="name"
          scopedSlots={{
            "item.time": ({ item }: any) =>
              `${item.timeStart ?? ""} - ${item.timeEnd ?? ""}`,
            "item.enable": ({ item }: any) => (
              <VChip color={item.enable ? "success" : "error"}>
                {item.enable ? "Enabled" : "Disabled"}
              </VChip>
            ),
            "item.dow": ({ item }: any) =>
              getDaysOfWeekFromValues(item.dow, true).join(", "),
            "item.actions": ({ item, index }: any) => (
              <span>
                <VBtn
                  onClick={() =>
                    toggleDialog(Dialogs.LINE_MONITORING, { index })
                  }
                  fab
                  xSmall
                  class="tertiary white--text"
                >
                  <VIcon>mdi-cog</VIcon>
                </VBtn>
                <VMenu
                  offset-y
                  scopedSlots={{
                    activator: ({ on, attrs }: never) => (
                      <VBtn
                        class="secondary primary--text ml-2"
                        fab
                        xSmall
                        {...{ on, attrs }}
                      >
                        <VIcon>mdi-dots-horizontal</VIcon>
                      </VBtn>
                    ),
                  }}
                >
                  <VList dense minWidth="200">
                    <VListItem
                      onClick={() =>
                        toggleDialog(Dialogs.LINE_MONITORING_STATE_TOGGLE, {
                          idMonitoring: index,
                          idDialModuleLine: this.$route.params.idDialModuleLine,
                          name: item.title,
                          enable: item.enable,
                        })
                      }
                    >
                      <VListItemIcon>
                        <VIcon color="primary">
                          {" "}
                          {item.enable
                            ? "mdi-toggle-switch-off-outline"
                            : "mdi-toggle-switch"}
                        </VIcon>
                      </VListItemIcon>
                      <VListItemContent>
                        <VListItemTitle>
                          {item.enable ? "Disable" : "Enable"}
                        </VListItemTitle>
                      </VListItemContent>
                    </VListItem>
                    <VListItem
                      onClick={() =>
                        toggleDialog(Dialogs.LINE_MONITORING_REMOVE, {
                          idMonitoring: index,
                          idDialModuleLine: this.$route.params.idDialModuleLine,
                          name: item.title,
                        })
                      }
                    >
                      <VListItemIcon>
                        <VIcon color="primary">mdi-delete</VIcon>
                      </VListItemIcon>
                      <VListItemContent>
                        <VListItemTitle>Remove</VListItemTitle>
                      </VListItemContent>
                    </VListItem>
                  </VList>
                </VMenu>
              </span>
            ),
          }}
        />
        <LineMonitoringRemoveDialog />
        <LineMonitoringStateToggleDialog />
      </div>
    );
  },
});

export default LineMonitoringOverview;
