import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VCard, VCardText, VCardTitle, VDataTable, VIcon } from "vuetify/lib";
import { mapGetters } from "vuex";

const BillingOverviewDataGrid = Vue.extend({
  props: {
    title: String,
    icon: String,
    data: Array,
    appendHeaders: { type: Array, default: () => [] },
  },

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  render(): VNode {
    return (
      <VCard
        loading={this.IS_ACTION_FETCHING(Actions.TENANT_BILLING_FETCH)}
        class="mx-2 mb-5"
      >
        {this.title && (
          <VCardTitle class="text-h6">
            {this.icon && <VIcon left>{this.icon}</VIcon>}
            {this.title}
          </VCardTitle>
        )}
        <VCardText>
          {this.data && (
            <div>
              <VDataTable
                headers={[
                  ...this.appendHeaders,
                  {
                    text: "Total calls",
                    value: "calls",
                  },
                  {
                    text: "Calls duration (min)",
                    value: "durationInMinutes",
                  },
                  {
                    text: "Inbound (min)",
                    value: "durationInboundInMinutes",
                  },
                  {
                    text: "Outbound (min)",
                    value: "durationOutboundInMinutes",
                  },
                  {
                    text: "SMS",
                    value: "smsCount",
                  },
                  {
                    text: "Price",
                    value: "price",
                    class: "text-end",
                    align: "right",
                    width: 160,
                  },
                ]}
                items={this.data}
                items-per-page={-1}
                item-class="text-end"
                hideDefaultFooter
                scopedSlots={{
                  "item.price": ({ item }: any) => (
                    <span class="font-weight-bold text-right">
                      {item.price?.toLocaleString("cs", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      {item.currency ? (
                        item.currency
                      ) : (
                        <span class="grey--text">N/A</span>
                      )}
                    </span>
                  ),

                  "item.calls": ({ item }: any) =>
                    item.calls?.toLocaleString("cs"),

                  "item.durationInMinutes": ({ item }: any) =>
                    (item.durationInMinutes ?? 0).toLocaleString("cs"),

                  "item.durationInboundInMinutes": ({ item }: any) => (
                    <span>
                      <VIcon color="success" small class="mr-2">
                        mdi-phone-incoming
                      </VIcon>
                      {(item.durationInboundInMinutes ?? 0).toLocaleString(
                        "cs"
                      )}
                    </span>
                  ),

                  "item.durationOutboundInMinutes": ({ item }: any) => (
                    <span>
                      <VIcon color="warning" small class="mr-2">
                        mdi-phone-outgoing
                      </VIcon>
                      {(item.durationOutboundInMinutes ?? 0).toLocaleString(
                        "cs"
                      )}
                    </span>
                  ),

                  "item.smsCount": ({ item }: any) => (
                    <span>
                      <VIcon color="blue" small class="mr-2">
                        mdi-message
                      </VIcon>
                      {item.smsCount}
                    </span>
                  ),
                }}
              />
            </div>
          )}
        </VCardText>
      </VCard>
    );
  },
});

export default BillingOverviewDataGrid;
