import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Dropdowns from "@/constants/dropdowns";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { getTagColorClass } from "@/helpers/tagColorConfig";
import toggleDialog from "@/helpers/toggleDialog";
import { Modules, Mutations } from "@/models/store";
import Tag from "@/models/Tag";
import Vue, { VNode } from "vue";
import { VBtn, VChip, VIcon } from "vuetify/lib";
import { mapMutations } from "vuex";
import DynamicSelect from "../DynamicSelect";
import TagCreateDialog from "../TagCreateDialog";

const TagsSelect = Vue.extend({
  props: {
    value: [Array],
    options: Object,
    canSelectAll: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapMutations(Modules.DROPDOWNS, [Mutations.ADD_DROPDOWN_DATA]),

    transformData(data: any): any {
      if (Object.keys(data).length === 0) {
        return [];
      }

      return Object.values(data).map(({ title, ...tagProps }: any) => ({
        text: title,
        value: { title, ...tagProps },
      }));
    },
  },

  render(): VNode {
    return (
      <div>
        <DynamicSelect
          name={Dropdowns.TAGS}
          label="Tags"
          fetchUrl={ApiEndpoints.TAGS_LIST}
          onInput={(val: string) => this.$emit("input", val)}
          slots={{
            appendOuter: () =>
              hasUserPermissions(Namespaces.CALLSCRIPT, [
                Permissions.EDIT_TAGS,
                Permissions.CREATE_TAGS,
              ]) && (
                <VBtn
                  class="tertiary white--text"
                  xSmall
                  fab
                  onClick={() => toggleDialog(Dialogs.TAG_CREATE, {})}
                >
                  <VIcon>mdi-plus</VIcon>
                </VBtn>
              ),
            selection: (item: any) => (
              <VChip
                on={{ "click:close": () => this.$emit("selectionClose", item) }}
                color={getTagColorClass(item.value?.color)}
                close
              >
                {item.text}
              </VChip>
            ),
          }}
          options={{
            multiple: true,
            chips: true,
            smallChips: true,
            deletableChips: true,
            ...this.options,
          }}
          value={this.value}
          useCache={false}
          dataModificator={(data: any) => this.transformData(data)}
        />
        <TagCreateDialog
          onTagCreated={(tag: Tag) => {
            this.ADD_DROPDOWN_DATA({
              dropdown: Dropdowns.TAGS,
              payload: [{ text: tag.title, value: tag }],
            });

            this.$emit("tagCreated", tag);
          }}
        />
      </div>
    );
  },
});

export default TagsSelect;
