import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VCombobox, VForm, VTextarea } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import NlpPreprocessOptionsSelect from "../NlpPreprocessOptionsSelect";

const NlpTesterModelTrainForm = Vue.extend({
  data: () => ({
    fields: {
      preprocessOptions: [
        "Sanitize",
        "SpellCheck",
        "Lemmatize",
        "RemoveStopWords",
        "RemoveDiacritics",
        "LowerCase",
        "Tokenize",
      ],
      excludePhrases: [],
      excludeGrammars: [],
      hyperParameters: "",
    },
    isFormValid: true,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.NLP_TESTER_MODELS_FETCH]),

    async handleSubmit() {
      const { preprocessOptions, excludePhrases, excludeGrammars } =
        this.fields;

      const { language, name } = this.GET_DIALOG_DATA(
        Dialogs.NLP_TESTER_MODEL_TRAIN
      );

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: `${ApiEndpoints.NLP_TESTER_MODELS}/${language}/model/${name}/train`,
        values: {
          preprocessOptions: preprocessOptions.join(","),
          excludePhrases: excludePhrases.join(","),
          excludeGrammars: excludeGrammars.join(","),
          hyperParameters: this.parseHyperParameters(),
        },
        actionId: Actions.NLP_TESTER_MODEL_TRAIN,
      });
      if (isFormSubmited) {
        toggleDialog(Dialogs.NLP_TESTER_MODEL_TRAIN, {});
        pushNotify(Notify.SUCCESS, "Model training process started");
        this.NLP_TESTER_MODELS_FETCH();
      }
    },

    parseHyperParameters(): any {
      try {
        const parsedParameters = JSON.parse(this.fields.hyperParameters);
        return parsedParameters;
      } catch {
        return {};
      }
    },
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VCombobox
          vModel={this.fields.excludePhrases}
          label="Phrases to exclude"
          multiple
          smallChips
          deletableChips
        />
        <VCombobox
          vModel={this.fields.excludeGrammars}
          label="Grammars to exclude"
          multiple
          smallChips
          deletableChips
        />
        <NlpPreprocessOptionsSelect vModel={this.fields.preprocessOptions} />
        <VTextarea
          label="Hyper parameters"
          vModel={this.fields.hyperParameters}
          spellcheck={false}
        />
      </VForm>
    );
  },
});

export default NlpTesterModelTrainForm;
