import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import TenantSelect from "../TenantSelect";

const Fields = {
  name: "name",
  idTenant: "idTenant",
};

const GrammarOverviewEditForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
    },
    isFormValid: true,
    isUniqueName: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_GRAMMAR]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.GRAMMAR_OVERVIEW_FETCH,
      Actions.GRAMMAR_CHECK_UNIQUE_NAME,
    ]),

    isEditable(name: string): boolean {
      return this.INSPECTED_GRAMMAR.basic.editableFields.includes(name);
    },

    async checkUniqueName(val: string) {
      const isUnique = await this.GRAMMAR_CHECK_UNIQUE_NAME(val);

      if (isUnique === null) {
        return;
      }

      this.isUniqueName = isUnique;
    },

    async handleSubmit(): Promise<void> {
      await this.checkUniqueName(this.fields.name);

      if (!this.isUniqueName) {
        return;
      }

      const refs: any = this.$refs;
      const submitedFields = getSectionEditableValues(
        this.fields,
        this.INSPECTED_GRAMMAR.basic
      );

      const { isFormSubmited } = await submitForm({
        actionId: Actions.GRAMMAR_OVERVIEW_EDIT,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.GRAMMAR_BASIC,
        params: { idGrammar: this.$route.params.idGrammar },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.GRAMMAR_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Grammar edited successfully");

        this.GRAMMAR_OVERVIEW_FETCH({
          idGrammar: this.$route.params.idGrammar,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_GRAMMAR.basic.content };
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields[Fields.name]}
          rules={[isRequired]}
          label="Name"
          disabled={!this.isEditable(Fields.name)}
          onBlur={(e: any) => this.checkUniqueName(e.target.value)}
          onInput={() => (this.isUniqueName = true)}
          errorMessages={
            !this.isUniqueName
              ? [`Name ${this.fields.name} is already used`]
              : null
          }
          loading={this.IS_ACTION_FETCHING(Actions.GRAMMAR_CHECK_UNIQUE_NAME)}
        />
        <TenantSelect
          vModel={this.fields[Fields.idTenant]}
          options={{ clearable: true }}
        />
      </VForm>
    );
  },
});

export default GrammarOverviewEditForm;
