import { ActionTree } from "vuex";
import { Actions, Getters, Mutations } from "@/models/store";
import router from "@/router";
import ApiEndpoints from "@/constants/apiEndpoints";
import DataGridsState from "@/models/store/DataGridsState";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import generateErrorMsg from "@/helpers/generateErrorMsg";
import QueryString from "qs";

const handleActions: ActionTree<DataGridsState, []> = {
  [Actions.SET_FAVORITE_ITEM]: async (
    { getters, dispatch },
    { id, dataset, dataGrid, actionId, isFavorite }
  ) => {
    const request = await createRequest({
      action: Actions.SET_FAVORITE_ITEM,
      method: "POST",
      url: ApiEndpoints.SET_FAVORITE,
      data: { id, dataset, isFavorite: !isFavorite },
    });

    const { errors } = parseResponse(request);

    if (errors.length === 0) {
      const dataGridParams = getters[Getters.GET_DATAGRID_FILTER](dataGrid);
      dispatch(actionId, { params: { ...dataGridParams } });
    } else {
      pushNotify(Notify.ERROR, generateErrorMsg(actionId));
    }
  },

  [Actions.FILTER_FAVORITE_ITEMS]: (
    { getters, dispatch },
    { dataGrid, actionId, setFavoriteAction }
  ) => {
    const dataGridParams = getters[Getters.GET_DATAGRID_FILTER](dataGrid);
    const isFavoriteFiltered = !!dataGridParams.isFavorite;

    dispatch(actionId, {
      params: {
        ...dataGridParams,
        isFavorite: !isFavoriteFiltered,
      },
    });
    if (setFavoriteAction) {
      dispatch(setFavoriteAction);
    }
  },

  [Actions.TOGGLE_FAVORITE_FLOWS]: ({ commit }) => {
    commit(Mutations.TOGGLE_FAVORITE_FLOWS);
  },

  [Actions.DATAGRID_FETCH]: async (
    { commit },
    {
      page,
      sortBy,
      sortDirection,
      dataGrid,
      actionId,
      url,
      params,
      filters,
      search,
    }
  ) => {
    const sort = sortBy ? `${sortBy}:${sortDirection}` : null;
    const dataGridParams = {
      page: page || 1,
      ...(search && { search }),
      ...(sort && { sort }),
      ...(params && { ...params }),
      ...(filters && { ...filters }),
    };

    const currentUrlQuery = router.currentRoute.query;
    const requestData = await createRequest({
      method: "GET",
      action: actionId,
      url,
      params: dataGridParams,
      paramsSerializer: (params: any) => {
        return QueryString.stringify(params);
      },
    });

    const query = {
      ...currentUrlQuery,
      ...{
        page,
        search: search || undefined,
        ...(sort && { sort }),
        ...(filters && { ...filters }),
      },
    };

    for (const key in query) {
      if (query[key] === null) {
        delete query[key];
      }
    }

    router
      .replace({
        query: query,
      })
      .catch(() => null);

    const { data, errors } = parseResponse(requestData);
    const dataGridData = { ...data, filter: dataGridParams || {} };

    if (errors.length === 0) {
      commit(Mutations.SET_DATAGRID_DATA, { dataGrid, data: dataGridData });
    }
  },

  [Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS]: (
    { getters, dispatch },
    { dataGrid, fetchActionId }
  ) => {
    const params = getters[Getters.GET_DATAGRID_FILTER](dataGrid);

    dispatch(fetchActionId, { params });
  },
};

export default handleActions;
