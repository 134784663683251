import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import Vue, { VNode } from "vue";
import { VForm } from "vuetify/lib";
import Dialog from "../Dialog";
import TenantInfrastructureSelect from "../TenantInfrastructureSelect";
import Routes from "@/constants/routes";

const BillingTenantChangeDialog = Vue.extend({
  methods: {
    submit(): void {
      const refs: any = this.$refs;
      const { idTenant, instance } = refs.tenantSelect.getValue();
      const isExternalInstance = instance && instance !== "master";

      this.$router.push({
        ...(isExternalInstance && { name: Routes.BILLING_OVERVIEW }),
        query: {
          ...this.$route.query,
          ...(isExternalInstance && { app: instance }),
        },
        params: { idTenant },
      });

      toggleDialog(Dialogs.BILLING_TENANT_SWITCH, {});
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.BILLING_TENANT_SWITCH}
        title="Tenant select"
        closeOnEsc
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            action: () => this.submit(),
          },
        ]}
      >
        <VForm>
          <TenantInfrastructureSelect
            ref="tenantSelect"
            value={{
              idTenant: this.$route.params.idTenant,
              instance: this.$route.query.app,
            }}
          />
        </VForm>
      </Dialog>
    );
  },
});

export default BillingTenantChangeDialog;
