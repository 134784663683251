enum Actions {
  // Datagrids
  DATAGRID_FETCH = "DATAGRID_FETCH",
  DATAGRID_FETCH_WITH_CURRENT_PARAMS = "FETCH_DATAGRID_WITH_CURRENT_PARAMS",
  FILTER_FAVORITE_ITEMS = "FILTER_FAVORITE_ITEMS",
  SET_FAVORITE_ITEM = "SET_FAVORITE_ITEM",
  TOGGLE_FAVORITE_FLOWS = "TOGGLE_FAVORITE_FLOWS",

  MODULES_DATAGRID_FETCH = "MODULES_DATAGRID_FETCH",
  LINES_DATAGRID_FETCH = "LINES_DATAGRID_FETCH",
  DIAL_LOGS_DATAGRID_FETCH = "DIAL_LOGS_DATAGRID_FETCH",
  LINE_QUEUE_DATAGRID_FETCH = "LINE_QUEUE_DATAGRID_FETCH",
  CALLSCRIPTS_DATAGRID_FETCH = "CALLSCRIPTS_DATAGRID_FETCH",
  FLOW_VERSION_HISTORY_DATAGRID_FETCH = "FLOW_VERSION_HISTORY_DATAGRID_FETCH",
  CAMPAIGNS_DATAGRID_FETCH = "CAMPAIGNS_DATAGRID_FETCH",
  GRAMMARS_DATAGRID_FETCH = "GRAMMARS_DATAGRID_FETCH",
  USERS_DATAGRID_FETCH = "USERS_DATAGRID_FETCH",
  TENANTS_DATAGRID_FETCH = "TENANTS_DATAGRID_FETCH",
  AUDIT_DATAGRID_FETCH = "AUDIT_DATAGRID_FETCH",
  PERMISSION_GROUPS_DATAGRID_FETCH = "PERMISSION_GROUPS_DATAGRID_FETCH",
  BOTDEPLOY_MODULES_DATAGRID_FETCH = "BOTDEPLOY_MODULES_DATAGRID_FETCH",
  BOTDEPLOY_SERVERS_DATAGRID_FETCH = "BOTDEPLOY_SERVERS_DATAGRID_FETCH",
  BOTDEPLOY_CONFIGS_DATAGRID_FETCH = "BOTDEPLOY_CONFIGS_DATAGRID_FETCH",
  NLP_TESTER_DATASETS_FETCH = "NLP_TESTER_DATASETS_FETCH",
  NLP_TESTER_CATEGORIES_FETCH = "NLP_TESTER_CATEGORIES_FETCH",
  NLP_TESTER_MODELS_FETCH = "NLP_TESTER_MODELS_FETCH",
  LANGUAGES_DATAGRID_FETCH = "LANGUAGES_DATAGRID_FETCH",
  TAGS_DATAGRID_FETCH = "TAGS_DATAGRID_FETCH",
  TENANT_TARRIFS_DATAGRID_FETCH = "TENANT_TARRIFS_DATAGRID_FETCH",
  LINE_TARRIFS_DATAGRID_FETCH = "LINE_TARRIFS_DATAGRID_FETCH",
  CONVERSATION_LOGS_DATAGRID_FETCH = "CONVERSATION_LOGS_DATAGRID_FETCH",

  // User
  FETCH_API_KEY = "FETCH_API_KEY",
  FETCH_USER_INFO = "FETCH_USER_INFO",
  USER_LOG_IN = "USER_LOG_IN",
  USER_ACCOUNTS_FETCH = "USER_ACCOUNTS_FETCH",
  APP_CONFIG_FETCH = "APP_CONFIG_FETCH",

  // Instances
  FLOW_SWITCH_VERSION = "FLOW_SWITCH_VERSION",
  FLOW_VERSION_DIFF = "FLOW_VERSION_DIFF",
  FLOW_TEST_CALL = "FLOW_TEST_CALL",
  GET_CALL_STATUS = "GET_CALL_STATUS",
  FLOW_DUPLICATE = "FLOW_DUPLICATE",
  FLOW_REMOVE = "FLOW_REMOVE",
  FLOW_OVERVIEW_EDIT = "FLOW_OVERVIEW_EDIT",
  FLOW_OVERVIEW_FETCH = "FLOW_OVERVIEW_FETCH",
  FLOW_CREATE = "FLOW_CREATE",
  FLOW_TAG_REMOVE = "FLOW_TAG_REMOVE",
  FLOW_VERSION_DUPLICATE = "FLOW_VERSION_DUPLICATE",

  MODULE_OVERVIEW_FETCH = "MODULE_OVERVIEW_FETCH",
  MODULE_CALLBOT_CONFIG_FETCH = "MODULE_CALLBOT_CONFIG_FETCH",
  MODULE_OVERVIEW_EDIT = "MODULE_OVERVIEW_EDIT",
  MODULE_CALLBOT_SETTINGS_EDIT = "MODULE_CALLBOT_SETTINGS_EDIT",
  MODULE_REMOVE = "MODULE_REMOVE",
  MODULE_SET_DEFAULT_LINE = "MODULE_SET_DEFAULT_LINE",

  USER_OVERVIEW_FETCH = "USER_OVERVIEW_FETCH",
  USER_ROLES_FETCH = "USER_ROLES_FETCH",
  USER_OVERVIEW_EDIT = "USER_OVERVIEW_EDIT",
  USER_REMOVE = "USER_REMOVE",
  USER_PASSWORD_CHANGE = "USER_PASSWORD_CHANGE",
  USER_API_SECRET_FETCH = "USER_API_SECRET_FETCH",
  USER_API_SECRET_GENERATE = "USER_API_SECRET_GENERATE",
  USER_API_SECRET_REMOVE = "USER_API_SECRET_REMOVE",
  USER_TWO_FACTOR_FETCH = "USER_TWO_FACTOR_FETCH",
  USER_TWO_FACTOR_CHANGE = "USER_TWO_FACTOR_CHANGE",
  USER_TWO_FACTOR_RESET = "USER_TWO_FACTOR_RESET",
  USER_PREFERENCES_FETCH = "USER_PREFERENCES_FETCH",
  USER_PREFERENCES_EDIT = "USER_PREFERENCES_EDIT",

  PERMISSION_GROUP_CREATE = "PERMISSION_GROUP_CREATE",
  PERMISSION_GROUP_OVERVIEW_FETCH = "PERMISSION_GROUP_OVERVIEW_FETCH",
  PERMISSION_GROUP_REMOVE = "PERMISSION_GROUP_REMOVE",
  PERMISSIONS_GROUPS_FETCH = "PERMISSIONS_GROUPS_FETCH",
  PERMISSIONS_FETCH = "PERMISSIONS_FETCH",
  PERMISSIONS_WITH_GROUPS_FETCH = "PERMISSIONS_WITH_GROUPS_FETCH",
  PERMISSION_GROUP_OVERVIEW_EDIT = "PERMISSION_GROUP_OVERVIEW_EDIT",
  PERMISSION_GROUP_PERMISSIONS_EDIT = "PERMISSION_GROUP_PERMISSIONS_EDIT",

  LINE_OVERVIEW_FETCH = "LINE_OVERVIEW_FETCH",
  LINE_OVERVIEW_EDIT = "LINE_OVERVIEW_EDIT",
  LINE_CALLBOT_CONFIG_FETCH = "LINE_CALLBOT_CONFIG_FETCH",
  LINE_CALLBOT_CONFIG_EDIT = "LINE_CALLBOT_CONFIG_EDIT",
  LINE_SET_MODULE = "LINE_SET_MODULE",
  LINE_TOGGLE = "LINE_TOGGLE",
  LINE_CREATE = "LINE_CREATE",
  LINE_REMOVE = "LINE_REMOVE",
  LINE_MONITORING_EDIT = "LINE_MONITORING_EDIT",
  LINE_MONITORING_FETCH = "LINE_MONITORING_FETCH",
  LINE_MONITORING_REMOVE = "LINE_MONITORING_REMOVE",
  LINE_MONITORING_STATE_TOGGLE = "LINE_MONITORING_STATE_TOGGLE",
  LINE_STATISTICS_FETCH = "LINE_STATISTICS_FETCH",
  LINE_RECOMMENDED_MONITORING_FETCH = "LINE_RECOMMENDED_MONITORING_FETCH",
  LINE_TARIFF_EDIT = "LINE_TARIFF_EDIT",
  LINE_CLEANING_SETTINGS_FETCH = "LINE_CLEANING_SETTINGS_FETCH",
  LINE_CLEANING_SETTINGS_EDIT = "LINE_CLEANING_SETTINGS_EDIT",

  GRAMMAR_OVERVIEW_FETCH = "GRAMMAR_OVERVIEW_FETCH",
  GRAMMAR_OVERVIEW_EDIT = "GRAMMAR_OVERVIEW_EDIT",
  GRAMMAR_CREATE = "GRAMMAR_CREATE",
  GRAMMAR_DUPLICATE = "GRAMMAR_DUPLICATE",
  GRAMMAR_REMOVE = "GRAMMAR_REMOVE",
  GRAMMAR_CHECK_UNIQUE_NAME = "GRAMMAR_CHECK_UNIQUE_NAME",

  TENANT_OVERVIEW_FETCH = "TENANT_OVERVIEW_FETCH",
  TENANT_PARAMS_FETCH = "TENANT_PARAMS_FETCH",
  TENANT_CREATE = "TENANT_CREATE",
  TENANT_USER_CREATE = "TENANT_USER_CREATE",
  TENANT_FETCH_NOTIFICATION_GROUPS = "TENANT_FETCH_NOTIFICATION_GROUPS",
  TENANT_CREATE_NOTIFICATION_GROUP = "TENANT_CREATE_NOTIFICATION_GROUP",
  TENANT_REMOVE_NOTIFICATION_GROUP = "TENANT_REMOVE_NOTIFICATION_GROUP",
  TENANT_REMOVE = "TENANT_REMOVE",
  TENANT_OVERVIEW_EDIT = "TENANT_OVERVIEW_EDIT",
  TENANT_PARAMS_EDIT = "TENANT_PARAMS_EDIT",
  TENANT_STATISTICS_FETCH = "TENANT_STATISTICS_FETCH",
  TENANT_BILLING_FETCH = "TENANT_BILLING_FETCH",
  TENANT_FORCE_TWO_FACTOR = "TENANT_FORCE_TWO_FACTOR",
  TENANT_TARIFF_REMOVE = "TENANT_TARIFF_REMOVE",

  CAMPAIGN_OVERVIEW_FETCH = "CAMPAIGN_OVERVIEW_FETCH",
  CAMPAIGN_CREATE = "CAMPAIGN_CREATE",
  CAMPAIGN_IMPORT_CONTACTS = "CAMPAIGN_IMPORT_CONTACTS",
  CAMPAIGN_SCHEDULE_EDIT = "CAMPAIGN_SCHEDULE_EDIT",
  CAMPAIGN_EDIT = "CAMPAIGN_EDIT",
  CAMPAIGN_BASIC_EDIT = "CAMPAIGN_BASIC_EDIT",
  CAMPAIGN_REMOVE = "CAMPAIGN_REMOVE",
  CAMPAIGN_STATE_CHANGE = "CAMPAIGN_STATE_CHANGE",
  CAMPAIGN_VALIDATION_FETCH = "CAMPAIGN_VALIDATION_FETCH",
  CAMPAING_STATUS_UPDATE = "CAMPAING_STATUS_UPDATE",
  CAMPAIGN_CONTACTS_FETCH = "CAMPAIGN_CONTACTS_FETCH",

  SECTION_DETAIL_FETCH = "SECTION_DETAIL_FETCH",
  INSTANCE_DUPLICATE = "INSTANCE_DUPLICATE",
  INSTANCE_REMOVE = "INSTANCE_REMOVE",
  INSTANCE_TEST_CALL = "INSTANCE_TEST_CALL",
  INSTANCE_GET_CALL_STATUS = "INSTANCE_GET_CALL_STATUS",

  INSTANCE_FETCH_CSV_STATISTICS = "INSTANCE_FETCH_CSV_STATISTICS",
  AUDIO_RECORD_FETCH = "AUDIO_RECORD_FETCH",
  AUDIO_RECORD_OPEN = "AUDIO_RECORD_OPEN",
  AUDIO_RECORD_REMOVE = "AUDIO_RECORD_REMOVE",
  GENERATE_CSV_REPORTS = "GENERATE_CSV_REPORTS",
  DIAL_LOG_FETCH = "DIAL_LOG_FETCH",
  LINE_REMOVE_CALL = "LINE_REMOVE_CALL",

  // Tools
  TTS_GENERATOR = "TTS_GENERATOR",
  PACKAGE_CREATE = "PACKAGE_CREATE",
  PACKAGE_INSTALL = "PACKAGE_INSTALL",

  // Bot Deploy
  BOTDEPLOY_MODULE_OVERVIEW_FETCH = "BOTDEPLOY_MODULE_OVERVIEW_FETCH",
  BOTDEPLOY_MODULE_OVERVIEW_EDIT = "BOTDEPLOY_MODULE_OVERVIEW_EDIT",
  BOTDEPLOY_MODULE_CREATE = "BOTDEPLOY_MODULE_CREATE",
  BOTDEPLOY_MODULE_REMOVE = "BOTDEPLOY_MODULE_REMOVE",
  BOTDEPLOY_MODULE_TOGGLE = "BOTDEPLOY_MODULE_TOGGLE",
  BOTDEPLOY_MODULE_RESTART = "BOTDEPLOY_MODULE_RESTART",

  BOTDEPLOY_SERVER_OVERVIEW_FETCH = "BOTDEPLOY_SERVER_OVERVIEW_FETCH",
  BOTDEPLOY_SERVER_OVERVIEW_EDIT = "BOTDEPLOY_SERVER_OVERVIEW_EDIT",
  BOTDEPLOY_SERVER_CREATE = "BOTDEPLOY_SERVER_CREATE",
  BOTDEPLOY_SERVER_REMOVE = "BOTDEPLOY_SERVER_REMOVE",

  BOTDEPLOY_CONFIG_OVERVIEW_FETCH = "BOTDEPLOY_CONFIG_OVERVIEW_FETCH",
  BOTDEPLOY_CONFIG_OVERVIEW_EDIT = "BOTDEPLOY_CONFIG_OVERVIEW_EDIT",
  BOTDEPLOY_CONFIG_CREATE = "BOTDEPLOY_CONFIG_CREATE",
  BOTDEPLOY_CONFIG_REMOVE = "BOTDEPLOY_CONFIG_REMOVE",

  // Nlp tester
  NLP_CATEGORY_OVERVIEW_FETCH = "NLP_CATEGORY_OVERVIEW_FETCH",
  NLP_CATEGORY_SAMPLES_FETCH = "NLP_CATEGORY_SAMPLES_FETCH",
  NLP_CATEGORY_INIT = "NLP_CATEGORY_INIT",
  NLP_CATEGORY_SAMPLES_EDIT = "NLP_CATEGORY_SAMPLES_EDIT",
  NLP_CATEGORY_CREATE = "NLP_CATEGORY_CREATE",
  NLP_CATEGORY_REMOVE = "NLP_CATEGORY_REMOVE",
  NLP_CATEGORY_SAMPLES_GENERATE = "NLP_CATEGORY_SAMPLES_GENERATE",
  NLP_TESTER_DATASET_CREATE = "NLP_TESTER_DATASET_CREATE",
  NLP_TESTER_DATASET_REMOVE = "NLP_TESTER_DATASET_REMOVE",
  NLP_TESTER_DATASET_DUPLICATE = "NLP_TESTER_DATASET_DUPLICATE",
  NLP_TESTER_MODEL_CREATE = "NLP_TESTER_MODEL_CREATE",
  NLP_TESTER_MODEL_TRAIN = "NLP_TESTER_MODEL_TRAIN",
  NLP_TESTER_MODEL_PREDICT = "NLP_TESTER_MODEL_PREDICT",
  NLP_TESTER_MODEL_REMOVE = "NLP_TESTER_MODEL_REMOVE",

  // Languages
  LANGUAGE_CREATE = "LANGUAGE_CREATE",
  LANGUAGE_OVERVIEW_FETCH = "LANGUAGE_OVERVIEW_FETCH",
  LANGUAGE_OVERVIEW_EDIT = "LANGUAGE_OVERVIEW_EDIT",
  LANGUAGE_REMOVE = "LANGUAGE_REMOVE",

  // Notification
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",

  // Dropdowns
  DROPDOWN_DATA_FETCH = "DROPDOWN_DATA_FETCH",

  // Resources
  LANGUAGES_FETCH = "LANGUAGES_FETCH",
  TAGS_FETCH = "TAGS_FETCH",
  INFRASTRUCTURE_TENANT_FETCH = "INFRASTRUCTURE_TENANT_FETCH",

  // Variable storage
  VARIABLE_STORAGE_FETCH_ALL = "VARIABLE_STORAGE_FETCH_ALL",
  VARIABLE_STORAGE_FETCH_KEY = "VARIABLE_STORAGE_FETCH_KEY",
  VARIABLES_STORAGE_CREATE = "VARIABLES_STORAGE_CREATE",
  VARIABLE_STORAGE_GENERATE_KEY = "VARIABLE_STORAGE_GENERATE_KEY",
  VARIABLE_STORAGE_REMOVE = "VARIABLE_STORAGE_REMOVE",
  VARIABLE_STORAGE_EDIT = "VARIABLE_STORAGE_EDIT",
  VARIABLE_STORAGE_TENANT_CHANGE = "VARIABLE_STORAGE_TENANT_CHANGE",

  // Temp
  CAMPAIGN_PREPARE = "CAMPAIGN_PREPARE",
}

export default Actions;
