import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import NlpTesterDatasetsCreateForm from "../NlpTesterDatasetsCreateForm";

const NlpTesterDatasetsCreateDialog = Vue.extend({
  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        id={Dialogs.NLP_TESTER_DATASET_CREATE}
        title="Create dataset"
        actions={[
          {
            title: "Create",
            icon: "mdi-plus",
            id: Actions.NLP_TESTER_DATASET_CREATE,
            action: () => refs.form?.handleSubmit(),
          },
        ]}
      >
        <NlpTesterDatasetsCreateForm ref="form" />
      </Dialog>
    );
  },
});

export default NlpTesterDatasetsCreateDialog;
