import ApiEndpoints from "@/constants/apiEndpoints";
import DAYS_IN_WEEK from "@/constants/daysInWeek";
import submitForm from "@/helpers/submitForm";
import { isRequired, isTime } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapGetters } from "vuex";
import {
  VBtn,
  VCard,
  VCardText,
  VCheckbox,
  VCol,
  VDatePicker,
  VForm,
  VIcon,
  VMenu,
  VRow,
  VSelect,
  VTextField,
} from "vuetify/lib";
import Dialogs from "@/constants/dialogs";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import parseStorageTimetable from "@/helpers/parseStorageTimetable";
import TimeZoneSelect from "../TimeZoneSelect";

const VariablesStorageTimetableForm = Vue.extend({
  data: () => ({
    variableName: "",
    timeTable: [{ dow: [], ranges: [{ from: "", to: "" }] }],
    dates: [] as any,
    timezone: "",
    errors: [],
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),

    formattedValue(): any {
      const dowValue = {};

      [...Array(7)].map((_, index) => {
        dowValue[index] = null;
      });

      this.timeTable.map(({ dow, ranges }) => {
        dow.map((day) => {
          const parsedDay = parseInt(day);

          if (!dowValue[parsedDay]) {
            dowValue[parsedDay] = [];
          }

          ranges.map((range) => dowValue[parsedDay].push(range));
        });
      });

      const datesValue = {};

      this.dates.map(({ date, isDisabled, ranges }: any) => {
        datesValue[date] = isDisabled ? null : ranges;
      });

      const timezone =
        this.timezone === null
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : this.timezone;

      return { timezone, dow: dowValue, dates: datesValue };
    },

    initData(): any {
      return (
        this.GET_DIALOG_DATA(Dialogs.VARIABLE_STORAGE_CREATE)?.initData ?? null
      );
    },
  },

  methods: {
    addTimetable() {
      this.timeTable.push({ dow: [], ranges: [{ from: "", to: "" }] });
    },

    addRange(timeTableIndex: number) {
      this.timeTable[timeTableIndex].ranges.push({ from: "", to: "" });
    },

    addExactDate(): void {
      this.dates.push({
        date: "",
        isDisabled: true,
        ranges: [{ from: "", to: "" }],
        pickerActive: false,
      });
    },

    addDateRange(dateIndex: number): void {
      this.dates[dateIndex].ranges.push({ from: "", to: "" });
    },

    removeTimetable(timeTableIndex: number) {
      this.timeTable.splice(timeTableIndex, 1);
    },

    removeDate(dateIndex: number): void {
      this.dates.splice(dateIndex, 1);
    },

    removeRange(timeTableIndex: number, rangeIndex: number) {
      this.timeTable[timeTableIndex].ranges.splice(rangeIndex, 1);
    },

    removeDateRange(dateIndex: number, rangeIndex: number) {
      this.dates[dateIndex].ranges.splice(rangeIndex, 1);
    },

    prepareData() {
      try {
        const timeRangeGroups = parseStorageTimetable(this.initData.value);

        const timeTableSet = Object.entries(timeRangeGroups.dow).map(
          ([key, value]: any) => {
            const singleRanges = key.split(";");

            const ranges = singleRanges.map((range: string) => {
              const [from, to] = range.split(" - ");
              return { from, to };
            });

            return { dow: value.map((day: string) => parseInt(day)), ranges };
          }
        );

        this.variableName = this.initData.key;
        this.timeTable = timeTableSet;
        this.dates = timeRangeGroups.dates;
        this.timezone = timeRangeGroups.timezone;
      } catch {
        pushNotify(Notify.ERROR, "Error while parsing data");
      }
    },

    async submit(metaData: any): Promise<boolean> {
      const payload = {
        idTenant: metaData.idTenant,
        type: metaData.type,
        key: this.variableName,
        value: JSON.stringify(this.formattedValue),
      };

      this.errors = [];

      const { isFormSubmited, errors } = await submitForm({
        ref: this.$refs.form,
        url: this.initData
          ? ApiEndpoints.VARIABLE_STORAGE_EDIT
          : ApiEndpoints.VARIABLES_STORAGE_ALL,
        values: this.initData ? payload : [payload],
        actionId: Actions.VARIABLES_STORAGE_CREATE,
      });

      if (errors[0]?.rawData) {
        this.errors = errors[0]?.rawData;
      }

      return isFormSubmited;
    },
  },

  mounted() {
    this.timezone = this.USER_INFO.consolePreference.timezone;

    if (this.initData) {
      this.prepareData();
    }
  },

  render(): VNode {
    return (
      <div>
        <VForm ref="form">
          <VCard class="mb-5">
            <VCardText>
              <VRow>
                <VCol md={10}>
                  <VTextField
                    vModel={this.variableName}
                    label="Variable name"
                    rules={[isRequired]}
                    disabled={!!this.initData}
                    hideDetails="auto"
                  />
                </VCol>
                <VCol md={10}>
                  <TimeZoneSelect withBrowserOption vModel={this.timezone} />
                </VCol>
              </VRow>
            </VCardText>
          </VCard>

          <h3 class="text-subtitle-1 mb-2 font-weight-bold text--primary">
            Days of week
          </h3>
          {this.timeTable.map(({ ranges }: any, timeTableIndex) => (
            <VCard class="mb-5">
              <VBtn
                class="error--text"
                small
                text
                style="position: absolute; right: 0; top: 5px;"
                onClick={() => this.removeTimetable(timeTableIndex)}
              >
                <VIcon>mdi-delete-forever</VIcon>
              </VBtn>
              <VCardText>
                <VRow>
                  <VCol md={10}>
                    <VSelect
                      vModel={this.timeTable[timeTableIndex].dow}
                      label="Days of week"
                      multiple
                      smallChips
                      deletableChips
                      items={DAYS_IN_WEEK}
                      rules={[isRequired]}
                      hideDetails="auto"
                    />
                  </VCol>
                </VRow>
                {ranges.map(({ from, to }: any, rangeIndex: number) => (
                  <VRow class="d-flex align-center">
                    <VCol md={5}>
                      <VTextField
                        vModel={
                          this.timeTable[timeTableIndex].ranges[rangeIndex].from
                        }
                        label="From"
                        rules={[isRequired, isTime]}
                        hideDetails="auto"
                      />
                    </VCol>
                    <VCol md={5}>
                      <VTextField
                        vModel={
                          this.timeTable[timeTableIndex].ranges[rangeIndex].to
                        }
                        label="To"
                        rules={[isRequired, isTime]}
                        hideDetails="auto"
                      />
                    </VCol>
                    <VCol md={2}>
                      {this.timeTable[timeTableIndex].ranges.length > 1 && (
                        <VBtn
                          onClick={() =>
                            this.removeRange(timeTableIndex, rangeIndex)
                          }
                          class="error--text"
                          small
                          text
                        >
                          <VIcon>mdi-delete</VIcon>
                        </VBtn>
                      )}
                    </VCol>
                  </VRow>
                ))}
                <VBtn
                  onClick={() => this.addRange(timeTableIndex)}
                  class="tertiary--text mt-5"
                  small
                  text
                >
                  <VIcon left>mdi-plus</VIcon> Add time range
                </VBtn>
              </VCardText>
            </VCard>
          ))}

          <div class="text-center">
            <VBtn
              onClick={this.addTimetable}
              small
              class="tertiary white--text"
            >
              <VIcon left>mdi-plus</VIcon>Add timetable
            </VBtn>
          </div>

          <h3 class="text-subtitle-1 mb-2 mt-5 font-weight-bold text--primary">
            Exact dates
          </h3>
          {this.dates.map(({ isDisabled, ranges }: any, index: number) => (
            <VCard class="mb-5">
              <VCardText>
                <VBtn
                  class="error--text"
                  small
                  text
                  style="position: absolute; right: 0; top: 5px;"
                  onClick={() => this.removeDate(index)}
                >
                  <VIcon>mdi-delete-forever</VIcon>
                </VBtn>
                <VRow>
                  <VCol md={5}>
                    <VMenu
                      vModel={this.dates[index].pickerActive}
                      offsetY
                      transition="scale-transition"
                      maxWidth="290"
                      scopedSlots={{
                        activator: ({ on, attrs }: any) => (
                          <VTextField
                            vModel={this.dates[index].date}
                            label="Date"
                            readonly
                            rules={[isRequired]}
                            {...{ on, attrs }}
                          />
                        ),
                      }}
                    >
                      <VDatePicker
                        vModel={this.dates[index].date}
                        firstDayOfWeek={1}
                      />
                    </VMenu>
                  </VCol>
                  <VCol md={5}>
                    <VCheckbox
                      vModel={this.dates[index].isDisabled}
                      label="Closed"
                      hideDetails="auto"
                    />
                  </VCol>
                </VRow>
                {!isDisabled && (
                  <div>
                    {ranges.map((range: any, rangeIndex: number) => (
                      <VRow>
                        <VCol md={5}>
                          <VTextField
                            vModel={this.dates[index].ranges[rangeIndex].from}
                            label="From"
                            rules={[isRequired, isTime]}
                            hideDetails="auto"
                          />
                        </VCol>
                        <VCol md={5}>
                          <VTextField
                            vModel={this.dates[index].ranges[rangeIndex].to}
                            label="To"
                            rules={[isRequired, isTime]}
                            hideDetails="auto"
                          />
                        </VCol>
                        <VCol md={2}>
                          {this.dates[index].ranges.length > 1 && (
                            <VBtn
                              onClick={() =>
                                this.removeDateRange(index, rangeIndex)
                              }
                              class="error--text"
                              small
                              text
                            >
                              <VIcon>mdi-delete</VIcon>
                            </VBtn>
                          )}
                        </VCol>
                      </VRow>
                    ))}
                    <VBtn
                      onClick={() => this.addDateRange(index)}
                      class="tertiary--text mt-5"
                      small
                      text
                    >
                      <VIcon left>mdi-plus</VIcon> Add time range
                    </VBtn>
                  </div>
                )}
              </VCardText>
            </VCard>
          ))}
          <div class="text-center">
            <VBtn
              onClick={this.addExactDate}
              small
              class="tertiary white--text mt-5"
            >
              <VIcon left>mdi-plus</VIcon>Add date
            </VBtn>
          </div>
        </VForm>
        {this.errors.length > 0 && (
          <div class="error--text mt-4 d-flex align-center">
            <VIcon class="pr-1 error--text">mdi-alert-octagon</VIcon>
            Variable {this.errors.length === 1 ? "key" : "keys"}{" "}
            {this.errors.map((error: string) => `"${error}"`).join(", ")}{" "}
            {this.errors.length === 1 ? "is" : "are"} already used for this
            tenant.
          </div>
        )}
      </div>
    );
  },
});

export default VariablesStorageTimetableForm;
