export const API_URL = process.env.VUE_APP_API_URL;
export const API_WS_URL = process.env.VUE_APP_API_WS_URL;
export const SSO_URL = process.env.VUE_APP_SSO_URL;
export const NLP_API_URL = process.env.VUE_APP_NLP_API_URL;
export const VARIABLES_STORAGE = `${API_URL}/TenantValueStorage`;
export const ADMINISTRATION_URL = `${API_URL}/Administration`;
export const ADMINISTRATION_WS_URL = `${API_WS_URL}/Administration`;
export const TEST_CALL_WS_URL = `${API_WS_URL}/Designer`;

export const MODULE_URL = `${ADMINISTRATION_URL}/Module`;
export const LINE_URL = `${ADMINISTRATION_URL}/Line`;
export const FLOW_URL = `${ADMINISTRATION_URL}/CallScript`;
export const GRAMMAR_URL = `${ADMINISTRATION_URL}/Grammar`;
export const RESOURCE_URL = `${ADMINISTRATION_URL}/Resource`;
export const ROLES_URL = `${ADMINISTRATION_URL}/Role`;
export const TOOLS_URL = `${ADMINISTRATION_URL}/Tools`;
export const TENANT_URL = `${ADMINISTRATION_URL}/Tenant`;
export const LANGUAGE_URL = `${ADMINISTRATION_URL}/Language`;
export const CAMPAIGN_URL = `${ADMINISTRATION_URL}/Campaign`;
export const CAMPAIGN_WS_URL = `${ADMINISTRATION_WS_URL}/Campaign`;
export const ASSETS_URL = `${API_URL}/Assets`;
export const HOME_URL = `${API_URL}/Home`;
export const STATISTICS_URL = `${API_URL}/Statistics`;
export const BOTDEPLOY_URL = `${API_URL}/BotDeploy`;
export const CALLS_MANAGEMENT_URL = `${API_URL}/CallsManagement`;
export const CALLBOT_URL = `${API_URL}/Callbot`;

const ApiEndpoints = {
  // DataGrids
  AUDIT: `${ADMINISTRATION_URL}/Audit`,
  DIAL_MODULES: `${ADMINISTRATION_URL}/DialModules`,
  DIAL_MODULE_LINES: `${ADMINISTRATION_URL}/DialModuleLines`,
  DIAL_LOGS: `${ADMINISTRATION_URL}/DialLogs`,
  CONVERSATION_LOGS: `${ADMINISTRATION_URL}/Conversations`,
  FLOW: `${ADMINISTRATION_URL}/CallScripts`,
  FLOW_VERSION_HISTORY: `${FLOW_URL}/Histories`,
  CAMPAIGNS: `${ADMINISTRATION_URL}/Campaigns`,
  GRAMMARS: `${ADMINISTRATION_URL}/Grammars`,
  USERS: `${ADMINISTRATION_URL}/Users`,
  TENANTS: `${ADMINISTRATION_URL}/Tenants`,
  PERMISSION_GROUPS: `${ADMINISTRATION_URL}/Roles`,
  SET_FAVORITE: `${ADMINISTRATION_URL}/SetFavorite`,
  BOTDEPLOY_MODULES: `${BOTDEPLOY_URL}/Modules`,
  BOTDEPLOY_SERVERS: `${BOTDEPLOY_URL}/Servers`,
  BOTDEPLOY_CONFIGS: `${BOTDEPLOY_URL}/Configs`,
  NLP_DATASETS: `${NLP_API_URL}/dataset`,
  LANGUAGES: `${ADMINISTRATION_URL}/Languages`,
  TAGS: `${FLOW_URL}/Tags`,
  TENANT_TARIFFS: `${TENANT_URL}/Tariffs`,
  LINE_TARIFFS: `${TENANT_URL}/LinesTariff`,

  // Assets
  AUDIO_RECORD: `${ASSETS_URL}/Record`,
  CONVERSATION_AUDIO_RECORD: `${ASSETS_URL}/ConversationRecord`,
  CSV_REPORTS_MODULE: `${ASSETS_URL}/GenerateCsvByDialModule`,
  CSV_REPORTS_LINE: `${ASSETS_URL}/GenerateCsvByDialModuleLine`,
  CSV_REPORTS_FLOW: `${ASSETS_URL}/GenerateCsvByCallScript`,
  CSV_TENANT_STATISTICS: `${ASSETS_URL}/GenerateCsvByTenant`,
  CSV_CAMPAIGN_STATISTICS: `${ASSETS_URL}/GenerateCsvByCampaign`,
  CSV_CAMPAIGN_OVERVIEW: `${ASSETS_URL}/GenerateCsvCampaignOverview`,
  CSV_OVERVIEW_TENANT_STATISTICS: `${ASSETS_URL}/GenerateOverviewCsvByTenant`,
  CSV_BILLING_OVERVIEW: `${ASSETS_URL}/GenerateCsvTenantEconomy`,
  DIAL_LOG_DETAIL: `${ASSETS_URL}/CallInfo`,
  CONVERSATION_LOG_DETAIL: `${ASSETS_URL}/ConversationInfo`,
  CAMPAIGN_CONTACTS: `${ASSETS_URL}/Contacts`,
  PERMISSION_GROUPS_EXPORT: `${ASSETS_URL}/Roles`,

  // Modules
  MODULE_BASIC: `${MODULE_URL}/Basic`,
  MODULE_CALLBOT_SETTINGS: `${MODULE_URL}/CallbotSettings`,
  MODULE_REMOVE: MODULE_URL,
  MODULE_LINE_CREATE: `${MODULE_URL}/CreateLine`,
  MODULE_DEFAULT_LINE: `${MODULE_URL}/SetDefaultLine`,

  // Lines
  LINE_BASIC: `${LINE_URL}/Basic`,
  LINE_CALLBOT_SETTINGS: `${LINE_URL}/CallbotSettings`,
  LINE_TOGGLE: `${LINE_URL}/SetEnableLine`,
  LINE_SET_MODULE: `${LINE_URL}/Move`,
  LINE_REMOVE: LINE_URL,
  LINE_MONITORING: `${LINE_URL}/Monitoring`,
  LINE_RECOMMENDED_MONITORING: `${LINE_URL}/RecommendedMonitoringSetup`,
  LINE_STATISTICS: `${STATISTICS_URL}/Line`,
  LINE_QUEUE: `${LINE_URL}/Queue`,
  LINE_CLEANING_SETTINGS: `${LINE_URL}/CleaningSettings`,

  // Flow
  FLOW_SWITCH_VERSION: `${FLOW_URL}/Revert`,
  FLOW_VERSION_DIFF: `${FLOW_URL}/HistoryDiff`,
  FLOW_TEST_CALL: `${CALLS_MANAGEMENT_URL}/TestCall`,
  LANGUAGES_LIST_TEST_CALL: `${FLOW_URL}/Basic`,
  FLOW_DUPLICATE: `${FLOW_URL}/Duplicate`,
  FLOW_REMOVE: FLOW_URL,
  FLOW_EDIT_BASIC: `${FLOW_URL}/Basic`,
  FLOW_CREATE: FLOW_URL,
  FLOW_BASIC: `${FLOW_URL}/Basic`,
  FLOW_TAG: `${FLOW_URL}/Tag`,
  FLOW_VERSION_DUPLICATE: `${FLOW_URL}/DuplicateCallScriptHistory`,
  TEST_CALL_WS_URL: `${TEST_CALL_WS_URL}/DiagnosticWebSocket`,
  GET_CALL_STATUS_URL: `${CALLBOT_URL}/GetCallStatus`,

  // Campaign
  CAMPAIGN_OVERVIEW: `${CAMPAIGN_URL}/Overview`,
  CAMPAIGN_CREATE: `${CAMPAIGN_URL}/Prepare`,
  CAMPAIGN_IMPORT_CONTACTS: `${CAMPAIGN_URL}/Contacts`,
  CAMPAIGN_SCHEDULE: `${CAMPAIGN_URL}/Schedule`,
  CAMPAIGN_BASIC: `${CAMPAIGN_URL}/Basic`,
  CAMPAIGN_REMOVE: CAMPAIGN_URL,
  CAMPAIGN_STATE_CHANGE: `${CAMPAIGN_URL}/ChangeStatus`,
  CAMPAIGN_DETAIL_WS: `${CAMPAIGN_WS_URL}/LiveDetailWebSocket`,
  CAMPAIGN_VALIDATION: `${CAMPAIGN_URL}/Validation`,

  // Grammars
  GRAMMAR_BASIC: `${GRAMMAR_URL}/Basic`,
  GRAMMAR_CREATE: GRAMMAR_URL,
  GRAMMAR_DUPLICATE: `${GRAMMAR_URL}/Duplicate`,
  GRAMMAR_REMOVE: GRAMMAR_URL,
  GRAMMAR_GENERATE_SAMPLES: `${NLP_API_URL}/generator`,
  GRAMMAR_CHECK_UNIQUE_NAME: `${GRAMMAR_URL}/Exists`,

  // Permission groups
  PERMISSION_GROUP_CREATE: ROLES_URL,
  PERMISSION_GROUP_BASIC: `${ROLES_URL}/Basic`,
  PERMISSION_GROUP_REMOVE: ROLES_URL,
  PERMISSION_GROUP_PERMISSIONS: `${ROLES_URL}/Permission`,

  // Tools
  TTS_GENERATOR: `${TOOLS_URL}/TtsGenerator`,
  PACKAGE_INSTALL: `${TOOLS_URL}/InstallPackage`,
  PACKAGE_CREATE: `${TOOLS_URL}/CreateInstallPackage`,

  // Tenants
  TENANT_CREATE: TENANT_URL,
  TENANT_BASIC: `${TENANT_URL}/Basic`,
  TENANT_PARAMETERS: `${TENANT_URL}/Parameters`,
  TENANT_USER: `${TENANT_URL}/User`,
  TENANT_USER_BASIC: `${TENANT_URL}/UserBasic`,
  TENANT_USER_ROLES: `${TENANT_URL}/UserRoles`,
  TENANT_USER_PASSWORD: `${TENANT_URL}/UserPassword`,
  TENANT_USER_API_SECRET: `${TENANT_URL}/UserApiSecret`,
  TENANT_TWO_FACTOR_FORCE: `${TENANT_URL}/SetTenantTwoFactorRequirement`,
  TENANT_USER_TWO_FACTOR_ENABLE: `${TENANT_URL}/SetTwoFactorRequirement`,
  TENANT_USER_TWO_FACTOR_REMOVE: `${TENANT_URL}/RemoveTwoFactorToken`,
  TENANT_NOTIFICATION_GROUPS: `${TENANT_URL}/NotificationGroups`,
  TENANT_NOTIFICATION_GROUP: `${TENANT_URL}/NotificationGroup`,
  TENANT_STATISTICS: `${STATISTICS_URL}/Tenant`,
  TENANT_REMOVE: TENANT_URL,
  TENANT_USER_PREFERENCE_CONSOLE: `${TENANT_URL}/UserPreferenceConsole`,

  // Resources
  MODULES_LIST: `${RESOURCE_URL}/Modules`,
  FLOW_LIST: `${RESOURCE_URL}/Callscripts`,
  FLOW_LIST_OVERVIEW: `${RESOURCE_URL}/CallscriptsOverview`,
  TENANT_LIST: `${RESOURCE_URL}/Tenants`,
  LANGUAGES_LIST: `${RESOURCE_URL}/Languages`,
  PERMISSIONS_GROUPS_LIST: `${RESOURCE_URL}/RulesGroup`,
  LANGUAGES_STRUCTURE: `${RESOURCE_URL}/SupportedLanguages`,
  NOTIFICATION_GROUPS: `${RESOURCE_URL}/NotificationGroups`,
  GRAMMAR_LIST: `${RESOURCE_URL}/Grammars`,
  COMPONENT_LIST: `${RESOURCE_URL}/Components`,
  LINE_LIST: `${RESOURCE_URL}/Lines`,
  LINE_LIST_WITH_MODULE: `${RESOURCE_URL}/LinesWithModule`,

  BOTDEPLOY_SERVERS_LIST: `${RESOURCE_URL}/BotDeployServers`,
  BOTDEPLOY_CONFIGS_LIST: `${RESOURCE_URL}/BotDeployConfigs`,
  TAGS_LIST: `${RESOURCE_URL}/Tags`,
  TARIFFS_LIST: `${RESOURCE_URL}/Tariffs`,
  INSTANCES_TENANT_LIST: `${RESOURCE_URL}/Infrastructure/Tenants`,
  COMPONENT_TYPES_LIST: `${RESOURCE_URL}/ComponentTypes`,
  TIME_ZONES_LIST: `${RESOURCE_URL}/TimeZones`,

  // User profile
  USER_INFO: `${HOME_URL}/UserInfo`,
  APP_CONFIG: `${HOME_URL}/Configuration`,
  USER_ACCOUNTS: `${SSO_URL}/api/resource/externalProviders`,
  USER_TWO_FACTOR_STATE: `${ADMINISTRATION_URL}/Tenant/GetTwoFactorState`,
  USER_TWO_FACTOR_CHANGE: `${ADMINISTRATION_URL}/Tenant/ChangeTwoFactorState`,

  // Billing
  BILLING_OVERVEIW: `${STATISTICS_URL}/TenantEconomy`,
  BILLING_INSTANCE_OVERVIEW: `${STATISTICS_URL}/Infrastructure/TenantEconomy`,
  BILLING_TARIFF_CONFIGURE: `${TENANT_URL}/Tariff`,
  BILLING_LINE_TARIFF: `${LINE_URL}/Tariff`,

  // Language
  LANGUAGE: LANGUAGE_URL,
  LANGUAGE_OVERVIEW: `${LANGUAGE_URL}/Basic`,

  // Bot Deploy
  BOTDEPLOY_MODULE_BASIC: `${BOTDEPLOY_URL}/Module`,
  BOTDEPLOY_SERVER_BASIC: `${BOTDEPLOY_URL}/Server`,
  BOTDEPLOY_CONFIG_BASIC: `${BOTDEPLOY_URL}/Config`,

  // Nlp tester
  NLP_TESTER_DATASET: `${NLP_API_URL}/dataset`,
  NLP_TESTER_MODELS: `${NLP_API_URL}/classifier`,
  NLP_TESTER_GENERATOR: `${NLP_API_URL}/generator`,
  NLP_TESTER_DATASET_LIST: `${NLP_API_URL}/dataset/list`,

  // Variables storage
  VARIABLES_STORAGE_ALL: `${VARIABLES_STORAGE}/Values`,
  VARIABLE_STORAGE_KEY: `${VARIABLES_STORAGE}/GetKey`,
  VARIABLE_STORAGE_GENERATE_KEY: `${VARIABLES_STORAGE}/GenerateKey`,
  VARIABLE_STORAGE_VALUE: `${VARIABLES_STORAGE}/Value`,
  VARIABLE_STORAGE_EDIT: `${VARIABLES_STORAGE}/EditValue`,

  // Temp
  CAMPAIGN_PREPARE: `${ADMINISTRATION_URL}/Campaign/Prepare`,
};

export default ApiEndpoints;
