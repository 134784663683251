import { ActionTree } from "vuex";
import InstancesState from "@/models/store/InstancesState";
import { Actions, Getters, Mutations } from "@/models/store";
import ApiEndpoints from "@/constants/apiEndpoints";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import router from "@/router/router";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";

const nlpTesterActions: ActionTree<InstancesState, []> = {
  [Actions.NLP_CATEGORY_OVERVIEW_FETCH]: (
    { commit, dispatch, getters },
    { datasetId, language, category }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: `${ApiEndpoints.NLP_TESTER_DATASET}/${language}/${datasetId}/category/${category}`,
      action: Actions.NLP_CATEGORY_OVERVIEW_FETCH,
      withTitleChange: false,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_NLP_CATEGORY, {
          ...getters[Getters.INSPECTED_NLP_CATEGORY],
          basic: { content: data, editableFields: [] },
        }),
      error: () => commit(Mutations.SET_INSPECTED_NLP_CATEGORY, {}),
    });
  },

  [Actions.NLP_CATEGORY_SAMPLES_FETCH]: (
    { commit, dispatch, getters },
    { datasetId, language, category }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: `${ApiEndpoints.NLP_TESTER_DATASET}/${language}/${datasetId}/category/${category}/samples`,
      action: Actions.NLP_CATEGORY_SAMPLES_FETCH,
      withTitleChange: false,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_NLP_CATEGORY, {
          ...getters[Getters.INSPECTED_NLP_CATEGORY],
          samples: { content: data?.samples, editableFields: ["samples"] },
        }),
      error: () => commit(Mutations.SET_INSPECTED_NLP_CATEGORY, {}),
    });
  },

  [Actions.NLP_CATEGORY_INIT]: ({ dispatch }, params) => {
    dispatch(Actions.NLP_CATEGORY_OVERVIEW_FETCH, params);
    dispatch(Actions.NLP_CATEGORY_SAMPLES_FETCH, params);
  },

  [Actions.NLP_TESTER_DATASET_REMOVE]: (
    { dispatch },
    { language, datasetId }
  ) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      endpoint: `${ApiEndpoints.NLP_DATASETS}/${language}/${datasetId}`,
      action: Actions.NLP_TESTER_DATASET_REMOVE,
      redirectRoute: Routes.NLP_TESTER_DATASETS,
      dataGrid: DataGrids.NLP_TESTER_DATASETS,
      dataGridFetch: Actions.NLP_TESTER_DATASETS_FETCH,
      dialog: Dialogs.NLP_TESTER_DATASET_REMOVE,
      prefix: "NLP dataset",
    }),

  [Actions.NLP_CATEGORY_REMOVE]: (
    { dispatch },
    { language, datasetId, category, isDetailActive }
  ) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      endpoint: `${ApiEndpoints.NLP_DATASETS}/${language}/${datasetId}/category/${category}`,
      action: Actions.NLP_CATEGORY_REMOVE,
      redirectRoute: Routes.NLP_TESTER_CATEGORIES,
      dataGrid: DataGrids.NLP_TESTER_CATEGORIES,
      dataGridFetch: Actions.NLP_TESTER_CATEGORIES_FETCH,
      dialog: Dialogs.NLP_CATEGORY_REMOVE_DIALOG,
      prefix: "NLP category",
      isDetailActive,
    }),

  [Actions.NLP_TESTER_MODEL_REMOVE]: ({ dispatch }, { name, language }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      endpoint: `${ApiEndpoints.NLP_TESTER_MODELS}/${language}/${name}`,
      action: Actions.NLP_TESTER_MODEL_REMOVE,
      dataGrid: DataGrids.NLP_TESTER_MODELS,
      dataGridFetch: Actions.NLP_TESTER_MODELS_FETCH,
      dialog: Dialogs.NLP_TESTER_MODEL_REMOVE,
      prefix: "NLP model",
    }),

  [Actions.NLP_TESTER_DATASET_DUPLICATE]: async (
    _,
    { name, language, newInstanceName }
  ) => {
    const requestRes = await createRequest({
      action: Actions.NLP_TESTER_DATASET_DUPLICATE,
      method: "POST",
      url: `${ApiEndpoints.NLP_TESTER_DATASET}/${language}`,
      data: { datasetId: newInstanceName },
      params: { sourceDatasetId: name, sourceDatasetLanguage: language },
    });

    const { errors, data } = parseResponse(requestRes);

    if (errors.length !== 0) {
      return;
    }

    router.push({
      name: Routes.NLP_TESTER_CATEGORIES,
      params: { datasetId: data?.datasetId, language },
    });

    toggleDialog(Dialogs.NLP_TESTER_DATASET_DUPLICATE, {});
    pushNotify(Notify.SUCCESS, `Dataset "${name}" duplicated successfully`);
  },
};

export default nlpTesterActions;
