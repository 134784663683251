import Vue, { VNode } from "vue";
import { VIcon, VListItem, VSpacer, VTooltip } from "vuetify/lib";

const CampaignOverviewListItem = Vue.extend({
  props: {
    title: String,
    valueTooltip: String,
    icon: Object,
  },

  render(): VNode {
    const valueComponent = (
      <div class="primary--text font-weight-bold text-right">
        {this.$slots.default}
      </div>
    );

    return (
      <VListItem class="px-2">
        {this.icon && (
          <VIcon color={this.icon.color} class="pr-2">
            {this.icon.value}
          </VIcon>
        )}
        <div>{this.title}:</div>
        <VSpacer />
        {this.valueTooltip ? (
          <VTooltip
            top
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <div {...{ on, attrs }}>{valueComponent}</div>
              ),
            }}
          >
            {this.valueTooltip}
          </VTooltip>
        ) : (
          valueComponent
        )}
      </VListItem>
    );
  },
});

export default CampaignOverviewListItem;
