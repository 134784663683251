import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import { formatDate } from "@/helpers/dateAndTimeUtils";

const FlowVersionHistoryDuplicateDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_FLOW]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.FLOW_VERSION_DUPLICATE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_VERSION_DUPLICATE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.FLOW_VERSION_DUPLICATE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            action: () =>
              this.FLOW_VERSION_DUPLICATE({
                idCallScriptHistory: this.instanceData?.idCallScriptHistory,
              }),
            id: Actions.FLOW_VERSION_DUPLICATE,
          },
        ]}
      >
        Do you really want to duplicate flow "
        <strong class="primary--text">
          {this.INSPECTED_FLOW?.basic?.content?.name ?? "unknown"}
        </strong>
        " version from{" "}
        <strong class="primary--text">
          {formatDate(this.instanceData?.changed)}
        </strong>{" "}
        to new flow?
      </Dialog>
    );
  },
});

export default FlowVersionHistoryDuplicateDialog;
