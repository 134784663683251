import Routes from "@/constants/routes";
import Vue, { VNode } from "vue";
import TileMenu from "../TileMenu";
import { Namespaces, Permissions } from "@/constants/permissions";

const NlpTesterGuide = Vue.extend({
  data: () => ({
    items: [
      {
        title: "Datasets",
        icon: "mdi-database",
        route: Routes.NLP_TESTER_DATASETS,
        permissions: [Namespaces.NLP, [Permissions.CATEGORY_VIEW]],
      },
      {
        title: "Models",
        icon: "mdi-cube-scan",
        route: Routes.NLP_TESTER_MODELS,
        permissions: [Namespaces.NLP, [Permissions.DATASET_VIEW]],
      },
    ],
  }),
  render(): VNode {
    return <TileMenu title="NLP Training tool" items={this.items} />;
  },
});

export default NlpTesterGuide;
