enum Getters {
  // Api calls
  IS_ACTION_FETCHING = "IS_ACTION_FETCHING",
  IS_ACTION_SUCCESS = "IS_ACTION_SUCCESS",
  IS_ACTION_ERROR = "IS_ACTION_ERROR",
  IS_ACTION_INITIAL = "IS_ACTION_INITIAL",
  GET_ACTION_META = "GET_ACTION_META",

  // DataGrids
  GET_DATAGRID_DATA = "GET_DATAGRID_DATA",
  GET_DATAGRID_FILTER = "GET_DATAGRID_FILTER",
  GET_FAVORITE_FLOWS = "GET_FAVORITE_FLOWS",

  // User
  USER_INFO = "USER_INFO",
  USER_ACCOUNTS = "USER_ACCOUNTS",
  USER_TOKEN = "USER_TOKEN",
  HAS_PERMISSIONS = "HAS_PERMISSIONS",
  IS_USER_SIGNED_IN = "IS_USER_SIGNED_IN",
  APP_CONFIG = "APP_CONFIG",

  // Dialogs
  IS_DIALOG_OPEN = "IS_DIALOG_OPEN",
  GET_DIALOG_DATA = "GET_DIALOG_DATA",

  // Instances
  INSPECTED_MODULE = "INSPECTED_MODULE",
  INSPECTED_LINE = "INSPECTED_LINE",
  INSPECTED_FLOW = "INSPECTED_FLOW",
  INSPECTED_GRAMMAR = "INSPECTED_GRAMMAR",
  INSPECTED_TENANT = "INSPECTED_TENANT",
  INSPECTED_USER = "INSPECTED_USER",
  INSPECTED_PERMISSION_GROUP = "INSPECTED_PERMISSION_GROUP",
  INSPECTED_CAMPAIGN = "INSPECTED_CAMPAIGN",
  INSPECTED_BOTDEPLOY_MODULE = "INSPECTED_BOTDEPLOY_MODULE",
  INSPECTED_BOTDEPLOY_SERVER = "INSPECTED_BOTDEPLOY_SERVER",
  INSPECTED_BOTDEPLOY_CONFIG = "INSPECTED_BOTDEPLOY_CONFIG",
  INSPECTED_NLP_CATEGORY = "INSPECTED_NLP_CATEGORY",
  INSPECTED_LANGUAGE = "INSPECTED_LANGUAGE",
  INSPECTED_VARIABLES_STORAGE = "INSPECTED_VARIABLES_STORAGE",

  // Notification
  NOTIFICATION_OPTIONS = "NOTIFICATION_OPTIONS",

  // Dropdowns
  GET_DROPDOWN_DATA = "GET_DROPDOWN_DATA",

  // Resources
  LANGUAGES = "LANGUAGES",
  TAGS = "TAGS",
}

export default Getters;
