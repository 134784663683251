import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextarea } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";

const NlpTesterCategorySamplesEditForm = Vue.extend({
  data: () => ({
    fields: {
      samples: "",
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_NLP_CATEGORY]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.NLP_CATEGORY_SAMPLES_FETCH]),

    async generateSamples(
      category: string,
      samples: string[],
      count: number
    ): Promise<void> {
      const { language } = this.$route.params;

      const request = await createRequest({
        action: Actions.NLP_CATEGORY_SAMPLES_GENERATE,
        method: "POST",
        url: `${ApiEndpoints.NLP_TESTER_GENERATOR}/${language}/sentences`,
        data: { category, samples, count },
      });

      const { data, errors } = parseResponse(request);

      if (errors.length > 0) {
        return;
      }

      toggleDialog(Dialogs.NLP_TESTER_SAMPLES_GENERATE, {});

      this.fields.samples = `${this.fields.samples}${
        this.fields.samples.length > 0 ? "\r\n" : ""
      }${data.join("\r\n")}`;
    },

    async handleSubmit() {
      const { datasetId, language, category } = this.$route.params;

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        method: "PUT",
        url: `${ApiEndpoints.NLP_DATASETS}/${language}/${datasetId}/category/${category}/samples`,
        values: {
          samples: this.fields.samples.split("\r\n"),
        },
        actionId: Actions.NLP_CATEGORY_SAMPLES_EDIT,
      });
      if (isFormSubmited) {
        toggleDialog(Dialogs.NLP_CATEGORY_SAMPLES_EDIT, {});
        pushNotify(Notify.SUCCESS, "NLP Category edited sucessfully");

        this.NLP_CATEGORY_SAMPLES_FETCH({
          datasetId,
          language,
          category,
        });
      }
    },
  },

  created() {
    this.fields.samples =
      this.INSPECTED_NLP_CATEGORY?.samples?.content.join("\r\n") || [];
  },

  render(): VNode {
    return (
      <VForm ref="form">
        <VTextarea
          vModel={this.fields.samples}
          label="Samples"
          class="textarea-no-break"
          autoGrow
          autoFocus
          spellcheck={false}
        />
      </VForm>
    );
  },
});

export default NlpTesterCategorySamplesEditForm;
