import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const NlpTesterModelRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.NLP_TESTER_MODEL_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.NLP_TESTER_MODEL_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.NLP_TESTER_MODEL_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            id: Actions.NLP_TESTER_MODEL_REMOVE,
            action: () =>
              this.NLP_TESTER_MODEL_REMOVE({
                language: this.instanceData?.language,
                name: this.instanceData?.name,
              }),
          },
        ]}
      >
        Do you really want to remove "
        <strong class="primary--text">{this.instanceData?.name}</strong>" model?
        All data will be lost.
      </Dialog>
    );
  },
});

export default NlpTesterModelRemoveDialog;
