import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapGetters } from "vuex";
import Dialog from "../Dialog";
import FlowCreateForm from "../FlowCreateForm";

const FlowCreateDialog = Vue.extend({
  name: "FlowCreateDialog",
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    isComponent(): boolean {
      return this.GET_DIALOG_DATA(Dialogs.FLOW_CREATE).isComponent;
    },
  },

  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        id={Dialogs.FLOW_CREATE}
        title={this.isComponent ? "New component" : "New flow"}
        actions={[
          {
            title: "Create",
            action: () => refs?.form?.handleSubmit(),
            icon: "mdi-plus",
            id: Actions.FLOW_CREATE,
          },
        ]}
      >
        <FlowCreateForm ref="form" />
      </Dialog>
    );
  },
});

export default FlowCreateDialog;
