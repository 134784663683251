import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Actions, Getters, Modules, Mutations } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import { VChip, VIcon } from "vuetify/lib";
import {
  parseTarrif,
  getTariffUnitAndIcon,
  getTariffConfig,
} from "@/helpers/billingTariffs";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const BillingLinesDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.LINE_TARRIFS_DATAGRID_FETCH]),
    ...mapMutations(Modules.DIALOGS, [Mutations.TOGGLE_DIALOG]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      key: "idLine",
      title: "Lines",
      isHeaderOutside: false,
      disableElevation: true,
      rowActions: (row) => ({
        primary: {
          action: () =>
            toggleDialog(Dialogs.BILLING_TARIFF_EDIT, {
              ...row,
              withTariffReference: true,
            }),
          icon: "mdi-pencil",
          title: "Edit",
          isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
            Permissions.TARIFF_EDIT,
          ]),
        },
      }),
      headers: [
        { text: "Line", value: "line" },
        { text: "Group", value: "group" },
        { text: "Tariff", value: "tariff" },
        { text: "Call price", value: "callPrice" },
      ],
      filters: [
        {
          name: "line",
        },
      ],
      columnModificators: {
        "item.tariff": ({ item }) =>
          item.tariff.guid ? item.tariff.description : "-",
        "item.group": ({ item }) => {
          const tariffConfig = getTariffConfig(parseTarrif(item).group);
          return tariffConfig ? (
            <VChip color={tariffConfig.color}>
              <VIcon left>{tariffConfig.icon}</VIcon>
              {tariffConfig.text}
            </VChip>
          ) : (
            <VChip>
              <VIcon left>mdi-help-circle-outline</VIcon>
              Unknown
            </VChip>
          );
        },
        "item.callPrice": ({ item }) => {
          const tariff = parseTarrif(item);
          const tariffMeta = getTariffUnitAndIcon(
            item?.tariff?.type,
            tariff?.divider
          );

          return (
            tariff?.price && (
              <span>
                <VIcon
                  class="mr-1"
                  small
                  color={tariffMeta?.color}
                >{`mdi-${tariffMeta?.icon}`}</VIcon>
                <strong>
                  {tariff?.price?.toLocaleString("cs")} {tariff.currency}
                </strong>{" "}
                / {tariffMeta?.unit}
              </span>
            )
          );
        },
        "item.divider": ({ item }) => parseTarrif(item).divider,
      },
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.LINE_TARRIFS_DATAGRID_FETCH}
          fetchProps={{
            idTenant: this.$route.params.idTenant,
          }}
          data={this.GET_DATAGRID_DATA(DataGrids.LINE_TARIFFS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(
            Actions.LINE_TARRIFS_DATAGRID_FETCH
          )}
        />
      </div>
    );
  },
});

export default BillingLinesDataGrid;
