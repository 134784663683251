import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const LineMonitoringStateToggleDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    monitoringData(): any {
      return this.GET_DIALOG_DATA(Dialogs.LINE_MONITORING_STATE_TOGGLE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_MONITORING_STATE_TOGGLE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.LINE_MONITORING_STATE_TOGGLE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            id: Actions.LINE_MONITORING_STATE_TOGGLE,
            action: () => {
              const { enable, ...monitoringData } = this.monitoringData;
              this.LINE_MONITORING_STATE_TOGGLE(monitoringData);
            },
          },
        ]}
      >
        Do you really want to{" "}
        {this.monitoringData.enable ? "disable" : "enable"} "
        <strong class="primary--text">{this.monitoringData.name}</strong>"
        monitoring?
      </Dialog>
    );
  },
});

export default LineMonitoringStateToggleDialog;
