import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import VueJsonEditor from "vue-json-editor";
import {
  VForm,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextField,
} from "vuetify/lib";

const LineCleaningEditForm = Vue.extend({
  data: () => ({
    fields: {
      azure: "",
      diallogs: "",
      phone: "",
      speakflows: "",
      unrecognizedReactions: "",
      variables: "",
    },
    activeTab: 0,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),

    formattedData: {
      cache: false,
      get(): any {
        return this.parseFormattedData();
      },
      set(value: any) {
        this.initFormData(value);
      },
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_CLEANING_SETTINGS_FETCH]),

    parseFormattedData(): any {
      const uniqueDays = [...new Set(Object.values(this.fields))];
      const formattedObj = [] as any;

      uniqueDays.map((groupDay: any) => {
        if (groupDay === "") {
          return;
        }

        const settings = {} as any;
        Object.entries(this.fields).map(([param, oldInDays]) => {
          oldInDays === groupDay
            ? (settings[param] = true)
            : (settings[param] = false);
        });
        settings.oldInDays = parseInt(groupDay);

        formattedObj.push(settings);
      });

      return formattedObj;
    },

    initFormData(groups: any[]): any {
      groups.map((group: any) => {
        const { oldInDays, ...fields } = group;

        Object.entries(fields).map(([param, days]) => {
          days ? (this.fields[param] = oldInDays.toString()) : null;
        });
      });
    },

    async handleSubmit(): Promise<void> {
      const { isFormSubmited } = await submitForm({
        actionId: Actions.LINE_CLEANING_SETTINGS_EDIT,
        values: this.formattedData,
        url: ApiEndpoints.LINE_CLEANING_SETTINGS,
        params: { idDialModuleLine: this.$route.params.idDialModuleLine },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LINE_CLEANING, {});
        pushNotify(
          Notify.SUCCESS,
          "Line cleaning settings edited successfully"
        );

        this.LINE_CLEANING_SETTINGS_FETCH({
          idDialModuleLine: this.$route.params.idDialModuleLine,
        });
      }
    },
  },

  created() {
    this.initFormData(this.INSPECTED_LINE?.cleaningSettings?.content);
  },

  render(): VNode {
    return (
      <div>
        <VTabs grow vModel={this.activeTab}>
          <VTab>Form</VTab>
          <VTab>Source</VTab>
        </VTabs>

        <VTabsItems vModel={this.activeTab}>
          <VTabItem>
            <VForm class="pt-5">
              <VTextField
                vModel={this.fields.diallogs}
                label="All records"
                type="number"
                suffix="days"
              />

              <VTextField
                vModel={this.fields.azure}
                label="Azure content"
                type="number"
                suffix="days"
              />

              <VTextField
                vModel={this.fields.phone}
                label="Phone"
                type="number"
                suffix="days"
              />

              <VTextField
                vModel={this.fields.speakflows}
                label="Speak flows"
                type="number"
                suffix="days"
              />

              <VTextField
                vModel={this.fields.unrecognizedReactions}
                label="Unrecognized reactons"
                type="number"
                suffix="days"
              />

              <VTextField
                vModel={this.fields.variables}
                label="Variables"
                type="number"
                suffix="days"
              />
            </VForm>
          </VTabItem>
          <VTabItem>
            <VueJsonEditor vModel={this.formattedData} mode="code" />
          </VTabItem>
        </VTabsItems>
      </div>
    );
  },
});

export default LineCleaningEditForm;
