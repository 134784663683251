import { Getters, Modules } from "@/models/store";
import store from "@/store";

const formatNumber = (number: number) => (number > 9 ? number : `0${number}`);

export const formatDateForDatePicker = (date: Date | string | null): string => {
  if (date === null || date === "") {
    return "";
  }

  const dateFormat = new Date(date);

  const year = dateFormat.getFullYear();
  const month = formatNumber(dateFormat.getMonth() + 1);
  const day = formatNumber(dateFormat.getDate());

  return `${year}-${month}-${day}`;
};

export const formatTimeForTimePicker = (date: Date | string | null): string => {
  if (date === null || date === "") {
    return "";
  }

  const dateFormat = new Date(date);

  const hours = String(dateFormat.getHours()).padStart(2, "0");
  const minutes = String(dateFormat.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const getFirstDayCurrentMonth = (): Date => {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDayCurrentMonth = (): Date => {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();

  return new Date(year, month + 1, 0);
};

export const formatDate = (
  date: string,
  options = {},
  local = "cs-CZ"
): string => {
  const timeZone =
    store.getters[`${Modules.USER}/${Getters.USER_INFO}`]?.consolePreference
      ?.timezone;

  return new Date(date).toLocaleString(local, {
    ...(timeZone && { timeZone }),
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    ...options,
  });
};

export const formatTime = (time: number): string => {
  let seconds = 0;
  let minutes = 0;

  if (time < 60) {
    seconds = time;
  } else {
    minutes = Math.trunc(time / 60);
    seconds = time % 60;
  }

  return `${minutes > 0 ? `${minutes}min` : ""} ${seconds}s`;
};

export const toDaysHoursAndMinutes = (totalSeconds: number): string => {
  const totalHours = Math.floor(totalSeconds / (60 * 60));
  const totalMinutes = Math.floor(totalSeconds / 60);

  const days = Math.floor(totalSeconds / (60 * 60 * 24));
  const hours = totalHours % 24;
  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;

  return `${days ? days + " days, " : ""}
  ${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
};

export const formatDateForInput = (date: string): string => {
  return new Date(formatDate(date, {}, "en-US")).toISOString();
};

export const formatTimezoneDateToUTC = (date: string): string => {
  const currentDate = new Date();
  const currentTimezoneDate = formatDate(currentDate.toString(), {}, "en-US");

  const timeDiff = Math.round(
    (currentDate.getTime() - new Date(currentTimezoneDate).getTime()) /
      (1000 * 60 * 60)
  );

  const inputDate = new Date(date);
  const transformedDateTime = inputDate.getTime() + timeDiff * 60 * 60 * 1000;
  inputDate.setTime(transformedDateTime);

  return inputDate.toISOString();
};
