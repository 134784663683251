import Vue, { VNode } from "vue";
import { VAutocomplete } from "vuetify/lib";

const NlpTextClassifierSelect = Vue.extend({
  props: {
    value: String,
  },

  data: () => ({
    items: [
      { text: "MLNet", value: "MLNet" },
      { text: "FastText", value: "FastText" },
      { text: "Levenshtein", value: "Levenshtein" },
      { text: "Cosine", value: "Cosine" },
      { text: "WordWeight", value: "WordWeight" },
      { text: "WordContext", value: "WordContext" },
    ],
  }),
  render(): VNode {
    return (
      <VAutocomplete
        items={this.items}
        onInput={(val: string) => this.$emit("input", val)}
        value={this.value}
      />
    );
  },
});

export default NlpTextClassifierSelect;
