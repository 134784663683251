import Dialogs from "@/constants/dialogs";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import { mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import CampaignCreateForm from "../CampaignCreateForm";

const STEP_BUTTONS = {
  1: { title: "Create", icon: "mdi-plus", id: Actions.CAMPAIGN_CREATE },
  2: {
    title: "Import",
    icon: "mdi-import",
    id: Actions.CAMPAIGN_IMPORT_CONTACTS,
  },
  3: {
    title: "Save",
    icon: "mdi-content-save",
    id: Actions.CAMPAIGN_SCHEDULE_EDIT,
  },
};

const CampaignCreateDialog = Vue.extend({
  props: {
    prefilled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    currentStep: 1,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.IS_DIALOG_OPEN]),
  },

  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        id={Dialogs.CAMPAIGN_CREATE}
        title="New campaign"
        actions={
          this.currentStep > 1
            ? [
                {
                  id: "0",
                  title: "Skip",
                  icon: STEP_BUTTONS[2]?.icon,
                  color: "secondary primary--text",
                  action: () => refs.form?.stepChange(),
                },
                {
                  id: STEP_BUTTONS[this.currentStep]?.id,
                  title: STEP_BUTTONS[this.currentStep]?.title,
                  icon: STEP_BUTTONS[this.currentStep]?.icon,
                  action: () => refs.form?.submitStep(),
                },
              ]
            : [
                {
                  id: STEP_BUTTONS[this.currentStep]?.id,
                  title: STEP_BUTTONS[this.currentStep]?.title,
                  icon: STEP_BUTTONS[this.currentStep]?.icon,
                  action: () => refs.form?.submitStep(),
                },
              ]
        }
        width={625}
      >
        <CampaignCreateForm
          ref="form"
          prefilled={this.prefilled}
          onStepChange={(step: number) => (this.currentStep = step)}
        />
      </Dialog>
    );
  },
});

export default CampaignCreateDialog;
