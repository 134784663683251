import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import VueJsonEditor from "vue-json-editor";
import {
  VForm,
  VSelect,
  VSlider,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextField,
} from "vuetify/lib";

const Fields = {
  MaxInboundCalls: "MaxInboundCalls",
  MaxOutboundCalls: "MaxOutboundCalls",
  CallMode: "CallMode",
  Proxy: "Proxy",
  LiveTranslate: "LiveTranslate",
  Endpoint: "Endpoint",
  Language: "Language",
  VoiceName: "VoiceName",
  Pitch: "Pitch",
  Speed: "Speed",
  WelcomeMessage: "WelcomeMessage",
  Party1: "Party1",
  Party2: "Party2",
  ActionOnFailure: "ActionOnFailure",
  FailureRedirectDestination: "FailureRedirectDestination",
};

const LineCallbotConfigurationForm = Vue.extend({
  data: () => ({
    configuration: {},
    activeTab: 0,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_CALLBOT_CONFIG_FETCH]),

    handleCallMode(): void {
      if (this.configuration[Fields.CallMode] == "ProxySIPClient") {
        this.configuration[Fields.Proxy] = {};
      } else if (typeof this.configuration[Fields.Proxy] == "object") {
        delete this.configuration[Fields.Proxy];
      }

      if (this.configuration[Fields.CallMode] == "LiveTranslate") {
        this.configuration[Fields.LiveTranslate] = {
          Type: 0,
          Party1: {
            EndpointType: 0,
            VoiceVendor: "Microsoft",
            Pitch: 1,
            Speed: 1,
          },
          Party2: {
            EndpointType: 0,
            VoiceVendor: "Microsoft",
            Pitch: 1,
            Speed: 1,
          },
        };
      } else if (typeof this.configuration[Fields.LiveTranslate] == "object") {
        delete this.configuration[Fields.LiveTranslate];
      }

      if (this.configuration[Fields.CallMode] == null) {
        delete this.configuration[Fields.CallMode];
      }
    },

    handleActionOnFailure(): void {
      if (this.configuration[Fields.ActionOnFailure] == null) {
        delete this.configuration[Fields.ActionOnFailure];
        delete this.configuration[Fields.FailureRedirectDestination];
      }
    },
    async handleSubmit(): Promise<void> {
      const { isFormSubmited } = await submitForm({
        actionId: Actions.LINE_CALLBOT_CONFIG_EDIT,
        values: { content: JSON.stringify(this.configuration) },
        url: ApiEndpoints.LINE_CALLBOT_SETTINGS,
        params: { idDialModuleLine: this.$route.params.idDialModuleLine },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LINE_EDIT_CALLBOT_CONFIG, {});
        pushNotify(Notify.SUCCESS, "Line configuration edited successfully");

        this.LINE_CALLBOT_CONFIG_FETCH({
          idDialModuleLine: this.$route.params.idDialModuleLine,
        });
      }
    },
  },

  created() {
    this.configuration = {
      ...this.INSPECTED_LINE?.callbotSettings?.content,
    };
  },

  render(): VNode {
    return (
      <div>
        <VTabs grow vModel={this.activeTab}>
          <VTab>Form</VTab>
          <VTab>Source</VTab>
        </VTabs>

        <VTabsItems vModel={this.activeTab}>
          <VTabItem>
            <VForm class="pt-3">
              <VTextField
                vModel={this.configuration[Fields.MaxInboundCalls]}
                type="number"
                label="Max Inbound Calls"
              />
              <VTextField
                vModel={this.configuration[Fields.MaxOutboundCalls]}
                type="number"
                label="Max Outbound Calls"
              />

              <VSelect
                vModel={this.configuration[Fields.CallMode]}
                items={["SIPClient", "ProxySIPClient", "LiveTranslate"]}
                label="Call Mode"
                clearable
                on={{
                  change: () => this.handleCallMode(),
                }}
              />

              {this.configuration[Fields.CallMode] == "ProxySIPClient" &&
                typeof this.configuration[Fields.Proxy] == "object" && (
                  <div class="ms-2">
                    <VTextField
                      vModel={this.configuration[Fields.Proxy][Fields.Endpoint]}
                      type="string"
                      label="Proxy Endpoint"
                      required
                    />
                  </div>
                )}

              {this.configuration[Fields.CallMode] == "LiveTranslate" &&
                typeof this.configuration[Fields.LiveTranslate] == "object" && (
                  <div class="ms-2">
                    <VTextField
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party1][
                          Fields.Language
                        ]
                      }
                      type="string"
                      label="Party1: Language"
                    />
                    <VTextField
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party1][
                          Fields.VoiceName
                        ]
                      }
                      type="string"
                      label="Party1: Voice Name"
                    />
                    <VSlider
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party1][
                          Fields.Pitch
                        ]
                      }
                      label="Party1: Pitch"
                      thumbLabel
                      min="-2"
                      max="2"
                      step="0.01"
                      class="mt-4"
                      default="1"
                    />
                    <VSlider
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party1][
                          Fields.Speed
                        ]
                      }
                      label="Party1: Speed"
                      thumbLabel
                      min="-2"
                      max="2"
                      step="0.01"
                      class="mt-4"
                      default="1"
                    />
                    <VTextField
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party1][
                          Fields.WelcomeMessage
                        ]
                      }
                      type="string"
                      label="Party1: Welcome Message"
                    />
                    <VTextField
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party1][
                          Fields.Endpoint
                        ]
                      }
                      type="string"
                      label="Party1: Endpoint"
                      required
                    />
                    <VTextField
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party2][
                          Fields.Language
                        ]
                      }
                      type="string"
                      label="Party2: Language"
                    />
                    <VTextField
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party2][
                          Fields.VoiceName
                        ]
                      }
                      type="string"
                      label="Party2: Voice Name"
                    />
                    <VSlider
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party2][
                          Fields.Pitch
                        ]
                      }
                      label="Party2: Pitch"
                      thumbLabel
                      min="-2"
                      max="2"
                      step="0.01"
                      class="mt-4"
                    />
                    <VSlider
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party2][
                          Fields.Speed
                        ]
                      }
                      label="Party2: Speed"
                      thumbLabel
                      min="-2"
                      max="2"
                      step="0.01"
                      class="mt-4"
                      default="1"
                    />
                    <VTextField
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party2][
                          Fields.WelcomeMessage
                        ]
                      }
                      type="string"
                      label="Party2: Welcome Message"
                    />
                    <VTextField
                      vModel={
                        this.configuration[Fields.LiveTranslate][Fields.Party2][
                          Fields.Endpoint
                        ]
                      }
                      type="string"
                      label="Party2: Endpoint"
                      required
                    />
                  </div>
                )}

              <VSelect
                vModel={this.configuration[Fields.ActionOnFailure]}
                items={["redirect"]}
                label="Action on Failure"
                on={{
                  change: () => this.handleActionOnFailure(),
                }}
                clearable
              />

              {this.configuration[Fields.ActionOnFailure] == "redirect" && (
                <VTextField
                  vModel={this.configuration[Fields.FailureRedirectDestination]}
                  type="string"
                  label="Redirect Destination"
                />
              )}
            </VForm>
          </VTabItem>
          <VTabItem>
            <VueJsonEditor vModel={this.configuration} mode="code" />
          </VTabItem>
        </VTabsItems>
      </div>
    );
  },
});

export default LineCallbotConfigurationForm;
