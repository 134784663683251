import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import NlpTesterDatasetsCreateDialog from "../NlpTesterDatasetsCreateDialog";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import NlpTesterDatasetRemoveDialog from "../NlpTesterDatasetRemoveDialog";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import NlpDatasetsDuplicateDialog from "../NlpDatasetsDuplicateDialog";

const NlpTesterDatasetsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.NLP_TESTER_DATASETS_FETCH]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "NLP datasets",
      isHeaderOutside: true,
      key: "name",
      buttons: [
        {
          title: "Back",
          icon: "mdi-arrow-left",
          action: () => this.$router.push({ name: Routes.NLP_TESTER }),
          isPrimary: false,
        },
        {
          role: "primary",
          title: "New dataset",
          icon: "mdi-plus",
          action: () => toggleDialog(Dialogs.NLP_TESTER_DATASET_CREATE, {}),
          isAllowed: hasUserPermissions(Namespaces.NLP, [
            Permissions.DATASET_CREATE,
          ]),
        },
      ],
      rowActions: ({ language, name }) => ({
        primary: {
          icon: "mdi-cog",
          title: "Settings",
          isAllowed: hasUserPermissions(Namespaces.NLP, [
            Permissions.DATASET_VIEW,
          ]),
          action: () =>
            this.$router.push({
              name: Routes.NLP_TESTER_CATEGORIES,
              params: {
                language: language,
                datasetId: name,
              },
            }),
        },
        secondary: [
          {
            icon: "mdi-content-copy",
            title: "Duplicate",
            action: () =>
              toggleDialog(Dialogs.NLP_TESTER_DATASET_DUPLICATE, {
                name,
                language,
              }),
            isAllowed: hasUserPermissions(Namespaces.NLP, [
              Permissions.DATASET_CREATE,
            ]),
          },
          {
            icon: "mdi-delete",
            title: "Remove",
            isAllowed: hasUserPermissions(Namespaces.NLP, [
              Permissions.DATASET_CREATE,
            ]),
            action: () =>
              toggleDialog(Dialogs.NLP_TESTER_DATASET_REMOVE, {
                language,
                datasetId: name,
              }),
          },
        ],
      }),
      filters: [{ name: "name" }],
      headers: [
        { text: "Name", value: "name" },
        { text: "Language", value: "language" },
        { text: "Last change", value: "changed" },
        { text: "Size", value: "size" },
        { text: "Category count", value: "categoryCount" },
      ],
      columnModificators: {
        "item.size": ({ item }) => `${item.size / 1000} kB`,
        "item.changed": ({ item }) => formatDate(item.changed),
      },
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.NLP_TESTER_DATASETS_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.NLP_TESTER_DATASETS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(
            Actions.NLP_TESTER_DATASETS_FETCH
          )}
        />
        <NlpTesterDatasetsCreateDialog />
        <NlpTesterDatasetRemoveDialog />
        <NlpDatasetsDuplicateDialog />
      </div>
    );
  },
});

export default NlpTesterDatasetsDataGrid;
