import Dialogs from "@/constants/dialogs";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VDivider, VIcon, VSimpleTable } from "vuetify/lib";
import { mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import NlpTesterCategorySamplesEditForm from "../NlpTesterCategorySamplesEditForm";

const NlpTesterCategoryDetailOverview = Vue.extend({
  data: () => ({
    basic: {},
    samples: {
      title: "Samples",
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_NLP_CATEGORY]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    categorySamples(): string[] {
      return this.INSPECTED_NLP_CATEGORY.samples?.content || [];
    },
  },

  created() {
    this.basic = {
      title: "Overview",
      columns: [
        { name: "name", title: "Name" },
        { name: "language", title: "Language" },
        { name: "sampleCount", title: "Sample count" },
        { name: "size", title: "Size" },
        { name: "created", title: "Created at" },
        { name: "changed", title: "Last changed at" },
      ],
      modificators: {
        changed: ({ changed }: any) => formatDate(changed),
        created: ({ created }: any) => formatDate(created),
        size: ({ size }: any) => `${size / 1000} kB`,
      },
    };
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_NLP_CATEGORY?.basic}
          action={Actions.NLP_CATEGORY_OVERVIEW_FETCH}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.NLP_CATEGORY_OVERVIEW_FETCH
          )}
        />
        <VDivider />
        <EditableOverviewSection
          configuration={this.samples}
          data={this.INSPECTED_NLP_CATEGORY?.samples}
          editDialog={Dialogs.NLP_CATEGORY_SAMPLES_EDIT}
          editDialogWidth={3000}
          editSecondaryActions={[
            {
              action: () =>
                toggleDialog(Dialogs.NLP_TESTER_SAMPLES_GENERATE, {
                  editFormRef: this.$refs.form,
                }),
              title: "Generate",
              icon: "mdi-cog-transfer",
            },
          ]}
          action={Actions.NLP_CATEGORY_SAMPLES_EDIT}
          scopedSlots={{
            form: () => <NlpTesterCategorySamplesEditForm ref="form" />,
          }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.NLP_CATEGORY_SAMPLES_FETCH
          )}
          overviewComponent={
            <div>
              <VSimpleTable dense>
                {this.categorySamples.length > 0 ? (
                  <tbody>
                    {this.categorySamples.map((item) => (
                      <tr>
                        <td class="py-1">{item}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <span>There is no content yet.</span>
                )}
              </VSimpleTable>
              {/* <VBtn class="secondary primary--text mt-3">
                Show all <VIcon>mdi-chevron-down</VIcon>
              </VBtn> */}
            </div>
          }
        />
      </div>
    );
  },
});

export default NlpTesterCategoryDetailOverview;
