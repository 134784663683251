import { TARIFF_GROUPS } from "@/constants/tariffs";

export const parseTarrif = (item: any): any => {
  return JSON.parse(item?.tariff?.content) || {};
};

export const getTariffUnitAndIcon = (type: number, divider: string): any => {
  if (type === 2) {
    return { unit: "call", icon: "counter", color: "success" };
  }

  return divider === "1"
    ? { unit: "s", icon: "clock-time-seven", color: "info" }
    : { unit: "min", icon: "clock-time-seven", color: "info" };
};

export const getTariffConfig = (groupId: string): any =>
  TARIFF_GROUPS.find((tariff: any) => tariff.value === groupId) ?? null;
