import Vue, { VNode } from "vue";
import { VFooter, VCol } from "vuetify/lib";

const Footer = Vue.extend({
  computed: {
    companyName(): string {
      const theme = process.env.VUE_APP_THEME;

      return theme === "default"
        ? "Vocalls"
        : theme.split(/(?=[A-Z])/).join(" ");
    },
  },

  render(): VNode {
    return (
      <VFooter padlles color="dark white--text">
        <VCol class="text-center" cols="12">
          <span class="text-capitalize">{this.companyName}</span> - Copyright{" "}
          {new Date().getFullYear()}, All rights reserved.
        </VCol>
      </VFooter>
    );
  },
});

export default Footer;
