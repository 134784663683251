import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import LineSelect from "../LineSelect";
import toggleDialog from "@/helpers/toggleDialog";
import ApiEndpoints from "@/constants/apiEndpoints";
import { isRequired } from "@/helpers/validations";
import {
  VForm,
  VContainer,
  VRow,
  VCol,
  VTextField,
  VAutocomplete,
  VListItemContent,
  VListItemTitle,
  VListItem,
} from "vuetify/lib";

const FlowMakeTestCall = Vue.extend({
  name: "FlowMakeTestCall",
  data: () => ({
    id: null,
    phoneNumber: "" as string,
    selectedLine: "" as string | null,
    selectedLanguage: "" as string | null,
    languagesList: [] as any[],
    guid: "" as string,
    parameters: "" as string,
    openMakeTestCallDialog: false as boolean,
    responseData: "",
    isDataLoaded: false as boolean,
    message: [] as any[],
    ws: null as WebSocket | null,
    intervalId: null as any,
    status: "" as string,
    statusError: false as boolean,
    error: "" as string,
    isFormValid: false as boolean,
  }),
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    flowData(): any {
      return this.GET_DIALOG_DATA(Dialogs.FLOW_TEST_CALL);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.INSTANCE_TEST_CALL]),
    ...mapActions(Modules.INSTANCES, [Actions.INSTANCE_GET_CALL_STATUS]),

    async makeTestCall() {
      const refs: any = this.$refs;
      const isFormValid = await refs.form.validate();

      if (!isFormValid) return;

      this.saveToLocalStorage();

      try {
        const importData = await this.INSTANCE_TEST_CALL({
          guid: this.guid,
          phone: this.phoneNumber,
          language: this.selectedLanguage,
          moduleCode: this.selectedLine,
          parameters: this.parameters,
        });

        if (importData.success) {
          this.responseData = importData.data.guid;
          this.isDataLoaded = true;
          this.initWebSocket();
          this.intervalId = setInterval(() => {
            this.getCallStatus(this.responseData);
          }, 3500);
          toggleDialog(Dialogs.FLOW_TEST_CALL);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    async getCallStatus(guid: string) {
      try {
        const importData = await this.INSTANCE_GET_CALL_STATUS({
          id: guid,
        });
        if (importData.success) {
          const callStatus = importData.data.status;

          this.status = `A call to ${this.phoneNumber} is in progress...`;

          if (callStatus.includes("Result")) {
            this.closeConnections();
            callStatus.includes("Error") ? (this.statusError = true) : null;
            this.status = "Conversation result:";
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    initFlowLanguages() {
      if (!this.flowData?.languages) return;
      const languages = JSON.parse(this.flowData.languages);
      const languagesData: any[] = Object.entries(languages).map(
        ([key, value]: any) => {
          return { text: value.languageName, value: key };
        }
      );

      this.languagesList = languagesData;
    },

    initGuid() {
      if (!this.flowData?.guid) return;
      this.guid = this.flowData.guid;
    },

    initWebSocket() {
      const url =
        ApiEndpoints.TEST_CALL_WS_URL + "?callGuid=" + this.responseData;
      this.ws = new WebSocket(url);

      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.speakFlowItem != null) {
          const item = data.speakFlowItem;
          let messageText = {};

          switch (item.Type) {
            case 3:
              messageText = {
                type: "Robot voice",
                activity: item.Activity,
                color: "primary--text",
              };
              break;
            case 5:
              messageText = {
                type: "Human voice",
                activity: item.Activity,
                color: "primary--text",
              };
              break;
            case 8:
              messageText = {
                type: "Error",
                activity: item.Activity,
                color: "error--text",
              };
              break;
            case 12:
              messageText = {
                type: "Debug",
                activity: item.Activity,
                color: "grey--text",
              };
              break;
            case 13:
              messageText = {
                type: "Warning",
                activity: item.Activity,
                color: "warning--text",
              };
              break;
          }
          this.message.push(messageText);
        }
      };
    },

    closeConnections() {
      if (this.ws) {
        this.ws.close();
      }
      clearInterval(this.intervalId);
    },

    closeTestCallDialog() {
      this.closeConnections();
      this.message = [];
      this.status = "";
      this.statusError = false;
      toggleDialog(Dialogs.FLOW_TEST_CALL_PROGRESS);
      toggleDialog(Dialogs.FLOW_TEST_CALL);
    },

    saveToLocalStorage() {
      localStorage.setItem("phoneNumber", this.phoneNumber);
      localStorage.setItem("selectedLanguage", this.selectedLanguage as string);
      localStorage.setItem("selectedLine", this.selectedLine as string);
    },
  },

  created() {
    this.phoneNumber = localStorage.getItem("phoneNumber") || "";
    this.selectedLanguage = localStorage.getItem("selectedLanguage") || "";
    this.selectedLine = localStorage.getItem("selectedLine") || "";
  },

  render(): VNode {
    return (
      <div>
        <Dialog
          id={Dialogs.FLOW_TEST_CALL}
          title="Test call"
          closeOnEsc
          actions={[
            {
              title: "Make test call",
              icon: "mdi-phone",
              action: () => {
                this.makeTestCall();
                toggleDialog(Dialogs.FLOW_TEST_CALL_PROGRESS);
              },
            },
          ]}
          toggleHandler={() => {
            this.initFlowLanguages();
            this.initGuid();
          }}
        >
          <VForm ref="form" v-model={this.isFormValid}>
            <VContainer>
              <VRow>
                <VCol cols="12">
                  <VTextField
                    label="Phone number"
                    v-model={this.phoneNumber}
                    rules={[isRequired]}
                  />
                </VCol>
              </VRow>
              <LineSelect
                fetchUrl={ApiEndpoints.LINE_LIST_WITH_MODULE}
                useModificator
                vModel={this.selectedLine}
                options={{
                  rules: [isRequired],
                }}
              />
              <VAutocomplete
                label="Languages"
                items={this.languagesList}
                v-model={this.selectedLanguage}
                rules={[isRequired]}
              />
            </VContainer>
          </VForm>
        </Dialog>

        {this.isDataLoaded ? (
          <Dialog
            persistent
            id={Dialogs.FLOW_TEST_CALL_PROGRESS}
            closeOnEsc={false}
            noCancelBtn
            noCloseIcon
            width="700"
            actions={[
              {
                title: "Close",
                action: () => {
                  this.closeTestCallDialog();
                },
              },
            ]}
          >
            <VListItem>
              <VListItemContent>
                <VListItemTitle class="text-h6 primary--text font-weight-bold mt-5">
                  {this.status || "Waiting..."}
                </VListItemTitle>
              </VListItemContent>
            </VListItem>

            {this.message && this.message.length > 0
              ? this.message.map((item, index) => (
                  <VListItem>
                    <VListItemContent key={index}>
                      <VListItemTitle>
                        <span
                          class={item.color}
                          style="white-space: pre-line; overflow-wrap: break-word;"
                        >
                          <b class={"primary--text"}>{item.type}:</b>{" "}
                          {item.activity}
                        </span>
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                ))
              : null}
            {this.statusError ? (
              <VListItem>
                <VListItemContent>
                  <VListItemTitle class="error--text">
                    <b>System:</b> Error
                  </VListItemTitle>
                </VListItemContent>
              </VListItem>
            ) : null}
          </Dialog>
        ) : null}
      </div>
    );
  },
});

export default FlowMakeTestCall;
