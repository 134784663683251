import Dialogs from "@/constants/dialogs";
import TAG_COLOR_PALETTE from "@/constants/tagColorPalette";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import TagColor from "@/models/TagColor";
import Vue, { VNode } from "vue";
import { VCard, VForm, VIcon, VTextField } from "vuetify/lib";
import Dialog from "../Dialog";
import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import { Actions, Getters, Modules } from "@/models/store";
import { mapActions, mapGetters } from "vuex";
import TenantSelect from "../TenantSelect";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const TagCreateDialog = Vue.extend({
  data: () => ({
    name: "",
    color: "0",
    isFormValid: true,
    idTenant: null,
  }),

  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.TAGS_DATAGRID_FETCH]),

    async handleSubmit() {
      const { isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.FLOW_TAG,
        values: {
          title: this.name,
          color: this.color,
          idTenant: this.idTenant,
        },
        actionId: Actions.FLOW_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.TAG_CREATE, {});

        this.$emit("tagCreated", {
          title: this.name,
          color: this.color,
          idTenant: this.idTenant,
          idTag: data?.idTag,
        });

        this.TAGS_DATAGRID_FETCH();
      }
    },
  },

  created() {
    this.idTenant = this.USER_INFO.idTenant;
  },

  render(): VNode {
    return (
      <Dialog
        width={350}
        id={Dialogs.TAG_CREATE}
        title="Create tag"
        toggleHandler={() => {
          this.name = "";
          this.color = "0";
        }}
        actions={[
          {
            title: "Create",
            icon: "mdi-plus",
            action: this.handleSubmit,
            id: Actions.FLOW_CREATE,
          },
        ]}
      >
        <VForm vModel={this.isFormValid} ref="form">
          <VTextField vModel={this.name} label="Name" rules={[isRequired]} />
          {hasUserPermissions(Namespaces.CALLSCRIPT, [
            Permissions.VIEW_TAGS_ALL,
          ]) && <TenantSelect vModel={this.idTenant} />}
          <div class="d-flex mt-1">
            {TAG_COLOR_PALETTE.map(({ id, bg, text }: TagColor) => (
              <VCard
                key={id}
                width={30}
                height={30}
                color={bg}
                elevation={id === this.color ? 10 : 1}
                onClick={() => (this.color = id)}
                class="mr-2 transition-swing"
              >
                {id === this.color && (
                  <VIcon class="d-flex" color={text}>
                    mdi-check
                  </VIcon>
                )}
              </VCard>
            ))}
          </div>
        </VForm>
      </Dialog>
    );
  },
});

export default TagCreateDialog;
