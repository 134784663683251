import { ActionTree } from "vuex";
import { Actions, Modules } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import { DEFAULT_TITLE } from "@/constants/routes";
import router from "@/router";
import toggleDialog from "@/helpers/toggleDialog";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import downloadFile from "@/helpers/downloadFile";
import generateErrorMsg from "@/helpers/generateErrorMsg";
import { setStorageItem } from "@/helpers/userLocalStorage";
import DataGrids from "@/constants/dataGrids";

const instanceActions: ActionTree<InstancesState, []> = {
  [Actions.SECTION_DETAIL_FETCH]: async (
    _,
    { url, action, params, modify, error, withTitleChange, titleProp }
  ) => {
    const reqResponse = await createRequest({
      action,
      method: "GET",
      url,
      ...(params && { params }),
    });

    const { data, errors } = parseResponse(reqResponse);

    if (errors.length === 0) {
      modify(data);
      const title = titleProp ? titleProp : "name";

      withTitleChange
        ? (document.title = `${
            data?.content?.[title] || ""
          } - ${DEFAULT_TITLE}`)
        : null;
    } else {
      error();
    }
  },

  [Actions.INSTANCE_DUPLICATE]: async (
    _,
    { action, param, prefix, dialog, redirectRoute, endpoint, redirectParam }
  ) => {
    const requestRes = await createRequest({
      action,
      method: "GET",
      url: endpoint,
      params: { [param.id]: param.value },
    });

    const { errors, data } = parseResponse(requestRes);

    if (errors.length !== 0) {
      return;
    }

    router.push({
      name: redirectRoute,
      params: { [redirectParam ?? param.id]: data.id },
    });

    toggleDialog(dialog, {});
    pushNotify(Notify.SUCCESS, `${prefix} duplicated successfully`);
  },

  [Actions.INSTANCE_TEST_CALL]: async (
    _,
    { guid, language, parameters, phone, moduleCode }
  ) => {
    const scheduleRes = await createRequest({
      method: "POST",
      url: ApiEndpoints.FLOW_TEST_CALL,
      data: { language, guid, phone, moduleCode, parameters, diagnostic: true },
    });
    const { errors, data } = parseResponse(scheduleRes);

    if (errors.length === 0) {
      return { success: true, data };
    }

    return { success: false, data };
  },

  [Actions.INSTANCE_GET_CALL_STATUS]: async (_, { id }) => {
    const scheduleRes = await createRequest({
      action: Actions.GET_CALL_STATUS,
      method: "POST",
      url: ApiEndpoints.GET_CALL_STATUS_URL,
      data: { id },
    });
    const { errors, data } = parseResponse(scheduleRes);
    if (errors.length === 0) {
      return { success: true, data };
    }

    return { success: false, data };
  },

  [Actions.INSTANCE_REMOVE]: async (
    { dispatch },
    {
      params,
      isDetailActive,
      endpoint,
      action,
      redirectRoute,
      dataGrid,
      dataGridFetch,
      dialog,
      prefix,
    }
  ) => {
    const requestRes = await createRequest({
      action,
      method: "DELETE",
      url: endpoint,
      params,
    });

    const { errors } = parseResponse(requestRes);

    if (errors.length !== 0) {
      return;
    }

    if (isDetailActive) {
      router.push({ name: redirectRoute });
    } else {
      dispatch(
        `${Modules.DATA_GRIDS}/${Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS}`,
        {
          dataGrid,
          fetchActionId: dataGridFetch,
        },
        { root: true }
      );
    }

    toggleDialog(dialog, {});
    pushNotify(Notify.SUCCESS, `${prefix} removed successfully`);
  },

  [Actions.INSTANCE_FETCH_CSV_STATISTICS]: async (
    _,
    { actionId, url, params }
  ) => {
    const csvRes = await createRequest({
      action: actionId,
      url,
      method: "GET",
      responseType: "blob",
      params,
    });

    const { data, errors } = parseResponse(csvRes);

    if (errors.length === 0) {
      downloadFile(data, "test", "csv");
    } else {
      pushNotify(Notify.ERROR, generateErrorMsg(actionId));
    }
  },

  [Actions.DIAL_LOG_FETCH]: async (_, { guid, withFullConversation }) => {
    const recordRes = await createRequest({
      action: Actions.DIAL_LOG_FETCH,
      method: "GET",
      url: withFullConversation
        ? ApiEndpoints.CONVERSATION_LOG_DETAIL
        : ApiEndpoints.DIAL_LOG_DETAIL,
      params: { guid },
    });

    const { errors, data } = parseResponse(recordRes);

    if (errors.length === 0) {
      return data;
    }
  },

  [Actions.AUDIO_RECORD_FETCH]: async (_, { guid, withFullConversation }) => {
    const requestRes = await createRequest({
      action: Actions.AUDIO_RECORD_FETCH,
      method: "GET",
      url: withFullConversation
        ? ApiEndpoints.CONVERSATION_AUDIO_RECORD
        : ApiEndpoints.AUDIO_RECORD,
      params: { guid },
      responseType: "blob",
    });

    const { errors, data } = parseResponse(requestRes);

    if (errors.length === 0) {
      const audioUrl = window.URL.createObjectURL(data);
      return { audio: audioUrl, blob: data, guid: guid };
    } else {
      pushNotify(Notify.ERROR, generateErrorMsg(Actions.AUDIO_RECORD_FETCH));
    }
  },

  [Actions.AUDIO_RECORD_OPEN]: async (
    { dispatch },
    { guid, withFullConversation }
  ) => {
    const audioRecord = await dispatch(Actions.AUDIO_RECORD_FETCH, {
      guid,
      withFullConversation,
    });

    if (audioRecord) {
      toggleDialog(Dialogs.DIAL_LOG_AUDIO, audioRecord);
    }
  },

  [Actions.AUDIO_RECORD_REMOVE]: async (
    { dispatch },
    { guid, withFullConverstaion }
  ) => {
    const recordRes = await createRequest({
      action: Actions.AUDIO_RECORD_REMOVE,
      method: "DELETE",
      url: withFullConverstaion
        ? ApiEndpoints.CONVERSATION_AUDIO_RECORD
        : ApiEndpoints.AUDIO_RECORD,
      params: { guid },
    });

    const { errors } = parseResponse(recordRes);

    if (errors.length === 0) {
      toggleDialog(Dialogs.DIAL_LOG_AUDIO_REMOVE, {});
      pushNotify(Notify.SUCCESS, "Audio record removed successfully");

      dispatch(
        `${Modules.DATA_GRIDS}/${Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS}`,
        {
          dataGrid: DataGrids.DIAL_LOGS,
          fetchActionId: Actions.DIAL_LOGS_DATAGRID_FETCH,
        },
        { root: true }
      );
    }
  },

  [Actions.GENERATE_CSV_REPORTS]: async (
    _,
    {
      id,
      prefix,
      dialog,
      url,
      from,
      to,
      encoding,
      fetchParams,
      idProperty,
      withoutRange,
      customFileName,
      asConversations,
      successNotify,
    }
  ) => {
    const recordRes = await createRequest({
      action: Actions.GENERATE_CSV_REPORTS,
      method: "GET",
      url: id ? `${url}/${id}` : url,
      params: {
        encoding,
        ...(id && { id }),
        ...(asConversations !== null && { asConversations }),
        ...(!withoutRange && {
          from: new Date(`${from} 00:00:00`),
          to: new Date(`${to} 00:00:00`),
        }),
        ...fetchParams,
      },
      responseType: "blob",
    });

    const { errors, data } = parseResponse(recordRes);

    if (errors.length === 0) {
      pushNotify(
        Notify.SUCCESS,
        successNotify || "Report generated successfully"
      );
      toggleDialog(dialog);
      downloadFile(
        data,
        customFileName ??
          `report-${prefix}_id_${id ?? idProperty}-from_${from}-to_${to}`,
        "csv"
      );
      setStorageItem("csvEncoding", encoding);
    }
  },
};

export default instanceActions;
