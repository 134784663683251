import Dialogs from "@/constants/dialogs";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import { mapGetters } from "vuex";
import { Getters, Modules } from "@/models/store";
import downloadFile from "@/helpers/downloadFile";
import AudioPlayer from "../AudioPlayer";

const AudioDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [
      Getters.GET_DIALOG_DATA,
      Getters.IS_DIALOG_OPEN,
    ]),

    audio(): any {
      const dialogData = this.GET_DIALOG_DATA(Dialogs.DIAL_LOG_AUDIO);
      return {
        url: dialogData?.audio,
        blob: dialogData?.blob,
        guid: dialogData?.guid,
      };
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.DIAL_LOG_AUDIO}
        title="Play audio"
        actions={[
          {
            title: "Download",
            icon: "mdi-download",
            action: () => downloadFile(this.audio.blob, this.audio.guid, "wav"),
          },
        ]}
      >
        <AudioPlayer flat file={this.audio.url} autoPlay color="tertiary" />
      </Dialog>
    );
  },
});

export default AudioDialog;
