const TRAINING_STATUSES = [
  {
    title: "Queued",
    statuses: ["QUEUED"],
    color: "accent",
    isFinished: false,
  },
  {
    title: "In progress",
    statuses: [
      "INITIALIZING",
      "TRAINING",
      "TESTING",
      "PREPROCESSING",
      "FINALIZING",
    ],
    color: "warning",
    isFinished: false,
  },
  {
    title: "Error",
    statuses: ["CANCELLED", "ERROR"],
    color: "error",
    isFinished: false,
  },
  {
    title: "Trained",
    statuses: ["DONE"],
    color: "success",
    isFinished: true,
  },
];

export default TRAINING_STATUSES;
