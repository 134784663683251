import Vue, { VNode } from "vue";
import {
  VAutocomplete,
  VBtn,
  VCard,
  VCardText,
  VChip,
  VCol,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VIcon,
  VRow,
  VSimpleTable,
  VSkeletonLoader,
} from "vuetify/lib";
import SectionToolbar from "../SectionToolbar";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import VariablesStorageCreateDialog from "../VariablesStorageCreateDialog";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import VariableStorageRemoveDialog from "../VariableStorageRemoveDialog";
import VariableStorageEditDialog from "../VariableStorageEditDialog";
import VariableStorageGenerateKeyDialog from "../VariableStorageGenerateKeyDialog";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import DAYS_IN_WEEK from "@/constants/daysInWeek";
import parseStorageTimetable from "@/helpers/parseStorageTimetable";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import VariableStorageTenantSelectDialog from "../VariableStorageTenantSelectDialog";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import MonacoEditor from "../MonacoEditor";

const VariablesStorage = Vue.extend({
  data: () => ({
    variableGroups: [
      { id: 1, title: "Opening times" },
      { id: 0, title: "Custom variables" },
    ],
    idTenant: null as any,
    panels: [1],
    variableSelectValue: null,
  }),

  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_VARIABLES_STORAGE]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
    ...mapGetters(Modules.DROPDOWNS, [Getters.GET_DROPDOWN_DATA]),

    apiKeyOutput(): string {
      if (this.apiKeyExists) {
        const apiKey = this.INSPECTED_VARIABLES_STORAGE.key;
        const firstPart = apiKey.slice(0, 4);
        const lastPart = apiKey.slice(apiKey.length - 4, apiKey.length);

        return `${firstPart}${"*".repeat(apiKey.length - 8)}${lastPart}`;
      }

      return "No API key has been generated yet.";
    },

    tenantName(): string {
      return (
        this.GET_DROPDOWN_DATA(Dropdowns.TENANTS)?.find(
          (tenant: any) => tenant.value === parseInt(this.idTenant)
        )?.text ?? ""
      );
    },

    apiKeyExists(): boolean {
      return typeof this.INSPECTED_VARIABLES_STORAGE?.key === "string";
    },

    codeEndpointUrl(): string {
      const baseUrl =
        process.env.VUE_APP_API_URL.charAt(0) === "/"
          ? `${window.location.origin}${process.env.VUE_APP_API_URL}`
          : process.env.VUE_APP_API_URL;
      const route =
        this.variableSelectValue === null
          ? "AllValues"
          : `Value?key=${this.variableSelectValue}`;

      return `${baseUrl}/TenantValueStorage/${route}`;
    },

    codeExample(): string {
      return (
        `jsonHttpRequest(\n` +
        `  "${this.codeEndpointUrl}",\n ` +
        '  { method: "GET", "timeout": 5000 },\n' +
        `   { "x-api-key": "${this.INSPECTED_VARIABLES_STORAGE.key}" }).then(function (result) {\n` +
        '    log_debug("report " + JSON.stringify(result));\n' +
        "   }\n" +
        ");"
      );
    },

    variableSelectItems(): any {
      const items = [
        { header: "Default" },
        { text: "All variables", value: null },
        { header: "Variable keys" },
      ];

      if (!this.INSPECTED_VARIABLES_STORAGE?.variables) {
        return items;
      }

      Object.values(this.INSPECTED_VARIABLES_STORAGE?.variables).map(
        (group: any) => {
          group.map((variable: any) => {
            items.push({ text: variable.key, value: variable.key });
          });
        }
      );

      return items;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.VARIABLE_STORAGE_FETCH_ALL,
      Actions.VARIABLE_STORAGE_GENERATE_KEY,
      Actions.VARIABLE_STORAGE_FETCH_KEY,
    ]),
    ...mapActions(Modules.DROPDOWNS, [Actions.DROPDOWN_DATA_FETCH]),

    formatTimetableValue(value: string) {
      try {
        const timeRangeGroups = parseStorageTimetable(value);

        return Object.entries(timeRangeGroups.dow).map(([range, days]: any) => (
          <tr>
            <td class="pr-5 py-1 font-weight-bold" width="250">
              {days
                .map((dayId: string) => {
                  const meta = DAYS_IN_WEEK.find(
                    (day) => day.value === parseInt(dayId)
                  );
                  return meta?.text?.slice(0, 3);
                })
                .join(", ")}
            </td>
            <td>{range.replaceAll(";", ", ")}</td>
          </tr>
        ));
      } catch {
        return "Parsing error";
      }
    },

    getTimeTableMeta(value: string) {
      try {
        const parsedValue = JSON.parse(value);
        return {
          timezone: parsedValue.timezone,
          datesCount: Object.keys(parsedValue.dates)?.length,
        };
      } catch {
        return { timezone: "-", datesCount: 0 };
      }
    },

    async generateApiKey(): Promise<void> {
      await this.VARIABLE_STORAGE_GENERATE_KEY(this.idTenant);
      this.VARIABLE_STORAGE_FETCH_KEY(this.idTenant);
    },

    copyApiKeyToClipboard(): void {
      navigator.clipboard.writeText(this.INSPECTED_VARIABLES_STORAGE.key);
      pushNotify(Notify.INFO, "API key copied to the clipboard.");
    },

    copyCodeExampleToClipboard(): void {
      navigator.clipboard.writeText(this.codeExample);
      pushNotify(Notify.INFO, "Code example copied to the clipboard.");
    },

    switchTenant(idTenant: any) {
      this.idTenant = parseInt(idTenant);

      this.$router.push({ query: { idTenant } });
      this.VARIABLE_STORAGE_FETCH_ALL(this.idTenant);
      this.VARIABLE_STORAGE_FETCH_KEY(this.idTenant);

      toggleDialog(Dialogs.VARIABLE_STORAGE_TENANT_SELECT);
    },
  },

  created() {
    this.idTenant = this.$route.query.idTenant
      ? parseInt(this.$route.query.idTenant.toString())
      : this.USER_INFO.idTenant;

    this.VARIABLE_STORAGE_FETCH_ALL(this.idTenant);
    this.VARIABLE_STORAGE_FETCH_KEY(this.idTenant);
    this.DROPDOWN_DATA_FETCH({
      url: ApiEndpoints.TENANT_LIST,
      dropdown: Dropdowns.TENANTS,
      isValueNumber: true,
    });
  },

  render(): VNode {
    return (
      <div>
        <div class="mb-5 d-flex align-center justify-space-between">
          <h2 class="text-h4 font-weight-bold primary--text">
            {`Variables storage - ${this.tenantName}`}
          </h2>
          {hasUserPermissions(Namespaces.TENANT_VALUE, [
            Permissions.VIEW_ALL,
          ]) && (
            <VBtn
              rounded
              color="secondary primary--text"
              onClick={() =>
                toggleDialog(Dialogs.VARIABLE_STORAGE_TENANT_SELECT, {
                  idTenant: this.idTenant,
                })
              }
            >
              <VIcon left>mdi-account-switch-outline</VIcon>
              Change tenant
            </VBtn>
          )}
        </div>
        <VCard>
          <VCardText>
            <SectionToolbar
              title="API key"
              actions={[
                {
                  action: () =>
                    this.apiKeyExists
                      ? toggleDialog(Dialogs.VARIABLE_STORAGE_API_KEY, {
                          idTenant: this.idTenant,
                        })
                      : this.generateApiKey(),
                  icon: this.apiKeyExists ? "mdi-refresh" : "mdi-plus",
                  disabled: this.IS_ACTION_FETCHING(
                    Actions.VARIABLE_STORAGE_FETCH_KEY
                  ),
                  isAllowed: hasUserPermissions(Namespaces.TENANT_VALUE, [
                    Permissions.GENERATE_KEY,
                  ]),
                },
              ]}
            />
            <div>
              {this.IS_ACTION_FETCHING(Actions.VARIABLE_STORAGE_FETCH_KEY) ? (
                <VSkeletonLoader type="heading" />
              ) : (
                <div>
                  <div class="px-4">
                    {this.apiKeyOutput}
                    {this.apiKeyExists && (
                      <VBtn
                        onClick={this.copyApiKeyToClipboard}
                        text
                        class="tertiary--text ml-3"
                        small
                        fab
                      >
                        <VIcon>mdi-content-copy</VIcon>
                      </VBtn>
                    )}
                  </div>
                  <div>
                    {this.apiKeyExists && (
                      <VExpansionPanels class="mt-5">
                        <VExpansionPanel>
                          <VExpansionPanelHeader class="text-h6 primary--text">
                            Integration code example
                          </VExpansionPanelHeader>
                          <VExpansionPanelContent>
                            <VRow>
                              <VCol md={6}>
                                <VAutocomplete
                                  vModel={this.variableSelectValue}
                                  label="Variable key"
                                  items={this.variableSelectItems}
                                  outlined
                                />
                              </VCol>
                            </VRow>
                            <div>
                              <VBtn
                                onClick={this.copyCodeExampleToClipboard}
                                text
                                style="position: absolute; right: 30px; z-index:10"
                                small
                                fab
                                class="white--text"
                              >
                                <VIcon>mdi-content-copy</VIcon>
                              </VBtn>
                            </div>
                            <MonacoEditor
                              key={this.variableSelectValue}
                              language="javascript"
                              value={this.codeExample}
                              height={135}
                              readOnly
                            />
                          </VExpansionPanelContent>
                        </VExpansionPanel>
                      </VExpansionPanels>
                    )}
                  </div>
                </div>
              )}
            </div>
            <VDivider class="my-5" />
            <SectionToolbar
              title="Variables"
              actions={[
                {
                  icon: "mdi-plus",
                  menu: [
                    {
                      action: () =>
                        toggleDialog(Dialogs.VARIABLE_STORAGE_CREATE, {
                          type: 0,
                          idTenant: this.idTenant,
                        }),
                      label: "Custom variable",
                    },
                    {
                      action: () =>
                        toggleDialog(Dialogs.VARIABLE_STORAGE_CREATE, {
                          type: 1,
                          idTenant: this.idTenant,
                        }),
                      label: "Opening time",
                    },
                  ],
                  isAllowed: hasUserPermissions(Namespaces.TENANT_VALUE, [
                    Permissions.EDIT,
                  ]),
                },
              ]}
            />
            {this.IS_ACTION_FETCHING(Actions.VARIABLE_STORAGE_FETCH_ALL) ? (
              <div>
                {this.variableGroups.map(() => (
                  <VSkeletonLoader type="card-heading" />
                ))}
              </div>
            ) : (
              <VExpansionPanels multiple v-model={this.panels}>
                {this.variableGroups.map(({ id, title }) => (
                  <VExpansionPanel>
                    <VExpansionPanelHeader class="text-h6 primary--text">
                      <div class="d-flex align-center justify-space-between">
                        {title}
                        <VChip small class="mr-2">
                          {this.INSPECTED_VARIABLES_STORAGE?.variables?.[id]
                            ?.length || 0}
                        </VChip>
                      </div>
                    </VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      {this.INSPECTED_VARIABLES_STORAGE?.variables?.[id] ? (
                        <VSimpleTable>
                          <thead>
                            <tr>
                              <th>Key</th>
                              {id === 1 && <th>Timezone</th>}
                              {id === 1 && <th>Dates count</th>}
                              <th>{id === 1 ? "Times" : "Value"}</th>
                              {hasUserPermissions(Namespaces.TENANT_VALUE, [
                                Permissions.EDIT,
                              ]) && <th></th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.INSPECTED_VARIABLES_STORAGE?.variables?.[
                              id
                            ].map(({ key, value }: any) => {
                              const valueMeta = this.getTimeTableMeta(value);

                              return (
                                <tr>
                                  <td>{key}</td>
                                  {id === 1 && <td>{valueMeta.timezone}</td>}
                                  {id === 1 && <td>{valueMeta.datesCount}</td>}
                                  <td>
                                    {id === 1 ? (
                                      <table>
                                        {this.formatTimetableValue(value)}
                                      </table>
                                    ) : (
                                      value
                                    )}
                                  </td>
                                  {hasUserPermissions(Namespaces.TENANT_VALUE, [
                                    Permissions.EDIT,
                                  ]) && (
                                    <td width="110" class="text-right">
                                      <VBtn
                                        onClick={() =>
                                          id === 1
                                            ? toggleDialog(
                                                Dialogs.VARIABLE_STORAGE_CREATE,
                                                {
                                                  type: 1,
                                                  idTenant: this.idTenant,
                                                  initData: { value, key },
                                                }
                                              )
                                            : toggleDialog(
                                                Dialogs.VARIABLE_STORAGE_EDIT,
                                                {
                                                  idTenant: this.idTenant,
                                                  key,
                                                  type: id,
                                                  value,
                                                }
                                              )
                                        }
                                        fab
                                        xSmall
                                        color="tertiary white--text mr-2"
                                      >
                                        <VIcon small>mdi-pencil</VIcon>
                                      </VBtn>
                                      <VBtn
                                        onClick={() =>
                                          toggleDialog(
                                            Dialogs.VARIABLE_STORAGE_REMOVE,
                                            { idTenant: this.idTenant, key }
                                          )
                                        }
                                        fab
                                        xSmall
                                        color="error white--text"
                                      >
                                        <VIcon small>mdi-delete</VIcon>
                                      </VBtn>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </VSimpleTable>
                      ) : (
                        "No variables found for this category."
                      )}
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                ))}
              </VExpansionPanels>
            )}
          </VCardText>
        </VCard>
        <VariablesStorageCreateDialog />
        <VariableStorageRemoveDialog />
        <VariableStorageEditDialog />
        <VariableStorageGenerateKeyDialog />
        <VariableStorageTenantSelectDialog
          onTenantChanged={(idTenant: string | number) =>
            this.switchTenant(idTenant)
          }
        />
      </div>
    );
  },
});

export default VariablesStorage;
