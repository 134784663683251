import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import { VIcon } from "vuetify/lib";
import DataGrid from "../DataGrid";
import { Getters, Actions, Modules } from "@/models/store";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import DataGrids from "@/constants/dataGrids";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import FlowVersionDiffDialog from "../FlowVersionDiffDialog";
import FlowSwitchVersionDialog from "../FlowSwitchVersionDialog";
import FlowVersionHistoryDuplicateDialog from "../FlowVersionHistoryDuplicateDialog";

const FlowVersionHistoryDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
    idCallScript: 0,
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [
      Actions.FLOW_VERSION_HISTORY_DATAGRID_FETCH,
    ]),
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_VERSION_DIFF]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Version history",
      disableElevation: true,
      rowActions: ({ id, canCompare, changed }) => ({
        primary: {
          icon: "mdi-history",
          title: "Switch to this version",
          action: () =>
            toggleDialog(Dialogs.FLOW_SWITCH_VERSION, {
              idCallScript: this.$route.params.idCallScript,
              idCallScriptHistory: id,
            }),
        },
        secondary: [
          {
            icon: "mdi-vector-difference-ab",
            title: "Show diff",
            disabled: !canCompare,
            action: () =>
              this.FLOW_VERSION_DIFF({
                idCallScript: this.$route.params.idCallScript,
                idCallScriptHistory: id,
              }),
          },
          {
            icon: "mdi-content-copy",
            title: "Duplicate to new flow",
            action: () =>
              toggleDialog(Dialogs.FLOW_VERSION_DUPLICATE, {
                idCallScriptHistory: id,
                changed,
              }),
          },
        ],
      }),
      columnModificators: {
        "item.changed": ({ item }) => formatDate(item.changed),
        "item.isAutosave": ({ item }) =>
          item.isAutosave ? (
            <VIcon color="success">mdi-checkbox-marked-circle</VIcon>
          ) : (
            <VIcon color="error">mdi-close-circle</VIcon>
          ),
      },
      headers: [
        { text: "Changed at", value: "changed" },
        { text: "Author", value: "author" },
        { text: "Autosave", value: "isAutosave" },
      ],
    };

    this.dataGridConfig = dataGridConfig;
    this.idCallScript = Number(this.$route.params.idCallScript);
  },

  render(): VNode {
    const { idCallScript } = this;

    return (
      <div>
        <DataGrid
          fetchData={this.FLOW_VERSION_HISTORY_DATAGRID_FETCH}
          fetchProps={{ ...(idCallScript && { idCallScript }) }}
          data={this.GET_DATAGRID_DATA(DataGrids.FLOW_VERSION_HISTORY)}
          configuration={this.dataGridConfig}
          isFetching={
            this.IS_ACTION_FETCHING(
              Actions.FLOW_VERSION_HISTORY_DATAGRID_FETCH
            ) || this.IS_ACTION_FETCHING(Actions.FLOW_VERSION_DIFF)
          }
        />

        <FlowVersionDiffDialog />
        <FlowSwitchVersionDialog />
        <FlowVersionHistoryDuplicateDialog />
      </div>
    );
  },
});

export default FlowVersionHistoryDataGrid;
