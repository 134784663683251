import { ActionTree } from "vuex";
import { Actions, Getters, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import toggleDialog from "@/helpers/toggleDialog";
import generateErrorMsg from "@/helpers/generateErrorMsg";

const userActions: ActionTree<InstancesState, []> = {
  [Actions.USER_OVERVIEW_FETCH]: ({ state, commit, dispatch }, { idUser }) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.TENANT_USER_BASIC,
      action: Actions.USER_OVERVIEW_FETCH,
      params: { idUser },
      withTitleChange: true,
      titleProp: "username",
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_USER, {
          ...state.inspectedUser,
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_USER, {}),
    });
  },

  [Actions.USER_ROLES_FETCH]: ({ state, commit, dispatch }, { idUser }) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.TENANT_USER_ROLES,
      action: Actions.USER_ROLES_FETCH,
      params: { idUser },
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_USER, {
          ...state.inspectedUser,
          roles: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_USER, {}),
    });
  },

  [Actions.USER_REMOVE]: ({ dispatch }, { idUser, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idUser },
      isDetailActive,
      endpoint: ApiEndpoints.TENANT_USER,
      action: Actions.USER_REMOVE,
      redirectRoute: Routes.USERS_OVERVIEW,
      dataGrid: DataGrids.USERS,
      dataGridFetch: Actions.USERS_DATAGRID_FETCH,
      dialog: Dialogs.USER_REMOVE,
      prefix: "User",
    }),

  [Actions.USER_API_SECRET_FETCH]: async ({ commit, state }, { idUser }) => {
    const apiSecretReq = await createRequest({
      action: Actions.USER_API_SECRET_FETCH,
      method: "GET",
      url: ApiEndpoints.TENANT_USER_API_SECRET,
      params: { idUser },
    });

    const { data, errors } = parseResponse(apiSecretReq);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_USER, {
        ...state.inspectedUser,
        apiSecret: data.content.secret,
      });
    }
  },

  [Actions.USER_API_SECRET_GENERATE]: async ({ commit, state }, { idUser }) => {
    const apiSecretReq = await createRequest({
      action: Actions.USER_API_SECRET_GENERATE,
      method: "POST",
      url: ApiEndpoints.TENANT_USER_API_SECRET,
      params: { idUser },
    });

    const { data, errors } = parseResponse(apiSecretReq);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_USER, {
        ...state.inspectedUser,
        apiSecret: data.content.secret,
      });

      pushNotify(Notify.SUCCESS, "Api secret generated successfully");
    } else {
      pushNotify(
        Notify.ERROR,
        generateErrorMsg(Actions.USER_API_SECRET_GENERATE)
      );
    }
  },

  [Actions.USER_API_SECRET_REMOVE]: async ({ commit, state }, { idUser }) => {
    const apiSecretReq = await createRequest({
      action: Actions.USER_API_SECRET_REMOVE,
      method: "DELETE",
      url: ApiEndpoints.TENANT_USER_API_SECRET,
      params: { idUser },
    });

    const { errors } = parseResponse(apiSecretReq);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_USER, {
        ...state.inspectedUser,
        apiSecret: null,
      });

      toggleDialog(Dialogs.USER_API_SECRET_REMOVE, {});
      pushNotify(Notify.SUCCESS, "Api secret removed successfully");
    }
  },

  [Actions.USER_TWO_FACTOR_FETCH]: async ({ state, commit }, { idUser }) => {
    const twoFactorReq = await createRequest({
      action: Actions.USER_TWO_FACTOR_FETCH,
      method: "GET",
      url: ApiEndpoints.USER_TWO_FACTOR_STATE,
      params: { idUser },
    });

    const { data, errors } = parseResponse(twoFactorReq);

    if (errors.length !== 0) {
      pushNotify(
        Notify.ERROR,
        "Fetching two factor authentication data failed."
      );
      return null;
    }

    commit(Mutations.SET_INSPECTED_USER, {
      ...state.inspectedUser,
      twoFactor: data,
    });
  },

  [Actions.USER_TWO_FACTOR_CHANGE]: async (
    { dispatch, getters },
    { idUser, enable, isLoggedUser }
  ) => {
    const getFetchParams = (): any => {
      if (
        isLoggedUser ||
        getters[Getters.INSPECTED_USER]?.twoFactor?.enable ||
        getters[Getters.INSPECTED_USER]?.twoFactor?.requested
      ) {
        return {
          url: ApiEndpoints.USER_TWO_FACTOR_CHANGE,
          method: "POST",
          data: { enable },
        };
      }

      return { url: ApiEndpoints.TENANT_USER_TWO_FACTOR_ENABLE, method: "GET" };
    };

    const twoFactorReq = await createRequest({
      action: Actions.USER_TWO_FACTOR_CHANGE,
      params: { idUser },
      ...getFetchParams(),
    });

    const { errors } = parseResponse(twoFactorReq);

    if (errors.length !== 0) {
      pushNotify(
        Notify.ERROR,
        "Changing two factor authentication state failed"
      );
      return null;
    }

    dispatch(Actions.USER_TWO_FACTOR_FETCH, { idUser });
  },

  [Actions.USER_TWO_FACTOR_RESET]: async ({ dispatch }, { idUser }) => {
    const twoFactorResetRes = await createRequest({
      method: "DELETE",
      url: ApiEndpoints.TENANT_USER_TWO_FACTOR_REMOVE,
      action: Actions.USER_TWO_FACTOR_RESET,
      params: { idUser },
    });

    const { errors } = parseResponse(twoFactorResetRes);

    if (errors.length !== 0) {
      pushNotify(
        Notify.ERROR,
        "Changing two factor authentication state failed"
      );
      return null;
    }

    pushNotify(
      Notify.SUCCESS,
      "User two factor authentication reseted successfully"
    );

    dispatch(Actions.USER_TWO_FACTOR_FETCH, { idUser });
  },

  [Actions.USER_PREFERENCES_FETCH]: async ({ state, commit }, { idUser }) => {
    const preferencesRes = await createRequest({
      method: "GET",
      url: ApiEndpoints.TENANT_USER_PREFERENCE_CONSOLE,
      action: Actions.USER_PREFERENCES_FETCH,
      params: { idUser },
    });

    const { data, errors } = parseResponse(preferencesRes);

    if (errors.length !== 0) {
      return;
    }

    commit(Mutations.SET_INSPECTED_USER, {
      ...state.inspectedUser,
      preferences: data,
    });
  },
};

export default userActions;
