import Vue, { VNode } from "vue";
import { isRequired, isTime, minValue } from "@/helpers/validations";
import {
  VAlert,
  VBtn,
  VCol,
  VDivider,
  VForm,
  VList,
  VListItem,
  VMenu,
  VRow,
  VSelect,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSwitch,
  VTextField,
  VTimePicker,
} from "vuetify/lib";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import NotificationGroupsSelect from "../NotificationGroupsSelect";
import { mapActions, mapGetters } from "vuex";
import DAYS_IN_WEEK from "@/constants/daysInWeek";
import getDaysOfWeekFromValues from "@/helpers/getDaysOfWeekFromValues";

const LineMonitoringForm = Vue.extend({
  data: () => ({
    fields: {
      title: "",
      dow: [1, 2, 3, 4, 5],
      timeStart: "",
      timeEnd: "",
      interval: "",
      notifications: [],
      enable: false,
      configuration: {
        minConversationCount: "",
        maxConversationCount: "",
        minAht: "",
        maxAht: "",
        allowedError: "",
      },
      reportingMode: false,
    },
    options: {
      monitoringIndex: null,
      onceInDay: false,
    },
    menus: {
      timeFrom: false,
      timeTo: false,
    },
    steps: {
      isStep1Valid: true,
      isStep2Valid: true,
      isStep3Valid: true,
      activeStep: 1,
      finalStep: 4,
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    configurationFields(): any {
      const filledConfiguration = {};

      Object.keys(this.fields.configuration).map((key) => {
        this.fields.configuration[key] === ""
          ? null
          : (filledConfiguration[key] = parseInt(
              this.fields.configuration[key]
            ));
      });

      return filledConfiguration;
    },

    getFirstAndLastMonitoringActionTime(): string[] {
      const { timeStart, timeEnd, interval } = this.fields;
      const timeStartInMinutes = this.parseTimeToMinutes(timeStart);
      const timeEndInMinutes = this.parseTimeToMinutes(timeEnd);

      const calculatedTimeStart =
        timeStartInMinutes + parseInt(interval) > timeEndInMinutes
          ? timeStartInMinutes
          : timeStartInMinutes + parseInt(interval);

      let calculatedTimeEnd = timeStartInMinutes;

      while (calculatedTimeEnd + parseInt(interval) <= timeEndInMinutes) {
        calculatedTimeEnd = calculatedTimeEnd + parseInt(interval);
      }

      return [
        this.parseMinutesToTimeString(calculatedTimeStart),
        this.parseMinutesToTimeString(calculatedTimeEnd),
      ];
    },

    inspectedLineTenantId(): number | null {
      return (
        this.INSPECTED_LINE.basic?.content?.idTenant ||
        this.INSPECTED_LINE.basic?.content.dialModuleIdTenant
      );
    },

    monitoringIndex(): number | undefined {
      return this.GET_DIALOG_DATA(Dialogs.LINE_MONITORING)?.index;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.LINE_MONITORING_FETCH,
      Actions.LINE_RECOMMENDED_MONITORING_FETCH,
    ]),

    async handleSubmit() {
      if (this.steps.activeStep !== this.steps.finalStep) {
        const refs: any = this.$refs;
        const activeStepRef = `step${this.steps.activeStep}`;
        const isStepValid = refs[activeStepRef]?.validate();

        if (isStepValid) {
          this.steps.activeStep = this.steps.activeStep + 1;
        }

        return;
      }

      const submitData = this.getSubmitData();

      const { isFormSubmited } = await submitForm({
        url: ApiEndpoints.LINE_MONITORING,
        params: { idDialModuleLine: this.$route.params.idDialModuleLine },
        values: submitData,
        actionId: Actions.LINE_MONITORING_EDIT,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LINE_MONITORING, {});
        pushNotify(Notify.SUCCESS, "Monitoring settings saved successfully");

        this.LINE_MONITORING_FETCH({
          idDialModuleLine: this.$route.params.idDialModuleLine,
        });
      }
    },

    getSubmitData(): any[] {
      const { timeStart, timeEnd, interval, ...fields } = this.fields;
      const timeSettings = this.options.onceInDay
        ? {
            timeStart,
            timeEnd: timeStart,
            interval: 1440,
          }
        : { timeStart, timeEnd, interval };

      const editedMonitoringItem = {
        ...fields,
        ...timeSettings,
        ...this.configurationFields,
      };

      if (this.options.monitoringIndex === null) {
        return [
          ...this.INSPECTED_LINE.monitoring.content,
          editedMonitoringItem,
        ];
      }

      const modifiedData = [...this.INSPECTED_LINE.monitoring.content];
      modifiedData[this.options.monitoringIndex || 0] = editedMonitoringItem;

      return modifiedData;
    },

    getInitData() {
      const monitoringIndex = this.GET_DIALOG_DATA(
        Dialogs.LINE_MONITORING
      )?.index;

      if (monitoringIndex === undefined) {
        return;
      }

      const {
        allowedError,
        minAht,
        maxAht,
        minConversationCount,
        maxConversationCount,
        ...content
      } = this.INSPECTED_LINE?.monitoring?.content?.[monitoringIndex];

      this.options.monitoringIndex = monitoringIndex;
      this.fields = {
        ...this.fields,
        ...content,
        configuration: {
          allowedError: isNaN(allowedError) ? "" : allowedError,
          minAht: isNaN(minAht) ? "" : minAht,
          maxAht: isNaN(maxAht) ? "" : maxAht,
          minConversationCount: isNaN(minConversationCount)
            ? ""
            : minConversationCount,
          maxConversationCount: isNaN(maxConversationCount)
            ? ""
            : maxConversationCount,
        },
      };
    },

    parseTimeToMinutes(time: string): number {
      if (!time) {
        return 0;
      }

      const [hour, min] = time.split(":");
      return parseInt(hour) * 60 + parseInt(min);
    },

    parseMinutesToTimeString(timeInMinutes: number): string {
      const minutes = timeInMinutes % 60;
      const hours = Math.trunc(timeInMinutes / 60);

      return `${hours > 9 ? hours : `0${hours}`}:${
        minutes > 9 ? minutes : `0${minutes}`
      }`;
    },

    async loadRecommendedValues(): Promise<void> {
      const data = await this.LINE_RECOMMENDED_MONITORING_FETCH({
        idDialModuleLine: this.$route.params.idDialModuleLine,
        timeStart: this.fields.timeStart,
        timeEnd: this.fields.timeEnd,
        dow: this.fields.dow,
        interval: this.fields.interval,
      });
      this.fields.configuration.minAht = data.minAht;
      this.fields.configuration.maxAht = data.maxAht;
      this.fields.configuration.minConversationCount =
        data.minConversationCount;
      this.fields.configuration.maxConversationCount =
        data.maxConversationCount;
      this.fields.configuration.allowedError = data.allowedError;
    },

    isFinalStep() {
      return this.steps.activeStep === this.steps.finalStep;
    },
  },

  created() {
    this.getInitData();
  },

  render(): VNode {
    return (
      <VStepper
        vModel={this.steps.activeStep}
        flat
        onChange={this.$emit("stepChange")}
      >
        <VStepperHeader class="mb-2">
          <VStepperStep step={1} editable>
            General
          </VStepperStep>
          <VDivider />
          <VStepperStep step={2} editable={this.steps.activeStep > 1}>
            Timing
          </VStepperStep>
          <VDivider />
          <VStepperStep step={3} editable={this.steps.activeStep > 2}>
            Criteria
          </VStepperStep>
          <VDivider />
          <VStepperStep step={4}>Summary</VStepperStep>
        </VStepperHeader>
        <VStepperItems>
          <VStepperContent step={1}>
            <VForm ref="step1" vModel={this.steps.isStep1Valid}>
              <VTextField
                vModel={this.fields.title}
                label="Name"
                rules={[isRequired]}
              />
              <NotificationGroupsSelect
                vModel={this.fields.notifications}
                idTenant={this.inspectedLineTenantId}
                options={{ multiple: true, rules: [isRequired] }}
              />
              {this.options.monitoringIndex === null && (
                <VSwitch
                  vModel={this.fields.enable}
                  label="Enabled"
                  hide-details
                />
              )}
              <VSwitch
                vModel={this.fields.reportingMode}
                label="Reporting mode"
              />
            </VForm>
          </VStepperContent>
          <VStepperContent step={2}>
            <VForm ref="step2" vModel={this.steps.isStep2Valid}>
              <VSelect
                label="Days to monitor"
                vModel={this.fields.dow}
                multiple
                smallChips
                deletableChips
                items={DAYS_IN_WEEK}
              />
              <VSwitch
                class="d-inline-block"
                vModel={this.options.onceInDay}
                label="Run once in day"
              />
              <VRow>
                <VCol md={6} class={this.options.onceInDay ? "" : "pb-0"}>
                  <VMenu
                    vModel={this.menus.timeFrom}
                    closeOnContentClick={false}
                    scopedSlots={{
                      activator: ({ on, attrs }: any) => (
                        <VTextField
                          label={this.options.onceInDay ? "Time" : "Time from"}
                          vModel={this.fields.timeStart}
                          rules={[isRequired, isTime]}
                          {...{ on, attrs }}
                        />
                      ),
                    }}
                  >
                    <VTimePicker
                      format="24hr"
                      vModel={this.fields.timeStart}
                      {...{
                        on: {
                          "click:minute": () => (this.menus.timeFrom = false),
                        },
                      }}
                    />
                  </VMenu>
                </VCol>
                {!this.options.onceInDay && (
                  <VCol md={6} class="pb-0">
                    <VMenu
                      vModel={this.menus.timeTo}
                      closeOnContentClick={false}
                      scopedSlots={{
                        activator: ({ on, attrs }: any) => (
                          <VTextField
                            label="Time to"
                            vModel={this.fields.timeEnd}
                            rules={[isRequired, isTime]}
                            {...{ on, attrs }}
                          />
                        ),
                      }}
                    >
                      <VTimePicker
                        format="24hr"
                        vModel={this.fields.timeEnd}
                        {...{
                          on: {
                            "click:minute": () => (this.menus.timeTo = false),
                          },
                        }}
                      />
                    </VMenu>
                  </VCol>
                )}
              </VRow>
              {!this.options.onceInDay && (
                <VRow>
                  <VCol md={6} class="pt-0">
                    <VTextField
                      vModel={this.fields.interval}
                      label="Interval (min)"
                      rules={[isRequired, (val: string) => minValue(val, 10)]}
                    />
                  </VCol>
                </VRow>
              )}
            </VForm>
          </VStepperContent>
          <VStepperContent step={3}>
            <VForm ref="step3" vModel={this.steps.isStep3Valid}>
              <VRow>
                <VCol md={6} class="pb-0">
                  <VTextField
                    vModel={this.fields.configuration.minConversationCount}
                    label="Min conversation count"
                  />
                </VCol>
                <VCol md={6} class="pb-0">
                  <VTextField
                    vModel={this.fields.configuration.maxConversationCount}
                    label="Max conversation count"
                  />
                </VCol>
                <VCol md={6} class="py-0">
                  <VTextField
                    vModel={this.fields.configuration.minAht}
                    label="Min AHT"
                  />
                </VCol>
                <VCol md={6} class="py-0">
                  <VTextField
                    vModel={this.fields.configuration.maxAht}
                    label="Max AHT"
                  />
                </VCol>
                <VCol md={6} class="py-0">
                  <VTextField
                    vModel={this.fields.configuration.allowedError}
                    label="Allowed error (%)"
                  />
                </VCol>
              </VRow>
            </VForm>
            <VRow justify="center">
              <VBtn
                color="primary"
                rounded
                class="mb-3 mt-1"
                onClick={() => this.loadRecommendedValues()}
              >
                Recommended Values
              </VBtn>
            </VRow>
          </VStepperContent>
          <VStepperContent step={4}>
            {!this.options.onceInDay && (
              <VAlert
                border="left"
                coloredBorder
                type="info"
                elevation="2"
                class="mx-2 mt-1 text-body-2"
              >
                The first monitoring action of the day will be triggered at{" "}
                <strong class="info--text">
                  {this.getFirstAndLastMonitoringActionTime[0]}
                </strong>{" "}
                and the last will be triggered at{" "}
                <strong class="info--text">
                  {this.getFirstAndLastMonitoringActionTime[1]}
                </strong>
                .
              </VAlert>
            )}
            <VList>
              <VListItem>
                <span>
                  Contact groups:{" "}
                  <strong class="primary--text">
                    {this.fields.notifications.join(", ")}
                  </strong>
                </span>
              </VListItem>
              <VListItem>
                <span>
                  Active days:{" "}
                  <strong class="primary--text">
                    {getDaysOfWeekFromValues(this.fields.dow, true).join(", ")}
                  </strong>
                </span>
              </VListItem>
              <VListItem>
                {this.options.onceInDay ? (
                  <span>
                    Time:{" "}
                    <strong class="primary--text">
                      {this.fields.timeStart}
                    </strong>
                  </span>
                ) : (
                  <span>
                    Time:{" "}
                    <strong class="primary--text">
                      {this.fields.timeStart} - {this.fields.timeEnd}
                    </strong>{" "}
                    every{" "}
                    <strong class="primary--text">
                      {this.fields.interval} minutes
                    </strong>
                  </span>
                )}
              </VListItem>
              <VListItem class="d-block mt-2">
                <div>Parameters:</div>
                <ul>
                  {Object.entries(this.configurationFields).map(
                    ([key, val]) => (
                      <li class="primary--text font-weight-bold">
                        {key}: {val}
                      </li>
                    )
                  )}
                </ul>
              </VListItem>
            </VList>
          </VStepperContent>
        </VStepperItems>
      </VStepper>
    );
  },
});

export default LineMonitoringForm;
