import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VForm, VSelect, VSwitch, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import {
  TARIFF_TYPES,
  TARIFF_DIVIDER_TYPES,
  TARIFF_CURRENCIES,
} from "@/constants/tariffs";
import BillingGroupSelect from "../BillingGroupSelect";
import BillingTariffsSelect from "../BillingTariffsSelect";
import DataGrids from "@/constants/dataGrids";

const BillingTariffEditForm = Vue.extend({
  data: () => ({
    fields: {
      type: 0,
      description: "",
      currency: "CZK",
      price: "",
      smsPrice: 0,
      divider: "1",
      roundInterval: "60",
      minDuration: 0,
      group: 1,
      guid: "",
    },
    isCustomSettings: false,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.BILLING_TARIFF_EDIT);
    },
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [
      Actions.TENANT_TARRIFS_DATAGRID_FETCH,
      Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS,
    ]),

    async submitLineTariff(): Promise<void> {
      const { type, description, guid, ...content } = this.fields;
      const tariffData = { guid, customContent: false };
      const customData = {
        type,
        description,
        customContent: true,
        content: JSON.stringify(content),
      };

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.BILLING_LINE_TARIFF,
        values: this.isCustomSettings ? customData : tariffData,
        params: {
          idDialModuleLine: this.instanceData.idLine,
        },
        actionId: Actions.LINE_TARIFF_EDIT,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.BILLING_TARIFF_EDIT, {});
        pushNotify(Notify.SUCCESS, "Line tariff edited successfully");

        this.FETCH_DATAGRID_WITH_CURRENT_PARAMS({
          dataGrid: DataGrids.LINE_TARIFFS,
          fetchActionId: Actions.LINE_TARRIFS_DATAGRID_FETCH,
        });
      }
    },

    async submitTenantTariff(): Promise<void> {
      const { type, description, ...content } = this.fields;
      const guid = this.instanceData?.tariff?.guid;

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.BILLING_TARIFF_CONFIGURE,
        values: {
          type,
          description,
          ...(guid && { guid }),
          content: JSON.stringify(content),
        },
        params: {
          idTenant: this.$route.params.idTenant,
        },
        actionId: Actions.LINE_TARIFF_EDIT,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.BILLING_TARIFF_EDIT, {});
        pushNotify(
          Notify.SUCCESS,
          `Tariff ${guid ? "edited" : "created"} successfully`
        );
        this.TENANT_TARRIFS_DATAGRID_FETCH({
          params: {
            idTenant: this.$route.params.idTenant,
          },
        });
      }
    },

    handleSubmit() {
      const withTariffReference = this.instanceData?.withTariffReference;
      withTariffReference ? this.submitLineTariff() : this.submitTenantTariff();
    },
  },

  created() {
    this.fields = {
      type: this.instanceData?.tariff?.type || 0,
      description: this.instanceData?.tariff?.description || "",
      guid: this.instanceData?.tariff?.guid || "",
      ...JSON.parse(this.instanceData?.tariff?.content ?? { group: 1 }),
    };

    if (!this.fields.guid && this.fields.price) {
      this.isCustomSettings = true;
    }
  },

  render(): VNode {
    return (
      <VForm
        ref="form"
        onSubmit={(e: Event) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        {this.instanceData?.withTariffReference && (
          <div>
            <BillingTariffsSelect
              vModel={this.fields.guid}
              idTenant={this.$route.params.idTenant}
              options={{ disabled: this.isCustomSettings }}
            />
            <VSwitch vModel={this.isCustomSettings} label="Custom settings" />
          </div>
        )}
        {(this.isCustomSettings || !this.instanceData?.withTariffReference) && (
          <div>
            <VTextField
              vModel={this.fields.description}
              rules={[isRequired]}
              label="Name"
            />
            <BillingGroupSelect vModel={this.fields.group} />
            <VSelect
              vModel={this.fields.currency}
              items={TARIFF_CURRENCIES}
              label="Currency"
            />
            <VTextField
              rules={[isRequired]}
              type="number"
              vModel={this.fields.price}
              label="Call Price"
            />
            <VSelect
              vModel={this.fields.type}
              items={TARIFF_TYPES}
              label="Type"
            />
            {this.fields.type == 1 && (
              <VSelect
                vModel={this.fields.divider}
                items={TARIFF_DIVIDER_TYPES}
                label="Divider"
              />
            )}
            {(this.fields.type == 2 && (
              <VTextField
                vModel={this.fields.minDuration}
                type="number"
                label="Minimal Duration"
              />
            )) || (
              <VTextField
                vModel={this.fields.roundInterval}
                type="number"
                label="Round Interval"
              />
            )}
            <VTextField
              rules={[isRequired]}
              vModel={this.fields.smsPrice}
              type="number"
              label="SMS Price"
            />
          </div>
        )}
      </VForm>
    );
  },
});

export default BillingTariffEditForm;
