import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import TenantActionsParams from "@/models/actions/TenantActionsParams";

const useTenantActions = ({
  id,
  invoicingName,
}: TenantActionsParams): ActionHandler[] => [
  {
    icon: "mdi-currency-usd",
    title: "Billing",
    route: {
      name: Routes.BILLING_OVERVIEW,
      params: { idTenant: id },
    },
    isAllowed: hasUserPermissions(Namespaces.STATISTICS, [
      Permissions.BILLING_VIEW,
    ]),
  },
  {
    icon: "mdi-application-variable-outline",
    title: "Variables storage",
    route: {
      name: Routes.VARIABLES_STORAGE,
      query: { idTenant: id },
    },
  },
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () => toggleDialog(Dialogs.TENANT_REMOVE, { id, invoicingName }),
    isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
      Permissions.DELETE_ACCOUNT_ALL,
    ]),
  },
];

export default useTenantActions;
