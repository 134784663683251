import { TARIFF_GROUPS } from "@/constants/tariffs";
import Vue, { VNode } from "vue";
import { VAutocomplete } from "vuetify/lib";

const BillingGroupSelect = Vue.extend({
  props: {
    value: Number,
  },

  render(): VNode {
    return (
      <VAutocomplete
        label="Billing group"
        items={TARIFF_GROUPS.map(({ text, value }) => ({ text, value }))}
        onInput={(val: string | number) => this.$emit("input", val)}
        value={this.value}
      />
    );
  },
});

export default BillingGroupSelect;
