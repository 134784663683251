import UserState from "@/models/store/UserState";

const state: UserState = {
  connectedAcounts: [],
  isUserSignedIn: false,
  token: "",
  appConfig: {
    conversations: false,
    fulltext: false,
  },
  properties: {
    email: "",
    firstname: "",
    lastname: "",
    username: "",
    roles: [],
    permissions: [],
    consolePreference: {},
    id: "",
    tenant: "",
  },
};

export default state;
