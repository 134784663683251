import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules, Mutations } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import { VForm, VSelect } from "vuetify/lib";
import TimeZoneSelect from "../TimeZoneSelect";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ApiEndpoints from "@/constants/apiEndpoints";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { VCheckbox } from "vuetify/lib/components";

const UserPreferencesEditDialog = Vue.extend({
  data: () => ({
    fields: {} as any,
  }),

  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_PREFERENCES_FETCH]),
    ...mapMutations(Modules.USER, [Mutations.SET_USER_INFO]),

    initFormData() {
      this.fields = { ...this.INSPECTED_USER?.preferences };
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const idUser = this.$route.params.idUser ?? this.USER_INFO?.id;

      const { isFormSubmited } = await submitForm({
        actionId: Actions.USER_PREFERENCES_EDIT,
        ref: refs.form,
        values: { content: this.fields },
        url: ApiEndpoints.TENANT_USER_PREFERENCE_CONSOLE,
        params: { idUser },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.USER_PREFERENCES, {});
        pushNotify(Notify.SUCCESS, "User preferences edited successfully");

        this.USER_PREFERENCES_FETCH({ idUser });
      }

      if (isFormSubmited && !this.$route.params.idUser) {
        this.SET_USER_INFO({
          ...this.USER_INFO,
          consolePreference: { ...this.fields },
        });
      }
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.USER_PREFERENCES}
        title="Configuration"
        closeOnEsc
        toggleHandler={this.initFormData}
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            action: () => this.handleSubmit(),
            id: Actions.USER_PREFERENCES_EDIT,
          },
        ]}
      >
        <VForm ref="form">
          <VSelect
            vModel={this.fields.datagridItemsPerPage}
            label="Datagrid items per page"
            items={[5, 10, 20, 50]}
          />
          <TimeZoneSelect vModel={this.fields.timezone} withBrowserOption />
          <VCheckbox
            vModel={this.fields.preferConversations}
            label="Show conversations by default"
          />
        </VForm>
      </Dialog>
    );
  },
});

export default UserPreferencesEditDialog;
