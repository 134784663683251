import Dialogs from "@/constants/dialogs";
import DialogsState from "@/models/store/DialogsState";

const initDialogState = (): any => ({
  isActive: false,
  data: {},
});

const state: DialogsState = {
  [Dialogs.MODULE_EDIT_BASIC]: initDialogState(),
  [Dialogs.MODULE_CALLBOT_CONFIGURATION]: initDialogState(),
  [Dialogs.MODULE_CSV_EXPORT]: initDialogState(),
  [Dialogs.MODULE_REMOVE]: initDialogState(),
  [Dialogs.MODULE_SET_DEFAULT_LINE]: initDialogState(),
  [Dialogs.LINE_CSV_EXPORT]: initDialogState(),
  [Dialogs.LINE_CHANGE_MODULE]: initDialogState(),
  [Dialogs.LINE_CREATE]: initDialogState(),
  [Dialogs.LINE_EDIT_BASIC]: initDialogState(),
  [Dialogs.LINE_ENABLE]: initDialogState(),
  [Dialogs.LINE_REMOVE]: initDialogState(),
  [Dialogs.LINE_DELETE_CALL]: initDialogState(),
  [Dialogs.LINE_MONITORING]: initDialogState(),
  [Dialogs.LINE_EDIT_CALLBOT_CONFIG]: initDialogState(),
  [Dialogs.BILLING_TARIFF_EDIT]: initDialogState(),
  [Dialogs.LINE_CLEANING]: initDialogState(),
  [Dialogs.FLOW_CSV_EXPORT]: initDialogState(),
  [Dialogs.FLOW_SWITCH_VERSION]: initDialogState(),
  [Dialogs.FLOW_CREATE]: initDialogState(),
  [Dialogs.FLOW_VERSION_DIFF]: initDialogState(),
  [Dialogs.FLOW_TEST_CALL]: initDialogState(),
  [Dialogs.FLOW_TEST_CALL_PROGRESS]: initDialogState(),
  [Dialogs.FLOW_DUPLICATE]: initDialogState(),
  [Dialogs.FLOW_REMOVE]: initDialogState(),
  [Dialogs.FLOW_EDIT_BASIC]: initDialogState(),
  [Dialogs.DIAL_LOG_DETAIL]: initDialogState(),
  [Dialogs.DIAL_LOG_AUDIO]: initDialogState(),
  [Dialogs.GRAMMAR_CREATE]: initDialogState(),
  [Dialogs.GRAMMAR_DUPLICATE]: initDialogState(),
  [Dialogs.GRAMMAR_REMOVE]: initDialogState(),
  [Dialogs.GRAMMAR_EDIT_CONTENT]: initDialogState(),
  [Dialogs.GRAMMAR_EDIT_BASIC]: initDialogState(),
  [Dialogs.PACKAGE_INSTALL]: initDialogState(),
  [Dialogs.TENANT_CREATE]: initDialogState(),
  [Dialogs.TENANT_NOTIFICATION_GROUP]: initDialogState(),
  [Dialogs.TENANT_NOTIFICATION_GROUP_REMOVE]: initDialogState(),
  [Dialogs.TENANT_REMOVE]: initDialogState(),
  [Dialogs.TENANT_EDIT_BASIC]: initDialogState(),
  [Dialogs.TENANT_EDIT_PARAMS]: initDialogState(),
  [Dialogs.USER_CREATE]: initDialogState(),
  [Dialogs.USER_ROLES]: initDialogState(),
  [Dialogs.USER_EDIT_BASIC]: initDialogState(),
  [Dialogs.USER_REMOVE]: initDialogState(),
  [Dialogs.USER_CONNECTED_ACCOUNTS]: initDialogState(),
  [Dialogs.CHANGE_PASSWORD]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_CREATE]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_REMOVE]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_EDIT_BASIC]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_PERMISSIONS_EDIT]: initDialogState(),
  [Dialogs.USER_API_SECRET_REMOVE]: initDialogState(),
  [Dialogs.CAMPAIGN_CREATE]: initDialogState(),
  [Dialogs.CAMPAIGN_EDIT]: initDialogState(),
  [Dialogs.CAMPAIGN_REMOVE]: initDialogState(),
  [Dialogs.CAMPAIGN_STATE_CHANGE]: initDialogState(),
  [Dialogs.CAMPAIGN_MANAGE_CONTACTS]: initDialogState(),
  [Dialogs.CAMPAIGN_ERROR_CONTACTS]: initDialogState(),
  [Dialogs.CAMPAIGN_VALIDATION_DIALOG]: initDialogState(),
  [Dialogs.BOTDEPLOY_MODULE_CREATE]: initDialogState(),
  [Dialogs.BOTDEPLOY_MODULE_EDIT_BASIC]: initDialogState(),
  [Dialogs.BOTDEPLOY_MODULE_REMOVE]: initDialogState(),
  [Dialogs.BOTDEPLOY_MODULE_TOGGLE]: initDialogState(),
  [Dialogs.BOTDEPLOY_MODULE_RESTART]: initDialogState(),
  [Dialogs.BOTDEPLOY_SERVER_CREATE]: initDialogState(),
  [Dialogs.BOTDEPLOY_SERVER_EDIT_BASIC]: initDialogState(),
  [Dialogs.BOTDEPLOY_SERVER_REMOVE]: initDialogState(),
  [Dialogs.BOTDEPLOY_CONFIG_CREATE]: initDialogState(),
  [Dialogs.BOTDEPLOY_CONFIG_EDIT_BASIC]: initDialogState(),
  [Dialogs.BOTDEPLOY_CONFIG_REMOVE]: initDialogState(),
  [Dialogs.NLP_TESTER_DATASET_CREATE]: initDialogState(),
  [Dialogs.NLP_CATEGORY_SAMPLES_EDIT]: initDialogState(),
  [Dialogs.NLP_TESTER_CATEGORY_CREATE]: initDialogState(),
  [Dialogs.NLP_TESTER_DATASET_REMOVE]: initDialogState(),
  [Dialogs.NLP_CATEGORY_REMOVE_DIALOG]: initDialogState(),
  [Dialogs.NLP_TESTER_SAMPLES_GENERATE]: initDialogState(),
  [Dialogs.NLP_TESTER_MODEL_CREATE]: initDialogState(),
  [Dialogs.NLP_TESTER_MODEL_TRAIN]: initDialogState(),
  [Dialogs.NLP_TESTER_MODEL_PREDICT]: initDialogState(),
  [Dialogs.NLP_TESTER_MODEL_REMOVE]: initDialogState(),
  [Dialogs.NLP_TESTER_DATASET_DUPLICATE]: initDialogState(),
  [Dialogs.LANGUAGE_CREATE]: initDialogState(),
  [Dialogs.LANGUAGE_EDIT_BASIC]: initDialogState(),
  [Dialogs.LANGUAGE_REMOVE]: initDialogState(),
  [Dialogs.TENANT_FORCE_TWO_FACTOR]: initDialogState(),
  [Dialogs.TENANT_STATISTICS_REPORT_EXPORT]: initDialogState(),
  [Dialogs.TENANT_STATISTICS_OVERVIEW_EXPORT]: initDialogState(),
  [Dialogs.CAMPAIGN_CSV_EXPORT]: initDialogState(),
  [Dialogs.TAG_CREATE]: initDialogState(),
  [Dialogs.FLOW_PUBLISHED_LINES]: initDialogState(),
  [Dialogs.FILTER_EXTENDED]: initDialogState(),
  [Dialogs.USER_PREFERENCES]: initDialogState(),
  [Dialogs.TAG_REMOVE]: initDialogState(),
  [Dialogs.BILLING_TENANT_SWITCH]: initDialogState(),
  [Dialogs.BILLING_TARIFF_REMOVE]: initDialogState(),
  [Dialogs.BILLING_CSV_EXPORT]: initDialogState(),
  [Dialogs.DIAL_LOG_AUDIO_REMOVE]: initDialogState(),
  [Dialogs.CAMPAIGN_CSV_OVERVIEW]: initDialogState(),
  [Dialogs.LINE_MONITORING_REMOVE]: initDialogState(),
  [Dialogs.LINE_MONITORING_STATE_TOGGLE]: initDialogState(),
  [Dialogs.FLOW_VERSION_DUPLICATE]: initDialogState(),
  [Dialogs.VARIABLE_STORAGE_CREATE]: initDialogState(),
  [Dialogs.VARIABLE_STORAGE_REMOVE]: initDialogState(),
  [Dialogs.VARIABLE_STORAGE_EDIT]: initDialogState(),
  [Dialogs.VARIABLE_STORAGE_API_KEY]: initDialogState(),
  [Dialogs.VARIABLE_STORAGE_TENANT_SELECT]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_CSV_EXPORT]: initDialogState(),
};

export default state;
