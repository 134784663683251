import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapGetters } from "vuex";
import Dialog from "../Dialog";
import TenantSelect from "../TenantSelect";

const VariableStorageTenantSelectDialog = Vue.extend({
  data: () => ({
    idTenant: "",
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.VARIABLE_STORAGE_TENANT_SELECT);
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.VARIABLE_STORAGE_TENANT_SELECT}
        title="Change tenant"
        closeOnEsc
        toggleHandler={() => (this.idTenant = this.instanceData.idTenant)}
        actions={[
          {
            title: "Confirm",
            icon: "mdi-check",
            id: Actions.VARIABLE_STORAGE_TENANT_CHANGE,
            action: () => {
              this.$emit("tenantChanged", this.idTenant);
            },
          },
        ]}
      >
        <TenantSelect vModel={this.idTenant} />
      </Dialog>
    );
  },
});

export default VariableStorageTenantSelectDialog;
