import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Dropdowns from "@/constants/dropdowns";
import Routes from "@/constants/routes";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import BillingTariffConfigurationDialog from "../BillingTariffConfigurationDialog";
import BillingTenantChangeDialog from "../BillingTenantChangeDialog";
import DetailWithTabs from "../DetailWithTabs";

const Billing = Vue.extend({
  computed: {
    ...mapGetters(Modules.DROPDOWNS, [Getters.GET_DROPDOWN_DATA]),

    tenantName(): string {
      const dropdownData = this.GET_DROPDOWN_DATA(
        Dropdowns.INFRASTRUCTURE_TENANTS
      );

      const instanceParam = this.$route.query.app ?? "master";
      const currentInstance = dropdownData.find(
        ({ instanceUrl }: any) => instanceUrl === instanceParam
      );

      return currentInstance?.data[this.$route.params.idTenant] ?? "";
    },
  },

  methods: {
    ...mapActions(Modules.DROPDOWNS, [Actions.DROPDOWN_DATA_FETCH]),

    initBilling() {
      if (!this.$route.params.idTenant) {
        toggleDialog(Dialogs.BILLING_TENANT_SWITCH, {});
      } else {
        this.DROPDOWN_DATA_FETCH({
          url: ApiEndpoints.INSTANCES_TENANT_LIST,
          dropdown: Dropdowns.INFRASTRUCTURE_TENANTS,
          dataModificator: (data: any) => data,
        });
      }
    },
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Billing - ${this.tenantName}${
            this.$route.query.app ? ` (${this.$route.query.app})` : ""
          }`}
          defaultReturnRoute={Routes.ADMINISTRATION}
          routerKey={`${this.$route.name}${this.$route.params.idTenant}`}
          initDataFetch={this.initBilling}
          tabs={[
            {
              title: "Overview",
              path: Routes.BILLING_OVERVIEW,
            },
            ...(!this.$route.query.app || this.$route.query.app === "master"
              ? [
                  {
                    title: "Tariffs",
                    path: Routes.BILLING_TARIFFS,
                  },
                  {
                    title: "Lines",
                    path: Routes.BILLING_LINES,
                  },
                ]
              : []),
          ]}
          actions={[
            {
              icon: "mdi-account-switch-outline",
              title: "Change tenant",
              action: () => toggleDialog(Dialogs.BILLING_TENANT_SWITCH, {}),
            },
          ]}
        />

        <BillingTenantChangeDialog />
        <BillingTariffConfigurationDialog />
      </div>
    );
  },
});

export default Billing;
