import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import useModuleActions from "@/hooks/useModuleActions";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import CsvExportDialog from "../CsvExportDialog";
import Dialogs from "@/constants/dialogs";
import ApiEndpoints from "@/constants/apiEndpoints";
import ModuleRemoveDialog from "../ModuleRemoveDialog";
import DialLogDetailDialog from "../DialLogDetailDialog";

const ModuleDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_MODULE]),

    instanceData(): any {
      const name = this.INSPECTED_MODULE?.basic?.content?.name;
      const id = this.$route.params.idDialModule;

      return { name, id };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.MODULE_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Module - ${this.instanceData.name || ""}`}
          defaultReturnRoute={Routes.MODULE_OVERVIEW}
          routerKey={this.$route.name}
          actions={useModuleActions(this.instanceData)}
          initDataFetch={() =>
            this.MODULE_OVERVIEW_FETCH({ idDialModule: this.instanceData.id })
          }
          tabs={[
            {
              title: "General",
              path: Routes.MODULE_DETAIL_BASIC,
            },
            {
              title: "Lines",
              path: Routes.MODULE_DETAIL_LINES,
            },
            {
              title: "Conversation log",
              path: Routes.MODULE_DETAIL_DIAL_LOGS,
            },
          ]}
        />

        <CsvExportDialog
          name={Dialogs.MODULE_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_REPORTS_MODULE}
          prefix="module"
          asConversationSelect
        />
        <ModuleRemoveDialog />
        <DialLogDetailDialog />
      </div>
    );
  },
});

export default ModuleDetail;
