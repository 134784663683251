import Dialogs from "@/constants/dialogs";
import { getTagColorClass } from "@/helpers/tagColorConfig";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Tag from "@/models/Tag";
import Vue, { VNode } from "vue";
import { VAvatar, VChip, VIcon, VSkeletonLoader } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const FilterExtendedDialog = Vue.extend({
  name: "FilterExtendedDialog",
  props: {
    dataGrid: String,
    actionId: String,
  },

  data: () => ({
    selectedTags: [] as string[],
  }),

  computed: {
    ...mapGetters(Modules.RESOURCES, [Getters.TAGS]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_FILTER]),

    tagsSet(): Tag[] {
      return Object.values(this.TAGS);
    },

    dataGridFilters(): any {
      return this.GET_DATAGRID_FILTER(this.dataGrid);
    },
  },

  methods: {
    ...mapActions(Modules.RESOURCES, [Actions.TAGS_FETCH]),

    initFilters() {
      this.selectedTags = [...(this.dataGridFilters?.tags || [])] ?? [];
      this.TAGS_FETCH();
    },

    submitFilters(): void {
      this.$store.dispatch(`${Modules.DATA_GRIDS}/${this.actionId}`, {
        params: {
          ...this.dataGridFilters,
          tags: this.selectedTags,
        },
      });

      toggleDialog(Dialogs.FILTER_EXTENDED);
    },

    toggleTag(title: string): void {
      const tagIndex = this.getTagIndex(title);

      tagIndex === -1
        ? this.selectedTags.push(title)
        : this.selectedTags.splice(tagIndex, 1);
    },

    getTagIndex(title: string): number {
      return this.selectedTags.findIndex((tag: string) => tag === title);
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.FILTER_EXTENDED}
        title="Advanced filters"
        toggleHandler={(isActive: boolean) =>
          isActive ? this.initFilters() : null
        }
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            action: this.submitFilters,
          },
        ]}
      >
        {this.IS_ACTION_FETCHING(Actions.TAGS_FETCH) ? (
          <VSkeletonLoader type="list-item" />
        ) : (
          <div>
            {this.tagsSet?.map(({ title, color }: Tag) => (
              <VChip
                class="mr-2 mb-2"
                color={getTagColorClass(color)}
                onClick={() => this.toggleTag(title)}
              >
                {this.getTagIndex(title) !== -1 && (
                  <VAvatar class="error" left>
                    {" "}
                    <VIcon>mdi-checkbox-marked-circle</VIcon>
                  </VAvatar>
                )}

                {title}
              </VChip>
            ))}
            {this.tagsSet?.length === 0 && <span>No filters available.</span>}
          </div>
        )}
      </Dialog>
    );
  },
});

export default FilterExtendedDialog;
