import {
  formatDateForDatePicker,
  formatTimeForTimePicker,
} from "@/helpers/dateAndTimeUtils";
import Vue, { VNode } from "vue";
import {
  VCol,
  VDatePicker,
  VLabel,
  VMenu,
  VRow,
  VTextField,
  VTimePicker,
} from "vuetify/lib";

const DateTimePicker = Vue.extend({
  data: () => ({
    dateMenu: false,
    timeMenu: false,
    date: "",
    time: "",
  }),
  props: {
    value: [String, Date],
    label: String,
    dateLabel: {
      type: String,
      default: "",
    },
    timeLabel: {
      type: String,
      default: "",
    },
    timeFormat: {
      type: String,
      default: "24hr",
    },
    firstDayOfWeek: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    initValues(): void {
      this.date = formatDateForDatePicker(this.value || "");
      this.time = formatTimeForTimePicker(this.value || "");
    },
    dateChange(): void {
      this.dateMenu = false;
      if (this.date && !this.time) {
        this.timeMenu = true;
      }
      this.mixDateTime();
    },
    timeChange(): void {
      this.timeMenu = false;
      this.mixDateTime();
    },
    mixDateTime(): void {
      if (this.date) {
        this.$emit(
          "input",
          new Date(`${this.date} ${this.time}`).toISOString()
        );
      }
    },
  },
  mounted() {
    this.initValues();
  },
  watch: {
    value: function () {
      this.initValues();
    },
  },

  render(): VNode {
    return (
      <div>
        <VLabel>{this.label}</VLabel>
        <VRow>
          <VCol cols={7}>
            <VMenu
              closeOnContentClick={false}
              minWidth="auto"
              vModel={this.dateMenu}
              content-class="lowered-picker"
              scopedSlots={{
                activator: ({ on, attrs }: any) => (
                  <VTextField
                    label={this.dateLabel}
                    vModel={this.date}
                    clearable
                    {...{ on, attrs }}
                    on={{
                      "click:clear": () => {
                        this.date = "";
                        this.dateChange();
                      },
                    }}
                    class="pt-0"
                    hideDetails
                  />
                ),
              }}
            >
              <VDatePicker
                no-title
                firstDayOfWeek={this.firstDayOfWeek}
                vModel={this.date}
                onChange={this.dateChange}
              />
            </VMenu>
          </VCol>
          <VCol cols={5}>
            <VMenu
              closeOnContentClick={false}
              minWidth="auto"
              vModel={this.timeMenu}
              content-class="lowered-picker"
              scopedSlots={{
                activator: ({ on, attrs }: any) => (
                  <VTextField
                    disabled={!this.date}
                    label={this.timeLabel}
                    vModel={this.time}
                    {...{ on, attrs }}
                    clearable
                    on={{
                      "click:clear": () => {
                        this.time = "";
                        this.timeChange();

                        this.$refs.timePicker
                          ? ((this.$refs as any).timePicker.selectingHour =
                              true)
                          : null;
                      },
                    }}
                    class="pt-0"
                  />
                ),
              }}
            >
              <VTimePicker
                no-title
                ref="timePicker"
                format={this.timeFormat}
                vModel={this.time}
                onChange={this.timeChange}
                on={{
                  "click:minute": () => {
                    this.timeChange();
                  },
                }}
              />
            </VMenu>
          </VCol>
        </VRow>
      </div>
    );
  },
});

export default DateTimePicker;
