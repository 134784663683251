import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const BillingTariffsSelect = Vue.extend({
  props: {
    value: String,
    options: Object,
    idTenant: String,
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.TARIFFS}
        label="Tariff"
        fetchUrl={`${ApiEndpoints.TARIFFS_LIST}/${this.idTenant}`}
        onInput={(val: string) => this.$emit("input", val)}
        options={this.options}
        value={this.value}
        useCache={false}
      />
    );
  },
});

export default BillingTariffsSelect;
