import DataGridsState from "@/models/store/DataGridsState";
import DataGrids from "@/constants/dataGrids";

const state: DataGridsState = {
  [DataGrids.DIAL_MODULES]: {},
  [DataGrids.LINES]: {},
  [DataGrids.DIAL_LOGS]: {},
  [DataGrids.LINE_QUEUE]: {},
  [DataGrids.CALL_SCRIPTS]: {},
  [DataGrids.FLOW_VERSION_HISTORY]: {},
  [DataGrids.CAMPAIGNS]: {},
  [DataGrids.GRAMMARS]: {},
  [DataGrids.USERS]: {},
  [DataGrids.TENANTS]: {},
  [DataGrids.TENANT_TARIFFS]: {},
  [DataGrids.LINE_TARIFFS]: {},
  [DataGrids.AUDIT]: {},
  [DataGrids.PERMISSION_GROUPS]: {},
  [DataGrids.BOTDEPLOY_MODULES]: {},
  [DataGrids.BOTDEPLOY_SERVERS]: {},
  [DataGrids.BOTDEPLOY_CONFIGS]: {},
  [DataGrids.NLP_TESTER_DATASETS]: {},
  [DataGrids.NLP_TESTER_CATEGORIES]: {},
  [DataGrids.NLP_TESTER_MODELS]: {},
  [DataGrids.LANGUAGES]: {},
  [DataGrids.TAGS]: {},
  [DataGrids.CONVERSATION_LOGS]: {},
  favoriteFlows: false,
};

export default state;
