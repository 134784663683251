import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import NlpTesterCategoryCreateDialog from "../NlpTesterCategoryCreateDialog";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import NlpTesterCategoryRemoveDialog from "../NlpTesterCategoryRemoveDialog";
import { VIcon, VTooltip } from "vuetify/lib";

const NlpTesterCategoriesDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
    language: "",
    datasetId: "",
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.NLP_TESTER_CATEGORIES_FETCH]),

    getMedianDifferenceData(percentage: number): any {
      const LOW_LIMIT = 20;
      const HIGH_LIMIT = 40;
      const roundedPercentage = Number(percentage.toFixed());

      if (roundedPercentage >= HIGH_LIMIT) {
        return { icon: "chevron-double-up", color: "error" };
      }

      if (roundedPercentage >= LOW_LIMIT) {
        return { icon: "chevron-up", color: "warning" };
      }

      if (roundedPercentage <= -HIGH_LIMIT) {
        return { icon: "chevron-double-down", color: "error" };
      }

      if (roundedPercentage <= -LOW_LIMIT) {
        return { icon: "chevron-down", color: "warning" };
      }

      return { icon: "minus", color: "success" };
    },

    formatSampleCount(percentage: number, sampleCount: number): any {
      const { icon, color } = this.getMedianDifferenceData(percentage);
      const countIsHigherThanMedian = percentage > 0;

      return (
        <VTooltip
          bottom
          scopedSlots={{
            activator: ({ on, attrs }: any) => (
              <span class="d-flex align-center">
                <VIcon small color={color} {...{ on, attrs }}>
                  mdi-{icon}
                </VIcon>
                <span class="d-inline-block ml-1">{sampleCount}</span>
              </span>
            ),
          }}
        >
          <span>
            {Math.abs(percentage).toFixed()}%{" "}
            {countIsHigherThanMedian ? "higher" : "less"} than sample count
            median
          </span>
        </VTooltip>
      );
    },
  },

  created() {
    this.language = this.$route.params?.language || "";
    this.datasetId = this.$route.params?.datasetId || "";

    const dataGridConfig: DataGridConfig = {
      title: `NLP categories - ${this.datasetId}`,
      isHeaderOutside: true,
      key: "name",
      buttons: [
        {
          title: "Back",
          icon: "mdi-arrow-left",
          action: () => this.$router.push({ name: Routes.NLP_TESTER_DATASETS }),
          isPrimary: false,
        },
        {
          title: "New category",
          icon: "mdi-plus",
          isAllowed: hasUserPermissions(Namespaces.NLP, [
            Permissions.CATEGORY_CREATE,
          ]),
          action: () => toggleDialog(Dialogs.NLP_TESTER_CATEGORY_CREATE, {}),
        },
      ],
      rowActions: (row) => ({
        primary: {
          icon: "mdi-cog",
          title: "Settings",
          isAllowed: hasUserPermissions(Namespaces.NLP, [
            Permissions.CATEGORY_VIEW,
          ]),
          action: () =>
            this.$router.push({
              name: Routes.NLP_TESTER_CATEGORY_DETAIL_BASIC,
              params: {
                language: row.language,
                category: row.name,
                datasetId: this.$route.params?.datasetId,
              },
            }),
        },
        secondary: [
          {
            icon: "mdi-delete",
            title: "Remove",
            isAllowed: hasUserPermissions(Namespaces.NLP, [
              Permissions.CATEGORY_CREATE,
            ]),
            action: ({ name }) =>
              toggleDialog(Dialogs.NLP_CATEGORY_REMOVE_DIALOG, {
                category: name,
                datasetId: this.$route.params?.datasetId,
                language: this.$route.params?.language,
              }),
          },
        ],
      }),
      filters: [{ name: "name" }],
      headers: [
        { text: "Name", value: "name" },
        { text: "Last change", value: "changed" },
        { text: "Size", value: "size" },
        { text: "Sample count", value: "sampleCount" },
      ],
      columnModificators: {
        "item.size": ({ item }) => `${item.size / 1000} kB`,
        "item.changed": ({ item }) => formatDate(item.changed),
        "item.sampleCount": ({ item }) =>
          this.formatSampleCount(
            item.sampleCountMedianPercentageDifference,
            item.sampleCount
          ),
      },
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.NLP_TESTER_CATEGORIES_FETCH}
          fetchProps={{
            language: this.language,
            datasetId: this.datasetId,
            countSamples: true,
          }}
          data={this.GET_DATAGRID_DATA(DataGrids.NLP_TESTER_CATEGORIES)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(
            Actions.NLP_TESTER_CATEGORIES_FETCH
          )}
        />
        <NlpTesterCategoryCreateDialog />
        <NlpTesterCategoryRemoveDialog />
      </div>
    );
  },
});

export default NlpTesterCategoriesDataGrid;
