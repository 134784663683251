import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import NlpTesterModelsCreateDialog from "../NlpTesterModelsCreateDialog";
import NlpTesterModelTrainDialog from "../NlpTesterModelTrainDialog";
import NlpTesterModelPredictDialog from "../NlpTesterModelPredictDialog";
import Routes from "@/constants/routes";
import NlpTesterModelRemoveDialog from "../NlpTesterModelRemoveDialog";
import { VChip } from "vuetify/lib";
import TRAINING_STATUSES from "@/constants/nlpModelTrainingStatus";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import ExternalLinks from "@/constants/externalLinks";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const NlpTesterModelsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.NLP_TESTER_MODELS_FETCH]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "NLP models",
      isHeaderOutside: true,
      key: "name",
      buttons: [
        {
          title: "Back",
          icon: "mdi-arrow-left",
          action: () => this.$router.push({ name: Routes.NLP_TESTER }),
          isPrimary: false,
        },
        {
          role: "primary",
          title: "New model",
          icon: "mdi-plus",
          isAllowed: hasUserPermissions(Namespaces.NLP, [
            Permissions.MODEL_CREATE,
          ]),
          action: () => toggleDialog(Dialogs.NLP_TESTER_MODEL_CREATE, {}),
        },
      ],
      rowActions: ({ language, name, trained }) => ({
        primary: {
          icon: "mdi-head-cog",
          title: "Train",
          isAllowed: hasUserPermissions(Namespaces.NLP, [
            Permissions.MODEL_TRAIN,
          ]),
          action: () =>
            toggleDialog(Dialogs.NLP_TESTER_MODEL_TRAIN, { name, language }),
        },
        secondary: [
          {
            icon: "mdi-scale-unbalanced",
            title: "Prediction test",
            disabled: !trained,
            action: () =>
              toggleDialog(Dialogs.NLP_TESTER_MODEL_PREDICT, {
                language,
                name,
              }),
          },
          {
            icon: "mdi-link-variant-plus",
            title: "Generate test link",
            disabled: !trained,
            action: () =>
              window.open(
                `${ExternalLinks.NLP_PREDICTION_TEST}?language=${language}&modelId=${name}`,
                "_blank"
              ),
          },
          {
            icon: "mdi-delete",
            title: "Remove",
            isAllowed: hasUserPermissions(Namespaces.NLP, [
              Permissions.MODEL_CREATE,
            ]),
            action: () =>
              toggleDialog(Dialogs.NLP_TESTER_MODEL_REMOVE, {
                language,
                name,
              }),
          },
        ],
      }),
      headers: [
        { text: "Name", value: "name" },
        { text: "Language", value: "language" },
        { text: "Dataset", value: "datasetId" },
        { text: "Text classifier", value: "textClassifier" },
        { text: "Status", value: "status" },
      ],
      filters: [{ name: "name" }],
      columnModificators: {
        "item.status": ({ item }) => {
          const statusConfig = TRAINING_STATUSES.find((status: any) =>
            status.statuses.includes(item?.status)
          );

          if (!item.trained) {
            return <VChip>Not trained</VChip>;
          }

          if (statusConfig) {
            return (
              <VChip color={statusConfig.color}>
                {statusConfig.title}{" "}
                {statusConfig.isFinished && (
                  <span class="ml-1 font-italic">
                    ({formatDate(item.trained)})
                  </span>
                )}
              </VChip>
            );
          }

          return <VChip>Unknown</VChip>;
        },
      },
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.NLP_TESTER_MODELS_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.NLP_TESTER_MODELS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.NLP_TESTER_MODELS_FETCH)}
        />
        <NlpTesterModelsCreateDialog />
        <NlpTesterModelTrainDialog />
        <NlpTesterModelPredictDialog />
        <NlpTesterModelRemoveDialog />
      </div>
    );
  },
});

export default NlpTesterModelsDataGrid;
