import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";

const grammarActions: ActionTree<InstancesState, []> = {
  [Actions.GRAMMAR_OVERVIEW_FETCH]: ({ commit, dispatch }, { idGrammar }) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.GRAMMAR_BASIC,
      action: Actions.GRAMMAR_OVERVIEW_FETCH,
      params: { idGrammar },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_GRAMMAR, {
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_GRAMMAR, {}),
    });
  },

  [Actions.GRAMMAR_DUPLICATE]: ({ dispatch }, idGrammar) =>
    dispatch(Actions.INSTANCE_DUPLICATE, {
      action: Actions.GRAMMAR_DUPLICATE,
      param: { id: "idGrammar", value: idGrammar },
      prefix: "Grammar",
      dialog: Dialogs.GRAMMAR_DUPLICATE,
      redirectRoute: Routes.GRAMMARS_DETAIL_BASIC,
      endpoint: ApiEndpoints.GRAMMAR_DUPLICATE,
    }),

  [Actions.GRAMMAR_REMOVE]: ({ dispatch }, { idGrammar, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idGrammar },
      isDetailActive,
      endpoint: ApiEndpoints.GRAMMAR_REMOVE,
      action: Actions.GRAMMAR_REMOVE,
      redirectRoute: Routes.GRAMMARS_OVERVIEW,
      dataGrid: DataGrids.GRAMMARS,
      dataGridFetch: Actions.GRAMMARS_DATAGRID_FETCH,
      dialog: Dialogs.GRAMMAR_REMOVE,
      prefix: "Grammar",
    }),

  [Actions.GRAMMAR_CHECK_UNIQUE_NAME]: async (_, name) => {
    const grammarRes = await createRequest({
      method: "GET",
      url: ApiEndpoints.GRAMMAR_CHECK_UNIQUE_NAME,
      action: Actions.GRAMMAR_CHECK_UNIQUE_NAME,
      params: { name },
    });

    const { errors } = parseResponse(grammarRes);

    if (errors.length === 0) {
      return true;
    }

    if (errors.length === 1 && errors[0].status === 409) {
      return false;
    }

    pushNotify(Notify.ERROR, "Error while checking grammar name");
    return null;
  },
};

export default grammarActions;
