const USER_PREFERENCES = {
  datagridItemsPerPage: {
    label: "Datagrid items per page",
  },
  timezone: {
    label: "Timezone",
    textModifier: (val: string | null): string =>
      val === null ? "From browser" : val,
  },
  preferConversations: {
    label: "Show conversations by default",
    textModifier: (val: boolean): string => (val ? "Enabled" : "Disabled"),
  },
};

export default USER_PREFERENCES;
