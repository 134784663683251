const parseStorageTimetable = (value: string): any => {
  const parsedData = JSON.parse(value);
  const timeRangeGroups: any = {};

  Object.entries(parsedData.dow).map(([day, ranges]: any) => {
    if (!ranges) {
      return;
    }

    const rangesString = ranges.map(({ from, to }: any) => `${from} - ${to}`);
    const timeRangeKey = rangesString.join(";");

    !timeRangeGroups[timeRangeKey]
      ? (timeRangeGroups[timeRangeKey] = [])
      : null;
    timeRangeGroups[timeRangeKey].push(day);
  });

  const dateGroups = Object.entries(parsedData.dates).map(([date, ranges]) => ({
    date,
    isDisabled: ranges === null,
    ranges: ranges ?? [{ from: "", to: "" }],
    pickerActive: false,
  }));

  return {
    timezone: parsedData.timezone,
    dow: timeRangeGroups,
    dates: dateGroups,
  };
};

export default parseStorageTimetable;
