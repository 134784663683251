import Dialogs from "@/constants/dialogs";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import getCountryCode from "@/helpers/getCountryCode";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import CountryFlag from "vue-country-flag";
import { VDivider, VSimpleTable } from "vuetify/lib";
import { mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import GrammarContentEditForm from "../GrammarContentEditForm";
import GrammarOverviewEditForm from "../GrammarOverviewEditForm";
import NlpTesterCategorySamplesGenerateDialog from "../NlpTesterCategorySamplesGenerateDialog";

const GrammarOverview = Vue.extend({
  data: () => ({
    basic: {},
    configuration: {
      title: "Content",
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_GRAMMAR]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    formattedContent(): string[] {
      return this.INSPECTED_GRAMMAR?.basic?.content?.content?.split(/\n/) || [];
    },
  },

  created() {
    this.basic = {
      title: "Overview",
      modificators: {
        created: ({ created }: any) => formatDate(created),
        changed: ({ changed }: any) => formatDate(changed),
        language: ({ language }: any) => (
          <div class="d-flex align-center">
            <CountryFlag size="small" country={getCountryCode(language)} />
            <span class="pl-1">{language}</span>
          </div>
        ),
      },
      columns: [
        { name: "name", title: "Name" },
        { name: "language", title: "Language" },
        { name: "owner", title: "Author" },
        { name: "typeName", title: "Type" },
        { name: "created", title: "Created at" },
        { name: "changed", title: "Last changed at" },
        { name: "tenant", title: "Tenant" },
      ],
    };
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_GRAMMAR?.basic}
          editDialog={Dialogs.GRAMMAR_EDIT_BASIC}
          action={Actions.GRAMMAR_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <GrammarOverviewEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.GRAMMAR_OVERVIEW_FETCH
          )}
        />
        <VDivider />
        <EditableOverviewSection
          configuration={this.configuration}
          data={this.INSPECTED_GRAMMAR?.basic}
          editDialog={Dialogs.GRAMMAR_EDIT_CONTENT}
          action={Actions.GRAMMAR_OVERVIEW_EDIT}
          editSecondaryActions={[
            {
              action: () =>
                toggleDialog(Dialogs.NLP_TESTER_SAMPLES_GENERATE, {
                  editFormRef: this.$refs.form,
                  language: this.INSPECTED_GRAMMAR.basic?.content?.language,
                  defaultCategory: this.INSPECTED_GRAMMAR.basic?.content?.name,
                }),
              title: "Generate",
              icon: "mdi-cog-transfer",
            },
          ]}
          scopedSlots={{ form: () => <GrammarContentEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.GRAMMAR_OVERVIEW_FETCH
          )}
          overviewComponent={
            <VSimpleTable dense>
              {this.formattedContent.length > 0 ? (
                <tbody>
                  {this.formattedContent.map((item) => (
                    <tr>
                      <td>{item}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <span>There is no content yet.</span>
              )}
            </VSimpleTable>
          }
        />
        <NlpTesterCategorySamplesGenerateDialog />
      </div>
    );
  },
});

export default GrammarOverview;
