import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import NlpTesterModelTrainForm from "../NlpTesterModelTrainForm";

const NlpTesterModelTrainDialog = Vue.extend({
  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        id={Dialogs.NLP_TESTER_MODEL_TRAIN}
        title="Model Training"
        width={600}
        actions={[
          {
            title: "Run",
            icon: "mdi-play",
            id: Actions.NLP_TESTER_MODEL_TRAIN,
            action: () => refs.form?.handleSubmit(),
          },
        ]}
      >
        <NlpTesterModelTrainForm ref="form" />
      </Dialog>
    );
  },
});

export default NlpTesterModelTrainDialog;
