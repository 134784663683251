const Dropdowns = {
  MODULES: "MODULES",
  FLOW: "FLOW",
  FLOW_OVERVIEW: "FLOW_OVERVIEW",
  TENANTS: "TENANTS",
  LANGUAGES: "LANGUAGES",
  NOTIFY_GROUPS: "NOTIFY_GROUPS",
  GRAMMARS: "GRAMMARS",
  COMPONENTS: "COMPONENTS",
  LINES: "LINES",
  LINES_WITH_MODULE: "LINES_WITH_MODULE",
  BOTDEPLOY_SERVERS: "BOTDEPLOY_SERVERS",
  BOTDEPLOY_CONFIGS: "BOTDEPLOY_CONFIGS",
  NLP_DATASETS: "NLP_DATASETS",
  LANGUAGE_CODES: "LANGUAGE_CODES",
  TAGS: "TAGS",
  TARIFFS: "TARIFFS",
  INFRASTRUCTURE_TENANTS: "INFRASTRUCTURE_TENANTS",
  FLOW_LANGUAGES: "FLOW_LANGUAGES",
  COMPONENT_TYPES: "COMPONENT_TYPES",
  TIME_ZONES: "TIME_ZONES",
};

export default Dropdowns;
