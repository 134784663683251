import CountryFlag from "vue-country-flag";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import lineIdentificatorTypes from "@/constants/lineIdetificatorTypes";
import getCountryCode from "@/helpers/getCountryCode";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider, VIcon } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import LineBasicEditForm from "../LineBasicEditForm";
import LineCallbotConfigurationForm from "../LineCallbotConfigurationForm";
import LineCleaningEditForm from "../LineCleaningEditForm";

const LineOverview = Vue.extend({
  name: "LineOverview",
  data: () => ({
    basic: {},
    callbotConfiguration: {
      title: "Advanced configuration",
      columns: [
        {
          name: "MaxInboundCalls",
          title: "Max Inbound Calls",
        },
        {
          name: "MaxOutboundCalls",
          title: "Max Outbound Calls",
        },
        {
          name: "CallMode",
          title: "Call Mode",
        },
        {
          name: "ActionOnFailure",
          title: "Action On Failure",
        },
      ],
    },
    cleaningSettings: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    idDialModuleLine(): string {
      return this.$route.params.idDialModuleLine;
    },

    cleaningSettingsData(): any {
      const cleaningData = {};

      this.INSPECTED_LINE?.cleaningSettings?.content?.map((group: any) => {
        const { oldInDays, ...fields } = group;

        Object.entries(fields).map(([param, isActive]) => {
          isActive ? (cleaningData[param] = oldInDays) : null;
        });
      });

      return {
        content: cleaningData,
        editableFields: this.INSPECTED_LINE?.cleaningSettings?.editableFields,
      };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.LINE_OVERVIEW_FETCH,
      Actions.LINE_CALLBOT_CONFIG_FETCH,
      Actions.LINE_CLEANING_SETTINGS_FETCH,
    ]),

    renderStatusIcon(oldInDays: number): any {
      return oldInDays ? (
        <span>
          <VIcon color="success">mdi-checkbox-marked-circle</VIcon> ({oldInDays}{" "}
          days)
        </span>
      ) : (
        <VIcon color="error">mdi-close-circle</VIcon>
      );
    },

    setBasicConfiguration(): any {
      return {
        title: "Overview",
        columns: [
          { name: "name", title: "Name" },
          { name: "callScript", title: "Flow" },
          { name: "dialModule", title: "Module" },
          { name: "tenant", title: "Tenant" },
          { name: "identificator", title: "Identificator" },
          { name: "identificatorType", title: "Identificator type" },
        ],
        modificators: {
          dialModule: ({ idDialModule, dialModule }: any) => (
            <router-link
              class="tertiary--text"
              to={{
                name: Routes.MODULE_DETAIL_BASIC,
                params: { idDialModule },
              }}
            >
              {dialModule}
            </router-link>
          ),
          callScript: ({ callScript, idCallScript, languageCode }: any) => (
            <div class="d-flex align-center">
              <router-link
                class="tertiary--text"
                to={{
                  name: Routes.FLOW_DETAIL_BASIC,
                  params: { idCallScript },
                }}
              >
                {callScript}
              </router-link>
              {languageCode && (
                <div class="d-flex align-center ml-2">
                  (
                  <CountryFlag
                    size="small"
                    country={getCountryCode(languageCode)}
                  />
                  <span class="pl-1">{languageCode}</span>)
                </div>
              )}
            </div>
          ),
          identificatorType: ({ identificatorType }: any) =>
            lineIdentificatorTypes.find(
              (type) => type.value === identificatorType
            ),
        },
      };
    },

    setCleaningConfiguration(): any {
      return {
        title: "Cleaning settings",
        columns: [
          {
            name: "diallogs",
            title: "All records",
          },
          {
            name: "azure",
            title: "Azure content",
          },
          {
            name: "phone",
            title: "Phone",
          },
          {
            name: "speakflows",
            title: "Speak flows",
          },
          {
            name: "unrecognizedReactions",
            title: "Unrecognized reactions",
          },
          {
            name: "variables",
            title: "Variables",
          },
        ],
        modificators: {
          diallogs: ({ diallogs }: any) => this.renderStatusIcon(diallogs),
          azure: ({ azure }: any) => this.renderStatusIcon(azure),
          phone: ({ phone }: any) => this.renderStatusIcon(phone),
          speakflows: ({ speakflows }: any) =>
            this.renderStatusIcon(speakflows),
          unrecognizedReactions: ({ unrecognizedReactions }: any) =>
            this.renderStatusIcon(unrecognizedReactions),
          variables: ({ variables }: any) => this.renderStatusIcon(variables),
        },
      };
    },
  },

  created() {
    this.basic = this.setBasicConfiguration();
    this.cleaningSettings = this.setCleaningConfiguration();
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_LINE?.basic}
          editDialog={Dialogs.LINE_EDIT_BASIC}
          action={Actions.LINE_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <LineBasicEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(Actions.LINE_OVERVIEW_FETCH)}
        />

        <VDivider class="mb-5" />

        <EditableOverviewSection
          configuration={this.callbotConfiguration}
          data={this.INSPECTED_LINE?.callbotSettings}
          fetchData={() =>
            this.LINE_CALLBOT_CONFIG_FETCH({
              idDialModuleLine: this.idDialModuleLine,
            })
          }
          action={Actions.LINE_CALLBOT_CONFIG_EDIT}
          editDialog={Dialogs.LINE_EDIT_CALLBOT_CONFIG}
          scopedSlots={{
            form: () => <LineCallbotConfigurationForm ref="form" />,
          }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.LINE_CALLBOT_CONFIG_FETCH
          )}
        />

        <VDivider class="mb-5" />

        <EditableOverviewSection
          configuration={this.cleaningSettings}
          data={this.cleaningSettingsData}
          fetchData={() =>
            this.LINE_CLEANING_SETTINGS_FETCH({
              idDialModuleLine: this.idDialModuleLine,
            })
          }
          editDialog={Dialogs.LINE_CLEANING}
          action={Actions.LINE_CLEANING_SETTINGS_EDIT}
          scopedSlots={{ form: () => <LineCleaningEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.LINE_CLEANING_SETTINGS_FETCH
          )}
        />
      </div>
    );
  },
});

export default LineOverview;
