import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import { VForm, VTextField } from "vuetify/lib";
import { isRequired } from "@/helpers/validations";

const NlpDatasetsDuplicateDialog = Vue.extend({
  data: () => ({
    newInstanceName: "",
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.NLP_TESTER_DATASET_DUPLICATE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.NLP_TESTER_DATASET_DUPLICATE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.NLP_TESTER_DATASET_DUPLICATE}
        title={`Duplicate - ${this.instanceData.name}`}
        closeOnEsc
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            id: Actions.NLP_TESTER_DATASET_DUPLICATE,
            action: () => {
              const refs: any = this.$refs;

              if (refs.form.validate()) {
                this.NLP_TESTER_DATASET_DUPLICATE({
                  name: this.instanceData.name,
                  language: this.instanceData.language,
                  newInstanceName: this.newInstanceName,
                });
              }
            },
          },
        ]}
      >
        <VForm ref="form">
          <VTextField
            vModel={this.newInstanceName}
            label="Name"
            rules={[isRequired]}
          />
        </VForm>
      </Dialog>
    );
  },
});

export default NlpDatasetsDuplicateDialog;
