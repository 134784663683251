import hasUserPermissions from "@/helpers/hasUserPermissions";
import TileMenuItem from "@/models/TileMenuItem";
import Vue, { PropType, VNode } from "vue";
import {
  VRow,
  VCol,
  VCard,
  VToolbar,
  VToolbarTitle,
  VSpacer,
  VIcon,
} from "vuetify/lib";

const TileMenu = Vue.extend({
  props: {
    title: String,
    cssClass: String,
    items: {
      type: Array as PropType<TileMenuItem[]>,
    },
  },

  render(): VNode {
    return (
      <div class={this.cssClass ?? ""}>
        <VRow>
          <VCol>
            <h2 class="text-h4 font-weight-bold primary--text">{this.title}</h2>
          </VCol>
        </VRow>
        <VRow class="mt-2">
          {this.items.map(
            ({
              title,
              icon,
              route,
              isExternal,
              permissions = [null, null],
            }: TileMenuItem) =>
              hasUserPermissions(permissions[0], permissions[1]) && (
                <VCol cols={12} sm={6} md={4} lg={3}>
                  <VCard
                    to={isExternal ? null : { name: route }}
                    href={isExternal ? route : null}
                    target={isExternal ? "_blank" : null}
                    hover
                  >
                    <VToolbar flat>
                      <VToolbarTitle class="text-subtitle-1">
                        {title}
                      </VToolbarTitle>
                      <VSpacer />
                      {icon && <VIcon color="primary">{icon}</VIcon>}
                    </VToolbar>
                  </VCard>
                </VCol>
              )
          )}
        </VRow>
      </div>
    );
  },
});

export default TileMenu;
