import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const FlowRemoveDialog = Vue.extend({
  name: "FlowRemoveDialog",
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    flowData(): any {
      return this.GET_DIALOG_DATA(Dialogs.FLOW_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.FLOW_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            id: Actions.FLOW_REMOVE,
            action: () =>
              this.FLOW_REMOVE({
                idCallScript: this.flowData.id,
                isDetailActive: !!this.$route.params.idCallScript,
              }),
          },
        ]}
      >
        Do you really want to remove "
        <strong class="primary--text">{this.flowData.name}</strong>" flow?
      </Dialog>
    );
  },
});

export default FlowRemoveDialog;
