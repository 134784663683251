import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VIcon,
  VSpacer,
  VTimeline,
  VTimelineItem,
} from "vuetify/lib";
import { DIAL_LOG_STATUS_TYPE } from "@/constants/valueIcons";
import AudioPlayer from "@/components/AudioPlayer";

const DialLogTimeline = Vue.extend({
  props: {
    data: Array,
    startDate: String,
    record: { type: Object, required: false },
    withFullConverstaion: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showAsChat: false,
    playerTime: 0,
    audioTimeShift: 0,
  }),

  methods: {
    getEventTime(timestamp: string): string {
      const timeDifference =
        new Date(timestamp).getTime() - new Date(this.startDate).getTime();
      const timeInSeconds = Math.round(timeDifference / 1000);

      return this.formatTime(timeInSeconds);
    },

    calcAudioTime(timeStamp: string, type: number) {
      return Number(
        (
          Math.round(
            new Date(timeStamp).getTime() - new Date(this.startDate).getTime()
          ) /
            1000 -
          this.audioTimeShift -
          (type === 5 ? 1.2 : 0)
        ).toFixed(3)
      );
    },

    formatTime(seconds: number): string {
      let formattedSeconds = 0;
      let formattedMinutes = 0;

      if (seconds < 60) {
        formattedSeconds = seconds;
      } else {
        formattedMinutes = Math.trunc(seconds / 60);
        formattedSeconds = seconds % 60;
      }

      return `${
        formattedMinutes > 9 ? formattedMinutes : `0${formattedMinutes}`
      }:${formattedSeconds > 9 ? formattedSeconds : `0${formattedSeconds}`}`;
    },

    getStatusName(type: number, parameters: any): any {
      if (
        (type === 3 && parameters?.partyId) ||
        (type === 5 && parameters?.partyId)
      ) {
        return parameters?.partyId === "1" ? "Party 1" : "Party 2";
      }

      return DIAL_LOG_STATUS_TYPE[type]?.name;
    },

    getStatusColor(type: number, parameters: any) {
      if (
        (type === 3 && parameters?.partyId) ||
        (type === 5 && parameters?.partyId)
      ) {
        return parameters?.partyId === "1" ? "tertiary" : "accent";
      }

      return DIAL_LOG_STATUS_TYPE[type]?.color;
    },
  },

  created() {
    const startBlock: any = this.data?.find(({ type }: any) => type === 1);

    if (!startBlock) {
      return;
    }

    this.audioTimeShift =
      Math.round(
        new Date(startBlock?.timeStamp).getTime() -
          new Date(this.startDate).getTime()
      ) / 1000;
  },

  render(): VNode {
    return (
      <div>
        <div class="d-flex align-center">
          <h3 class="text-h6 primary--text">Conversation flow</h3>
          <VSpacer />
          <VBtn
            onClick={() => (this.showAsChat = !this.showAsChat)}
            class="secondary primary--text"
            rounded
          >
            <VIcon left>
              {this.showAsChat
                ? "mdi-format-list-bulleted"
                : "mdi-forum-outline"}
            </VIcon>
            Display as {this.showAsChat ? "list" : "chat"}
          </VBtn>
        </div>
        {this.record && (
          <AudioPlayer
            class="mt-1"
            flat
            file={this.record.audio}
            color="tertiary"
            ref="player"
            on={{
              "update:playerTime": (i: number) => (this.playerTime = i),
            }}
          />
        )}
        <VTimeline alignTop dense={!this.showAsChat} class="px-1">
          {this.data?.map(
            ({ timeStamp, type, activity, parameters }: any, index: number) => {
              const audioTime = this.calcAudioTime(timeStamp, type);
              const endAudioTime = this.data?.[index + 1]
                ? this.calcAudioTime(
                    (this.data as any)[index + 1].timeStamp,
                    (this.data as any)[index + 1].type
                  )
                : 999;
              return this.showAsChat ? (
                <VTimelineItem
                  small
                  fillDot
                  icon={DIAL_LOG_STATUS_TYPE[type]?.icon}
                  color={this.getStatusColor(type, parameters)}
                  right={type === 5 || parameters?.partyId === "2"}
                  left={
                    (type !== 5 && parameters?.partyId !== "2") ||
                    (type === 5 && parameters?.partyId === "1")
                  }
                >
                  <VCard
                    class={[
                      {
                        "elevation-8":
                          this.playerTime >= audioTime &&
                          this.playerTime < endAudioTime &&
                          !this.withFullConverstaion,
                      },
                    ]}
                  >
                    <VCardText class="pa-3">
                      <div
                        class="font-weight-normal cursor-pointer"
                        onClick={() =>
                          (this.$refs.player as any)?.seek(audioTime, true)
                        }
                      >
                        <strong
                          class={`${this.getStatusColor(
                            type,
                            parameters
                          )}--text`}
                        >
                          {this.getStatusName(type, parameters)}
                        </strong>
                        &nbsp;|&nbsp;
                        <strong>{this.getEventTime(timeStamp)}</strong>
                        &nbsp;-&nbsp;
                        {new Date(timeStamp).toLocaleTimeString()}
                      </div>
                      {activity && <div>{activity}</div>}
                    </VCardText>
                  </VCard>
                </VTimelineItem>
              ) : (
                <VTimelineItem
                  small
                  fillDot
                  class={[
                    "pt-2 pb-4",
                    {
                      "indigo lighten-5 rounded-lg":
                        this.playerTime >= audioTime &&
                        this.playerTime < endAudioTime &&
                        !this.withFullConverstaion,
                    },
                  ]}
                  icon={DIAL_LOG_STATUS_TYPE[type]?.icon}
                  color={this.getStatusColor(type, parameters)}
                >
                  <div>
                    {}
                    <div
                      class="font-weight-normal cursor-pointer"
                      onClick={() =>
                        (this.$refs.player as any)?.seek(audioTime, true)
                      }
                    >
                      <strong
                        class={`${this.getStatusColor(type, parameters)}--text`}
                      >
                        {this.getStatusName(type, parameters)}
                      </strong>
                      &nbsp;|&nbsp;
                      <strong>{this.getEventTime(timeStamp)}</strong>
                      &nbsp;-&nbsp;
                      {new Date(timeStamp).toLocaleTimeString()}
                    </div>
                    {activity && <div>{activity}</div>}
                  </div>
                </VTimelineItem>
              );
            }
          )}
        </VTimeline>
      </div>
    );
  },
});

export default DialLogTimeline;
