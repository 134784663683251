import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const ComponentTypeSelect = Vue.extend({
  props: {
    value: [Number, Array],
    options: Object,
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.COMPONENT_TYPES}
        label="Subtype"
        fetchUrl={ApiEndpoints.COMPONENT_TYPES_LIST}
        onInput={(val: number) => this.$emit("input", val)}
        options={this.options}
        value={this.value}
        isValueNumber
      />
    );
  },
});

export default ComponentTypeSelect;
