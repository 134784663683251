import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import { VForm, VTextField, VTextarea } from "vuetify/lib";
import { isRequired } from "@/helpers/validations";

const VariableStorageEditDialog = Vue.extend({
  data: () => ({
    value: "",
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.VARIABLE_STORAGE_EDIT);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.VARIABLE_STORAGE_EDIT]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.VARIABLE_STORAGE_EDIT}
        title="Confirmation"
        closeOnEsc
        toggleHandler={() => (this.value = this.instanceData.value)}
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            id: Actions.VARIABLE_STORAGE_EDIT,
            action: () =>
              this.VARIABLE_STORAGE_EDIT({
                ...this.instanceData,
                value: this.value,
              }),
          },
        ]}
      >
        <VForm>
          <VTextField value={this.instanceData.key} label="Key" disabled />
          <VTextarea
            vModel={this.value}
            label="Value"
            rows={1}
            autoGrow
            rules={[isRequired]}
          />
        </VForm>
      </Dialog>
    );
  },
});

export default VariableStorageEditDialog;
