import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import toggleDialog from "@/helpers/toggleDialog";

const tenantActions: ActionTree<InstancesState, []> = {
  [Actions.TENANT_OVERVIEW_FETCH]: (
    { state, commit, dispatch },
    { idTenant }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.TENANT_BASIC,
      action: Actions.TENANT_OVERVIEW_FETCH,
      params: { idTenant },
      withTitleChange: true,
      titleProp: "invoicingName",
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_TENANT, {
          ...state.inspectedTenant,
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_TENANT, {}),
    });
  },

  [Actions.TENANT_PARAMS_FETCH]: (
    { state, commit, dispatch },
    { idTenant }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.TENANT_PARAMETERS,
      action: Actions.TENANT_PARAMS_FETCH,
      params: { idTenant },
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_TENANT, {
          ...state.inspectedTenant,
          parameters: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_TENANT, {}),
    });
  },

  [Actions.TENANT_FETCH_NOTIFICATION_GROUPS]: async (
    { state, commit },
    { idTenant }
  ) => {
    const requestRes = await createRequest({
      action: Actions.TENANT_FETCH_NOTIFICATION_GROUPS,
      method: "GET",
      url: ApiEndpoints.TENANT_NOTIFICATION_GROUPS,
      params: { idTenant },
    });

    const { data, errors } = parseResponse(requestRes);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_TENANT, {
        ...state.inspectedTenant,
        notifyGroups: data,
      });
    }
  },

  [Actions.TENANT_REMOVE]: ({ dispatch }, { idTenant, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idTenant },
      isDetailActive,
      endpoint: ApiEndpoints.TENANT_REMOVE,
      action: Actions.TENANT_REMOVE,
      redirectRoute: Routes.TENANTS_OVERVIEW,
      dataGrid: DataGrids.TENANTS,
      dataGridFetch: Actions.TENANTS_DATAGRID_FETCH,
      dialog: Dialogs.TENANT_REMOVE,
      prefix: "Tenant",
    }),

  [Actions.TENANT_STATISTICS_FETCH]: async (
    { state, commit },
    { idTenant, ...params }
  ) => {
    const statisticsRes = await createRequest({
      action: Actions.TENANT_STATISTICS_FETCH,
      url: `${ApiEndpoints.TENANT_STATISTICS}/${idTenant}`,
      method: "GET",
      params,
    });

    const { data, errors } = parseResponse(statisticsRes);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_TENANT, {
        ...state.inspectedTenant,
        statistics: data,
      });
    }
  },

  [Actions.TENANT_REMOVE_NOTIFICATION_GROUP]: async (_, { idTenant, name }) => {
    const notifyGroupReq = await createRequest({
      action: Actions.TENANT_REMOVE_NOTIFICATION_GROUP,
      method: "DELETE",
      url: ApiEndpoints.TENANT_NOTIFICATION_GROUP,
      params: { idTenant, name },
    });

    const { errors } = parseResponse(notifyGroupReq);

    if (errors.length === 0) {
      toggleDialog(Dialogs.TENANT_NOTIFICATION_GROUP_REMOVE, {});
      pushNotify(Notify.SUCCESS, "Notification group removed successfully");
    }
  },

  [Actions.TENANT_FORCE_TWO_FACTOR]: async ({ dispatch }, { idTenant }) => {
    const twoFactorRes = await createRequest({
      action: Actions.TENANT_FORCE_TWO_FACTOR,
      url: ApiEndpoints.TENANT_TWO_FACTOR_FORCE,
      method: "GET",
      params: { idTenant },
    });

    const { errors } = parseResponse(twoFactorRes);

    if (errors.length === 0) {
      toggleDialog(Dialogs.TENANT_FORCE_TWO_FACTOR);
      pushNotify(
        Notify.SUCCESS,
        "Two factor authentication enabled succesfully"
      );
      dispatch(Actions.TENANT_OVERVIEW_FETCH, { idTenant });
    }
  },

  [Actions.TENANT_TARIFF_REMOVE]: ({ dispatch }, { idTenant, guid }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idTenant, guid },
      endpoint: ApiEndpoints.BILLING_TARIFF_CONFIGURE,
      action: Actions.TENANT_TARIFF_REMOVE,
      dataGrid: DataGrids.TENANT_TARIFFS,
      dataGridFetch: Actions.TENANT_TARRIFS_DATAGRID_FETCH,
      dialog: Dialogs.BILLING_TARIFF_REMOVE,
      prefix: "Tariff",
    }),
};

export default tenantActions;
