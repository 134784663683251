import { MutationTree } from "vuex";
import { Mutations } from "@/models/store";
import ResourcesState from "@/models/store/ResourcesState";

const mutations: MutationTree<ResourcesState> = {
  [Mutations.SET_LANGUAGES]: (state, payload) => (state.languages = payload),

  [Mutations.SET_TAGS]: (state, payload) => (state.tags = payload),
};

export default mutations;
