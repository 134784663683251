import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import DropdownsState from "@/models/store/DropdownsState";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";

const actions: ActionTree<DropdownsState, []> = {
  [Actions.DROPDOWN_DATA_FETCH]: async (
    { commit },
    { url, dropdown, isValueNumber, fetchParams, dataModificator }
  ) => {
    const reqResponse = await createRequest({
      method: "GET",
      url,
      params: fetchParams ? fetchParams : {},
    });

    const { data, errors } = parseResponse(reqResponse);
    if (errors.length === 0) {
      const formattedData = [];

      if (dataModificator) {
        commit(Mutations.SET_DROPDOWN_DATA, {
          dropdown,
          data: await dataModificator(data),
        });
        return;
      }

      if (Array.isArray(data)) {
        data.map((item) => formattedData.push({ text: item, value: item }));
      } else {
        for (const [key, value] of Object.entries(data)) {
          formattedData.push({
            text: value,
            value: isValueNumber ? Number(key) : key,
          });
        }
      }
      commit(Mutations.SET_DROPDOWN_DATA, { dropdown, data: formattedData });
    }
  },
};

export default actions;
