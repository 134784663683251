import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const NlpDatasetsSelect = Vue.extend({
  props: {
    value: String,
    options: Object,
  },

  methods: {
    formatData(data: any): any[] {
      return data.map((item: any) => ({
        text: `${item?.name} (${item?.language})`,
        value: { datasetId: item?.name, language: item?.language },
      }));
    },
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.NLP_DATASETS}
        label="NLP Dataset"
        fetchUrl={ApiEndpoints.NLP_TESTER_DATASET_LIST}
        onInput={(val: string) => this.$emit("input", val)}
        value={this.value}
        dataModificator={this.formatData}
        options={this.options}
      />
    );
  },
});

export default NlpDatasetsSelect;
