import Vue, { VNode } from "vue";
import { Doughnut } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./DoughnutChart.scss";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartDataLabels
);

const DoughnutChart = Vue.extend({
  props: {
    chartData: Object,
    centerValue: [String, Number],
    centerValuePerex: String,
  },

  data: () => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      cutout: 110,
      plugins: {
        datalabels: {
          formatter: (value: number, ctx: any) => {
            const sum = ctx.dataset.data.reduce(
              (a: number, b: number) => a + b,
              0
            );
            const percentage = Math.round((value / sum) * 100);
            return percentage > 5 ? `${percentage}%` : "";
          },
          color: "#fff",
          align: "center",
          font: {
            weight: "bold",
            size: 16,
          },
        },
        legend: {
          display: true,
        },
      },
    },
  }),

  render(): VNode {
    return (
      <div class="chart">
        <Doughnut
          chartData={this.chartData}
          chartOptions={this.chartOptions}
          width={340}
          height={410}
        />
        {this.centerValue !== null && this.centerValue !== undefined && (
          <div class="chart__center-text text-center">
            <div class="primary--text font-weight-bold text-h3 text-no-wrap">
              {this.centerValue}
            </div>
            {this.centerValuePerex && (
              <div class="primary--text text-h6 font-weight-regular text-no-wrap">
                {this.centerValuePerex}
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
});

export default DoughnutChart;
