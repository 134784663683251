import Dialogs from "@/constants/dialogs";
import { isInteger, isNumberInRange, isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { VTextarea } from "vuetify/lib/components";
import { mapGetters } from "vuex";
import Dialog from "../Dialog";

const NlpTesterCategorySamplesGenerateDialog = Vue.extend({
  data: () => ({
    fields: {
      category: "",
      count: 10,
      samples: "",
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_NLP_CATEGORY]),
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    dialogData(): any {
      return this.GET_DIALOG_DATA(Dialogs.NLP_TESTER_SAMPLES_GENERATE);
    },

    samplesSet(): string[] {
      return this.fields.samples === "" ? [] : this.fields.samples.split("\n");
    },
  },

  methods: {
    async handleSubmit() {
      const refs: any = this.$refs;

      if (!refs.form.validate()) {
        return;
      }

      this.dialogData.editFormRef.generateSamples(
        this.fields.category,
        this.samplesSet,
        this.fields.count,
        this.dialogData.language
      );
    },
  },

  created() {
    const { category } = this.$route.params;
    this.fields.category = category;
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.NLP_TESTER_SAMPLES_GENERATE}
        title="Generate samples"
        width={1200}
        actions={[
          {
            title: "Generate",
            action: () => this.handleSubmit(),
            icon: "mdi-cog-transfer",
            id: Actions.NLP_CATEGORY_SAMPLES_GENERATE,
          },
        ]}
        toggleHandler={() =>
          (this.fields.category =
            this.dialogData?.defaultCategory || this.$route.params.category)
        }
      >
        <VForm ref="form">
          <VTextField
            vModel={this.fields.category}
            label="Category"
            autoFocus
            rules={[isRequired]}
          />
          <VTextField
            vModel={this.fields.count}
            type="number"
            label="Count"
            rules={[isInteger, (val: string) => isNumberInRange(val, [1, 50])]}
          />
          <VTextarea
            rows={5}
            vModel={this.fields.samples}
            label="Samples"
            spellcheck={false}
          />
        </VForm>
      </Dialog>
    );
  },
});

export default NlpTesterCategorySamplesGenerateDialog;
