import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import toggleDialog from "@/helpers/toggleDialog";

const VariableStorageGenerateKeyDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.VARIABLE_STORAGE_API_KEY);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.VARIABLE_STORAGE_GENERATE_KEY,
      Actions.VARIABLE_STORAGE_FETCH_KEY,
    ]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.VARIABLE_STORAGE_API_KEY}
        title="Confirmation"
        width={550}
        closeOnEsc
        actions={[
          {
            title: "Confirm",
            icon: "mdi-check",
            id: Actions.VARIABLE_STORAGE_FETCH_KEY,
            action: async () => {
              await this.VARIABLE_STORAGE_GENERATE_KEY(
                this.instanceData.idTenant
              );
              toggleDialog(Dialogs.VARIABLE_STORAGE_API_KEY);
            },
          },
        ]}
      >
        Do you really want to generate new API key? Current one will be
        deactivated which may break some funcionality, which is connected to it.
      </Dialog>
    );
  },
});

export default VariableStorageGenerateKeyDialog;
