import ExternalLinks from "@/constants/externalLinks";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import Vue, { VNode } from "vue";
import TileMenu from "../TileMenu";
import hasUserPermissions from "@/helpers/hasUserPermissions";

const AdministrationGuide = Vue.extend({
  data: () => ({
    sections: [
      {
        title: "Administration",
        items: [
          {
            title: "Components",
            icon: "mdi-puzzle",
            route: Routes.COMPONENTS,
          },
          {
            title: "Tenants",
            icon: "mdi-account-group",
            route: Routes.TENANTS_OVERVIEW,
          },
          {
            title: "Users",
            icon: "mdi-account-details",
            route: Routes.USERS_OVERVIEW,
          },
          {
            title: "Permission groups",
            icon: "mdi-account-key",
            route: Routes.PERMISSION_GROUPS_OVERVIEW,
            permissions: [
              Namespaces.ACCESS_MANAGEMENT,
              [Permissions.VIEW_ROLE],
            ],
          },
          {
            title: "Modules",
            icon: "mdi-application-cog-outline",
            route: Routes.MODULE_OVERVIEW,
          },
          {
            title: "Lines",
            icon: "mdi-deskphone",
            route: Routes.LINES_OVERVIEW,
          },
          {
            title: "Grammars",
            icon: "mdi-alphabetical",
            route: Routes.GRAMMARS_OVERVIEW,
          },
          {
            title: "Tenant statistics",
            icon: "mdi-chart-bar",
            route: Routes.TENANT_STATISTICS_ALL,
            permissions: [
              Namespaces.STATISTICS,
              [Permissions.VIEW_ALL_CLIENTS],
            ],
          },
          {
            title: "Billing",
            icon: "mdi-currency-usd",
            route: Routes.BILLING_OVERVIEW,
            permissions: [Namespaces.STATISTICS, [Permissions.BILLING_VIEW]],
          },
          {
            title: "Languages",
            icon: "mdi-translate",
            route: Routes.LANGUAGES_OVERVIEW,
            permissions: [Namespaces.LANGUAGE, [Permissions.VIEW]],
          },
          {
            title: "Tags",
            icon: "mdi-tag-multiple",
            route: Routes.TAGS,
          },
          {
            title: "Variables storage",
            icon: "mdi-application-variable-outline",
            route: Routes.VARIABLES_STORAGE,
            permissions: [Namespaces.TENANT_VALUE, [Permissions.VIEW]],
          },
        ],
      },
      {
        title: "Tools",
        items: [
          {
            title: "Conversation Details",
            icon: "mdi-database-search",
            route: Routes.CONVERSATION_DETAILS,
          },
          {
            title: "Designer manual",
            icon: "mdi-book-open-variant",
            route: ExternalLinks.DESIGNER_MANUAL,
            isExternal: true,
          },
          {
            title: "TTS generator",
            icon: "mdi-speaker-message",
            route: Routes.TTS_GENERATOR,
          },
          {
            title: "Packages manager",
            icon: "mdi-package-variant-closed",
            route: Routes.PACKAGE_MANAGER_INSTALL,
          },
          {
            title: "Bot deploy",
            icon: "mdi-server",
            route: Routes.BOTDEPLOY_MODULES,
            permissions: [Namespaces.BOTDPELOY, [Permissions.MODULE_VIEW]],
          },
          ...(hasUserPermissions(Namespaces.NLP, [Permissions.DATASET_VIEW]) ||
          hasUserPermissions(Namespaces.NLP, [Permissions.MODEL_VIEW])
            ? [
                {
                  title: "NLP training tool",
                  icon: "mdi-head-cog-outline",
                  route: Routes.NLP_TESTER,
                },
              ]
            : []),
        ],
      },
    ],
  }),

  render(): VNode {
    return (
      <div>
        {this.sections.map(({ title, items }: any) => (
          <TileMenu title={title} items={items} cssClass="mb-7" />
        ))}
      </div>
    );
  },
});

export default AdministrationGuide;
