import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import Dialogs from "@/constants/dialogs";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import toggleDialog from "@/helpers/toggleDialog";
import VariablesStorageTimetableForm from "../VariablesStorageTimetableForm";
import VariableStorageVariableForm from "../VariableStorageVariableForm";

const VariablesStorageCreateDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    metaData(): any {
      return this.GET_DIALOG_DATA(Dialogs.VARIABLE_STORAGE_CREATE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.VARIABLE_STORAGE_FETCH_ALL]),

    async handleSubmit() {
      const refs: any = this.$refs;
      const isFormSubmited = await refs.form.submit(this.metaData);

      if (isFormSubmited) {
        toggleDialog(Dialogs.VARIABLE_STORAGE_CREATE);
        this.VARIABLE_STORAGE_FETCH_ALL(this.metaData.idTenant);
      }
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.VARIABLE_STORAGE_CREATE}
        title={
          this.metaData.type === 1
            ? "New timetable variable"
            : "New custom variables"
        }
        width={600}
        actions={[
          {
            title: "Confirm",
            icon: "mdi-check",
            action: () => this.handleSubmit(),
            id: Actions.VARIABLES_STORAGE_CREATE,
          },
        ]}
        disableDefaultErrors
      >
        {this.metaData.type === 0 && <VariableStorageVariableForm ref="form" />}
        {this.metaData.type === 1 && (
          <VariablesStorageTimetableForm ref="form" />
        )}
      </Dialog>
    );
  },
});

export default VariablesStorageCreateDialog;
