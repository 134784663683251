import Vue, { VNode } from "vue";

const RouterNavigation = Vue.extend({
  props: {
    routerKey: String,
  },

  render(): VNode {
    return <router-view key={this.routerKey}></router-view>;
  },
});

export default RouterNavigation;
