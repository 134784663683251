import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const AudioRecordRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.DIAL_LOG_AUDIO_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.AUDIO_RECORD_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.DIAL_LOG_AUDIO_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            id: Actions.AUDIO_RECORD_REMOVE,
            action: () =>
              this.AUDIO_RECORD_REMOVE({
                guid: this.instanceData.guid,
                withFullConverstaion: this.instanceData.withFullConverstaion,
              }),
          },
        ]}
      >
        Do you really want to remove audio record?
      </Dialog>
    );
  },
});

export default AudioRecordRemoveDialog;
