import { ActionTree } from "vuex";
import InstancesState from "@/models/store/InstancesState";
import instanceActions from "./instanceActions";
import userActions from "./userActions";
import tenantActions from "./tenantActions";
import moduleActions from "./moduleActions";
import grammarActions from "./grammarActions";
import lineActions from "./lineActions";
import flowActions from "./flowActions";
import permissionGroupActions from "./permissionGroupActions";
import campaignActions from "./campaignActions";
import botDeployActions from "./botDeployActions";
import nlpTesterActions from "./nlpTesterActions";
import languageActions from "./languageActions";
import variableStorageActions from "./variableStorageActions";

const actions: ActionTree<InstancesState, []> = {
  ...instanceActions,
  ...lineActions,
  ...grammarActions,
  ...moduleActions,
  ...flowActions,
  ...tenantActions,
  ...userActions,
  ...campaignActions,
  ...permissionGroupActions,
  ...botDeployActions,
  ...nlpTesterActions,
  ...languageActions,
  ...variableStorageActions,
};

export default actions;
