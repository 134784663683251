import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Actions, Getters, Modules, Mutations } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import { VChip, VIcon } from "vuetify/lib";
import BillingTariffRemoveDialog from "../BillingTariffRemoveDialog";
import {
  parseTarrif,
  getTariffUnitAndIcon,
  getTariffConfig,
} from "@/helpers/billingTariffs";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const BillingTariffsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.TENANT_TARRIFS_DATAGRID_FETCH]),
    ...mapMutations(Modules.DIALOGS, [Mutations.TOGGLE_DIALOG]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      key: "guid",
      title: "Tariffs",
      isHeaderOutside: false,
      disableElevation: true,
      rowActions: (row) => ({
        primary: {
          action: () => toggleDialog(Dialogs.BILLING_TARIFF_EDIT, { ...row }),
          icon: "mdi-pencil",
          title: "Edit",
          isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
            Permissions.TARIFF_EDIT,
          ]),
        },
        secondary: [
          {
            action: () =>
              toggleDialog(Dialogs.BILLING_TARIFF_REMOVE, { ...row }),
            icon: "mdi-delete",
            title: "Remove",
            isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
              Permissions.TARIFF_EDIT,
            ]),
          },
        ],
      }),
      headers: [
        { text: "Name", value: "name" },
        { text: "Group", value: "group" },
        { text: "Call price", value: "callPrice" },
        { text: "Round interval / Min Duration", value: "callMeta" },
        { text: "SMS price", value: "smsPrice" },
      ],
      buttons: [
        {
          title: "New tariff",
          icon: "mdi-plus",
          action: () => toggleDialog(Dialogs.BILLING_TARIFF_EDIT, {}),
          isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
            Permissions.TARIFF_EDIT,
          ]),
        },
      ],
      columnModificators: {
        "item.name": ({ item }) => item.tariff.description,
        "item.group": ({ item }) => {
          const tariffConfig = getTariffConfig(parseTarrif(item).group);
          return tariffConfig ? (
            <VChip color={tariffConfig.color}>
              <VIcon left>{tariffConfig.icon}</VIcon>
              {tariffConfig.text}
            </VChip>
          ) : (
            <VChip>
              <VIcon left>mdi-help-circle-outline</VIcon>
              Unknown
            </VChip>
          );
        },
        "item.callPrice": ({ item }) => {
          const tariff = parseTarrif(item);
          const tariffMeta = getTariffUnitAndIcon(
            item?.tariff?.type,
            tariff?.divider
          );

          return (
            tariff?.price && (
              <span>
                <VIcon
                  class="mr-1"
                  small
                  color={tariffMeta?.color}
                >{`mdi-${tariffMeta?.icon}`}</VIcon>
                <strong>
                  {tariff?.price?.toLocaleString("cs")} {tariff.currency}
                </strong>{" "}
                / {tariffMeta?.unit}
              </span>
            )
          );
        },
        "item.callMeta": ({ item }) => {
          const tariff = parseTarrif(item);

          return item.tariff?.type === 0
            ? tariff?.minDuration
            : tariff?.roundInterval;
        },
        "item.divider": ({ item }) => parseTarrif(item).divider,
        "item.smsPrice": ({ item }) => {
          const tariff = parseTarrif(item);

          return (
            tariff.smsPrice && (
              <strong>
                {tariff.smsPrice} {tariff.currency}
              </strong>
            )
          );
        },
      },
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.TENANT_TARRIFS_DATAGRID_FETCH}
          fetchProps={{
            idTenant: this.$route.params.idTenant,
          }}
          data={this.GET_DATAGRID_DATA(DataGrids.TENANT_TARIFFS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(
            Actions.TENANT_TARRIFS_DATAGRID_FETCH
          )}
        />
        <BillingTariffRemoveDialog />
      </div>
    );
  },
});

export default BillingTariffsDataGrid;
