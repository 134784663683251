import Vue, { VNode } from "vue";
import { VAutocomplete } from "vuetify/lib";

const NlpDatasetsSelect = Vue.extend({
  props: {
    value: String,
    options: Object,
  },

  data: () => ({
    items: [
      "Sanitize",
      "SpellCheck",
      "Lemmatize",
      "LemmatizeRoots",
      "RemoveStopWords",
      "RemoveDiacritics",
      "LowerCase",
      "Tokenize",
    ],
  }),

  render(): VNode {
    return (
      <VAutocomplete
        label="Preprocess Options"
        items={this.items.map((item: string): any => ({
          text: item,
          value: item,
        }))}
        multiple
        smallChips
        deletableChips
        onInput={(val: string) => this.$emit("input", val)}
        value={this.value}
      />
    );
  },
});

export default NlpDatasetsSelect;
