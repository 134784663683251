import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const LanguageCodeSelect = Vue.extend({
  props: {
    value: [String, Array],
    options: Object,
  },

  methods: {
    transformToUniqueLanguageCodes(languages: any[]): any[] {
      const allLangCodes: any[] = [];

      languages.map((language) => {
        language?.ttsSettings.map((ttsSettings: any) =>
          ttsSettings?.voices.map((voice: any) => allLangCodes.push(voice.code))
        );
      });

      const uniqueCodeLanguages = [...new Set(allLangCodes)].sort();

      return uniqueCodeLanguages.map((code) => ({ text: code, value: code }));
    },
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.LANGUAGE_CODES}
        label="Language"
        fetchUrl={ApiEndpoints.LANGUAGES_STRUCTURE}
        dataModificator={this.transformToUniqueLanguageCodes}
        onInput={(val: string) => this.$emit("input", val)}
        value={this.value}
        options={this.options}
      />
    );
  },
});

export default LanguageCodeSelect;
