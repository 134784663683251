import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import { ActionTree } from "vuex";

const variableStorageActions: ActionTree<InstancesState, []> = {
  [Actions.VARIABLE_STORAGE_FETCH_ALL]: async ({ state, commit }, idTenant) => {
    const storageRes = await createRequest({
      action: Actions.VARIABLE_STORAGE_FETCH_ALL,
      method: "GET",
      url: ApiEndpoints.VARIABLES_STORAGE_ALL,
      params: { idTenant },
    });

    const { data, errors } = parseResponse(storageRes);

    if (errors.length > 0) {
      pushNotify(Notify.ERROR, "Error while fetching storage variables");
      return;
    }

    const groupedVariables = data.reduce((r: any, a: any) => {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, Object.create(null));

    commit(Mutations.SET_INSPECTED_VARIABLES_STORAGE, {
      ...state.inspectedVariablesStorage,
      variables: groupedVariables,
    });
  },

  [Actions.VARIABLE_STORAGE_FETCH_KEY]: async ({ state, commit }, idTenant) => {
    const keyRes = await createRequest({
      action: Actions.VARIABLE_STORAGE_FETCH_KEY,
      method: "GET",
      url: ApiEndpoints.VARIABLE_STORAGE_KEY,
      params: { idTenant },
    });

    const { data, errors } = parseResponse(keyRes);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_VARIABLES_STORAGE, {
        ...state.inspectedVariablesStorage,
        key: data,
      });
    }
  },

  [Actions.VARIABLE_STORAGE_GENERATE_KEY]: async ({ dispatch }, idTenant) => {
    const keyRes = await createRequest({
      action: Actions.VARIABLE_STORAGE_FETCH_KEY,
      method: "POST",
      url: ApiEndpoints.VARIABLE_STORAGE_GENERATE_KEY,
      params: { idTenant },
    });

    const { errors } = parseResponse(keyRes);

    if (errors.length === 0) {
      dispatch(Actions.VARIABLE_STORAGE_FETCH_KEY, idTenant);
    }
  },

  [Actions.VARIABLE_STORAGE_REMOVE]: async (
    { dispatch },
    { key, idTenant }
  ) => {
    const removeVarRes = await createRequest({
      action: Actions.VARIABLE_STORAGE_REMOVE,
      method: "DELETE",
      url: ApiEndpoints.VARIABLE_STORAGE_VALUE,
      params: { key, idTenant },
    });

    const { errors } = parseResponse(removeVarRes);

    if (errors.length === 0) {
      toggleDialog(Dialogs.VARIABLE_STORAGE_REMOVE, {});
      dispatch(Actions.VARIABLE_STORAGE_FETCH_ALL, idTenant);
    }
  },

  [Actions.VARIABLE_STORAGE_EDIT]: async ({ dispatch }, params) => {
    const editVarRes = await createRequest({
      action: Actions.VARIABLE_STORAGE_EDIT,
      method: "POST",
      url: ApiEndpoints.VARIABLE_STORAGE_EDIT,
      data: params,
    });

    const { errors } = parseResponse(editVarRes);

    if (errors.length === 0) {
      toggleDialog(Dialogs.VARIABLE_STORAGE_EDIT, {});
      dispatch(Actions.VARIABLE_STORAGE_FETCH_ALL, params.idTenant);
    }
  },
};

export default variableStorageActions;
