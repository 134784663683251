import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import DynamicSelect from "../DynamicSelect";

const LanguageSelect = Vue.extend({
  props: {
    value: [String, Array],
    options: Object,
    useModificator: {
      type: Boolean,
      default: true,
    },
    canSelectAll: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(Modules.RESOURCES, [Getters.LANGUAGES]),
  },

  methods: {
    ...mapActions(Modules.RESOURCES, [Actions.LANGUAGES_FETCH]),

    async transformToLanguageKeys(): Promise<any[]> {
      if (this.LANGUAGES.length === 0) {
        await this.LANGUAGES_FETCH();
      }

      return this.LANGUAGES.map(({ id, name }: any) => ({
        text: name,
        value: id,
      }));
    },
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.LANGUAGES}
        label="Language"
        fetchUrl={ApiEndpoints.LANGUAGES_LIST}
        onInput={(val: string) => this.$emit("input", val)}
        dataModificator={
          this.useModificator ? this.transformToLanguageKeys : null
        }
        useCache={false}
        value={this.value}
        options={this.options}
        canSelectAll={this.canSelectAll}
      />
    );
  },
});

export default LanguageSelect;
