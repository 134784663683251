import ApiEndpoints from "@/constants/apiEndpoints";
import { TARIFF_GROUPS } from "@/constants/tariffs";
import createRequest from "@/helpers/createRequest";
import {
  formatDateForDatePicker,
  getFirstDayCurrentMonth,
  getLastDayCurrentMonth,
} from "@/helpers/dateAndTimeUtils";
import parseResponse from "@/helpers/parseResponse";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCardText,
  VCol,
  VDatePicker,
  VDivider,
  VMenu,
  VRow,
  VSkeletonLoader,
  VTextField,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import BillingOverviewDataGrid from "../BillingOverviewDataGrid/BillingOverviewDataGrid";
import SectionToolbar from "../SectionToolbar";
import toggleDialog from "@/helpers/toggleDialog";
import CsvExportDialog from "../CsvExportDialog";
import Dialogs from "@/constants/dialogs";

const BillingOverview = Vue.extend({
  name: "BillingOverview",
  data: () => ({
    dates: ["", ""] as any,
    isDatepickerActive: false,
    overviewData: {} as any,
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    dateTextRange(): string {
      return this.dates.join(" ~ ");
    },

    summaryByGroups(): any {
      if (!this.overviewData.summary) {
        return {};
      }

      return this.overviewData.summary.reduce((acc: any, d: any) => {
        if (Object.keys(acc).includes(d.group)) {
          return acc;
        }

        acc[d.group] = this.overviewData.summary.filter(
          (g: any) => g.group === d.group
        );
        return acc;
      }, {});
    },
  },

  methods: {
    async fetchBillingOverview() {
      if (!this.$route.params.idTenant) {
        return;
      }

      const statisticsRes = await createRequest({
        action: Actions.TENANT_BILLING_FETCH,
        url: `${ApiEndpoints.BILLING_INSTANCE_OVERVIEW}/${this.$route.params.idTenant}`,
        method: "GET",
        params: {
          from: new Date(`${this.dates[0]}T00:00:00`).toISOString(),
          to: new Date(`${this.dates[1]}T00:00:00`).toISOString(),
          app: this.$route.query.app ?? "master",
        },
      });

      const { data } = parseResponse(statisticsRes);
      this.overviewData = data ?? {};
    },

    getGroupConfig(groupId: string): any {
      const groupConfig = TARIFF_GROUPS.find(
        (tariff: any) => tariff.value === parseInt(groupId)
      );

      return groupConfig;
    },
  },

  created() {
    this.dates = [
      this.$route.query.from ??
        formatDateForDatePicker(getFirstDayCurrentMonth()),
      this.$route.query.to ?? formatDateForDatePicker(getLastDayCurrentMonth()),
    ];

    this.fetchBillingOverview();
  },

  render(): VNode {
    return (
      <div class="tenant-billing">
        <VCard elevation={0}>
          <SectionToolbar title="Overview" />
          <VCardText>
            <VMenu
              vModel={this.isDatepickerActive}
              offsetY
              transition="scale-transition"
              closeOnContentClick={false}
              maxWidth="290"
              scopedSlots={{
                activator: ({ on, attrs }: any) => (
                  <VRow>
                    <VCol md={6}>
                      <VTextField
                        vModel={this.dateTextRange}
                        label="Select range"
                        readonly
                        prependIcon="mdi-calendar"
                        outlined
                        dense
                        {...{ on, attrs }}
                      />
                    </VCol>
                  </VRow>
                ),
              }}
            >
              <VDatePicker
                vModel={this.dates}
                onInput={(val: string) => {
                  if (val.length === 2) {
                    this.isDatepickerActive = false;
                    this.fetchBillingOverview();
                    this.$router.push({ query: { from: val[0], to: val[1] } });
                  }
                }}
                range
                firstDayOfWeek={1}
              />
            </VMenu>
          </VCardText>
        </VCard>

        {this.IS_ACTION_FETCHING(Actions.TENANT_BILLING_FETCH) ? (
          <VSkeletonLoader type="table" />
        ) : (
          <div>
            <VCard elevation={0}>
              <SectionToolbar
                title="Summary"
                actions={[
                  {
                    action: () =>
                      toggleDialog(Dialogs.FLOW_CSV_EXPORT, {
                        range: [this.dates[0], this.dates[1]],
                        idProperty: this.$route.params.idTenant,
                        fetchParams: { idTenant: this.$route.params.idTenant },
                      }),
                    title: "CSV export",
                    icon: "mdi-download",
                  },
                ]}
              />
              <VCardText>
                {JSON.stringify(this.summaryByGroups) === "{}" ? (
                  <span>No billing records found.</span>
                ) : (
                  <div>
                    {Object.entries(this.summaryByGroups).map(
                      ([groupId, groupSummary]) => {
                        const groupConfig = this.getGroupConfig(groupId);

                        return (
                          <BillingOverviewDataGrid
                            title={groupConfig?.text ?? "Unknown"}
                            icon={
                              groupConfig?.icon ?? "mdi-help-circle-outline"
                            }
                            data={groupSummary ?? []}
                          />
                        );
                      }
                    )}
                  </div>
                )}
              </VCardText>
            </VCard>
            {this.overviewData?.modules?.length > 0 && (
              <div>
                <VDivider class="mt-10 mb-3" />
                <VCard elevation={0}>
                  <SectionToolbar title="Modules and lines" />
                  <VCardText>
                    {this.overviewData?.modules?.map(
                      ({ module, lines }: any) => (
                        <BillingOverviewDataGrid
                          title={module}
                          data={lines}
                          appendHeaders={[{ text: "Line", value: "line" }]}
                        />
                      )
                    )}
                  </VCardText>
                </VCard>
              </div>
            )}
          </div>
        )}
        <CsvExportDialog
          name={Dialogs.FLOW_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_BILLING_OVERVIEW}
          prefix="billing"
        />
      </div>
    );
  },
});

export default BillingOverview;
