import { MutationTree } from "vuex";
import { Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";

const mutations: MutationTree<InstancesState> = {
  [Mutations.SET_INSPECTED_MODULE]: (state, payload) => {
    state.inspectedModule = payload;
  },

  [Mutations.SET_INSPECTED_LINE]: (state, payload) => {
    state.inspectedLine = payload;
  },

  [Mutations.SET_INSPECTED_FLOW]: (state, payload) => {
    state.inspectedFlow = payload;
  },

  [Mutations.SET_INSPECTED_GRAMMAR]: (state, payload) => {
    state.inspectedGrammar = payload;
  },

  [Mutations.SET_INSPECTED_TENANT]: (state, payload) => {
    state.inspectedTenant = payload;
  },

  [Mutations.SET_INSPECTED_USER]: (state, payload) => {
    state.inspectedUser = payload;
  },

  [Mutations.SET_INSPECTED_PERMISSION_GROUP]: (state, payload) => {
    state.inspectedPermissionGroup = payload;
  },

  [Mutations.SET_INSPECTED_CAMPAIGN]: (state, payload) => {
    state.inspectedCampaign = payload;
  },

  [Mutations.SET_INSPECTED_BOTDEPLOY_MODULE]: (state, payload) => {
    state.inspectedBotDeployModule = payload;
  },

  [Mutations.SET_INSPECTED_BOTDEPLOY_SERVER]: (state, payload) => {
    state.inspectedBotDeployServer = payload;
  },

  [Mutations.SET_INSPECTED_BOTDEPLOY_CONFIG]: (state, payload) => {
    state.inspectedBotDeployConfig = payload;
  },

  [Mutations.SET_INSPECTED_NLP_CATEGORY]: (state, payload) => {
    state.inspectedNlpCategory = payload;
  },

  [Mutations.SET_INSPECTED_LANGUAGE]: (state, payload) => {
    state.inspectedLanguage = payload;
  },

  [Mutations.SET_INSPECTED_VARIABLES_STORAGE]: (state, payload) => {
    state.inspectedVariablesStorage = payload;
  },
};

export default mutations;
