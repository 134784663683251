import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import NlpTesterCategorySamplesGenerateDialog from "../NlpTesterCategorySamplesGenerateDialog";

const NlpTesterCategoryDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_NLP_CATEGORY]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.NLP_CATEGORY_INIT]),
  },

  render(): VNode {
    const { datasetId, language, category } = this.$route.params;

    return (
      <div>
        <DetailWithTabs
          title={`Category - ${this.$route.params?.category || ""}`}
          defaultReturnRoute={Routes.NLP_TESTER_CATEGORIES}
          defaultReturnRouteParams={{ datasetId, language }}
          initDataFetch={() =>
            this.NLP_CATEGORY_INIT({ datasetId, language, category })
          }
          tabs={[
            {
              title: "General",
              path: Routes.NLP_TESTER_CATEGORY_DETAIL_BASIC,
            },
          ]}
        />
        <NlpTesterCategorySamplesGenerateDialog />
      </div>
    );
  },
});

export default NlpTesterCategoryDetail;
