import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const TimeZoneSelect = Vue.extend({
  props: {
    value: String,
    withBrowserOption: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    formatData(val: any): any {
      const formattedData: any[] = [{ text: "From browser", value: null }];

      for (const [key, value] of Object.entries(val)) {
        formattedData.push({
          text: value,
          value: key,
        });
      }

      return formattedData;
    },
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.TIME_ZONES}
        label="Time zones"
        fetchUrl={ApiEndpoints.TIME_ZONES_LIST}
        onInput={(val: number) => this.$emit("input", val)}
        value={this.value}
        dataModificator={(val: any) => this.formatData(val)}
      />
    );
  },
});

export default TimeZoneSelect;
