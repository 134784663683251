import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import BillingTariffEditForm from "../BillingTariffEditForm";
import Dialog from "../Dialog";

const BillingTariffConfigurationDialog = Vue.extend({
  render(): VNode {
    return (
      <Dialog
        id={Dialogs.BILLING_TARIFF_EDIT}
        title="Editation"
        actions={[
          {
            action: () => {
              const refs: any = this.$refs;
              refs.form.handleSubmit();
            },
            title: "Apply",
            icon: "mdi-check",
            id: Actions.LINE_TARIFF_EDIT,
          },
        ]}
      >
        <BillingTariffEditForm ref="form" />
      </Dialog>
    );
  },
});

export default BillingTariffConfigurationDialog;
