export const TARIFF_DIVIDER_TYPES = [
  {
    text: "Minutes",
    altText: "min",
    value: "60",
  },
  {
    text: "Seconds",
    altText: "s",
    value: "1",
  },
];

export const TARIFF_CURRENCIES = [
  {
    text: "CZK",
    value: "CZK",
  },
  {
    text: "EUR",
    value: "EUR",
  },
  {
    text: "USD",
    value: "USD",
  },
  {
    text: "PLN",
    value: "PLN",
  },
];

export const TARIFF_TYPES = [
  {
    text: "per Time",
    value: 1,
  },
  {
    text: "per Call",
    value: 2,
  },
];

export const TARIFF_GROUPS = [
  {
    text: "Routing",
    value: 0,
    icon: "mdi-routes",
    color: "success",
  },
  {
    text: "Common calls",
    value: 1,
    icon: "mdi-phone",
    color: "accent",
  },
];
