import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VIcon,
  VMenu,
  VProgressLinear,
  VSlider,
} from "vuetify/lib";

const AudioPlayer = Vue.extend({
  data: () => ({
    player: new Audio(),
    isPlaying: false,
    duration: 0,
    volume: 60,
    currentTime: 0,
  }),

  props: {
    file: String,
    autoPlay: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    playerTime: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "primary",
    },
  },

  computed: {
    position: {
      get(): number {
        return (this.currentTime / this.duration) * 100;
      },
      set(value: number) {
        this.player.currentTime = this.duration * (value / 100);
      },
    },
    volumeIcon(): string {
      if (this.volume > 66) return "mdi-volume-high";
      else if (this.volume > 33) return "mdi-volume-medium";
      else if (this.volume > 0) return "mdi-volume-low";
      else return "mdi-volume-mute";
    },
  },
  watch: {
    playerTime: function (val: number) {
      this.player.currentTime = val;
    },
    volume: function (val: number) {
      this.player.volume = val / 100;
    },
  },

  methods: {
    init() {
      this.player = new Audio(this.file);
      this.player.volume = this.volume / 100;
      this.player.onplay = () => {
        this.isPlaying = true;
      };
      this.player.onpause = () => {
        this.isPlaying = false;
      };
      this.player.onended = () => {
        this.isPlaying = false;
      };
      this.player.onloadedmetadata = () => {
        this.duration = this.player.duration;
      };
      this.player.onloadeddata = () => {
        if (this.autoPlay) this.player.play();
      };
      this.player.ontimeupdate = () => {
        this.currentTime = this.player.currentTime;
        this.$emit("update:playerTime", this.player.currentTime);
      };
    },
    togglePlay(): void {
      this.isPlaying ? this.player.pause() : this.player.play();
    },
    stop(): void {
      this.player.currentTime = 0;
      this.player.pause();
    },
    formatTime(time: number): string {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    seek(time: number, play = false): void {
      this.currentTime = time;
      this.player.currentTime = time;
      if (play) this.player.play();
    },
  },

  mounted() {
    this.init();
  },

  destroyed() {
    this.stop();
  },

  render(): VNode {
    return (
      <VCard flat={this.flat}>
        <VCardText class="d-flex justify-center">
          <VBtn
            icon
            outlined
            color={this.color}
            class="mx-2"
            onClick={(e: Event) => {
              e.preventDefault();
              this.togglePlay();
            }}
          >
            <VIcon>{this.isPlaying ? "mdi-pause" : "mdi-play"}</VIcon>
          </VBtn>
          <VBtn
            icon
            outlined
            color={this.color}
            class="mx-2"
            onClick={(e: Event) => {
              e.preventDefault();
              this.stop();
            }}
          >
            <VIcon>mdi-stop</VIcon>
          </VBtn>
          <VMenu
            close-on-content-click={false}
            offsetY
            top
            nudgeLeft={80}
            nudgeTop={5}
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <VBtn
                  icon
                  outlined
                  color={this.color}
                  class="mx-2"
                  {...{ on, attrs }}
                >
                  <VIcon>{this.volumeIcon}</VIcon>
                </VBtn>
              ),
            }}
          >
            <VCard width="200">
              <VSlider
                hide-details
                color={this.color}
                density="compact"
                min="0"
                max="100"
                step="5"
                v-model={this.volume}
              />
            </VCard>
          </VMenu>
        </VCardText>
        <VProgressLinear v-model={this.position}></VProgressLinear>
        <VCardText class="d-flex  justify-center align-center py-2">
          {this.formatTime(this.currentTime)} / {this.formatTime(this.duration)}
        </VCardText>
      </VCard>
    );
  },
});

export default AudioPlayer;
