import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VForm,
  VIcon,
  VTextField,
  VTextarea,
} from "vuetify/lib";
import { isRequired } from "@/helpers/validations";
import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import { Actions } from "@/models/store";

const VariableStorageVariableForm = Vue.extend({
  data: () => ({
    variables: [{ key: "", value: "" }],
    isFormValid: true,
    errors: [],
  }),

  methods: {
    addVariable() {
      this.variables.push({ key: "", value: "" });
    },

    removeVariable(index: number) {
      this.variables.splice(index, 1);
    },

    async submit(metaData: any) {
      this.errors = [];

      const { isFormSubmited, errors } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.VARIABLES_STORAGE_ALL,
        values: this.variables.map((variable) => ({
          ...variable,
          ...metaData,
        })),
        actionId: Actions.VARIABLES_STORAGE_CREATE,
      });

      if (errors[0]?.rawData) {
        this.errors = errors[0]?.rawData;
      }

      return isFormSubmited;
    },
  },

  render(): VNode {
    return (
      <div>
        <VForm vModel={this.isFormValid} ref="form">
          {this.variables.map((_, index) => (
            <VCard class="mb-5">
              {this.variables.length > 1 && (
                <VBtn
                  class="error--text"
                  small
                  text
                  style="position: absolute; right: 0; top: 5px;"
                  onClick={() => this.removeVariable(index)}
                >
                  <VIcon>mdi-delete-forever</VIcon>
                </VBtn>
              )}
              <VCardText>
                <VTextField
                  vModel={this.variables[index].key}
                  label="Key"
                  rules={[isRequired]}
                />
                <VTextarea
                  vModel={this.variables[index].value}
                  label="Value"
                  rows={1}
                  autoGrow
                  rules={[isRequired]}
                />
              </VCardText>
            </VCard>
          ))}
        </VForm>
        <div class="text-center">
          <VBtn
            onClick={this.addVariable}
            small
            class="tertiary secondary--text"
          >
            <VIcon left>mdi-plus</VIcon>Add variable
          </VBtn>
        </div>
        {this.errors.length > 0 && (
          <div class="error--text mt-4 d-flex align-center">
            <VIcon class="pr-1 error--text">mdi-alert-octagon</VIcon>
            Variable {this.errors.length === 1 ? "key" : "keys"}{" "}
            {this.errors.map((error: string) => `"${error}"`).join(", ")}{" "}
            {this.errors.length === 1 ? "is" : "are"} already used for this
            tenant.
          </div>
        )}
      </div>
    );
  },
});

export default VariableStorageVariableForm;
