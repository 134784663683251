import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const LineSelect = Vue.extend({
  name: "LineSelect",
  props: {
    value: [String, Number],
    options: Object,
    fetchUrl: {
      type: String,
      default: ApiEndpoints.LINE_LIST,
    },
    useModificator: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    modifyDropdownData(data: any) {
      return Object.entries(data).map(([module, text]) => ({
        value: module,
        text,
      }));
    },
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.LINES}
        label="Line"
        options={this.options}
        fetchUrl={this.fetchUrl}
        dataModificator={this.useModificator ? this.modifyDropdownData : null}
        onInput={(val: number) => this.$emit("input", val)}
        value={this.value}
        isValueNumber
      />
    );
  },
});

export default LineSelect;
