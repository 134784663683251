import Vue, { VNode } from "vue";
import { mapActions } from "vuex";
import { VApp } from "vuetify/lib";

import Header from "@/components/Header";
import Content from "@/components/Content";
import Footer from "@/components/Footer";
import { Modules, Actions } from "@/models/store";

const Root = Vue.extend({
  methods: {
    ...mapActions(Modules.USER, [Actions.FETCH_API_KEY]),
  },
  created() {
    this.FETCH_API_KEY();
  },

  render(): VNode {
    return (
      <VApp class={process.env.VUE_APP_THEME}>
        <Header />
        <Content />
        <Footer />
      </VApp>
    );
  },
});

export default Root;
