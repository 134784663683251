import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import Routes from "@/constants/routes";
import LanguageCodeSelect from "../LanguageCodeSelect";

const NlpTesterDatasetsCreateForm = Vue.extend({
  data: () => ({
    fields: {
      datasetId: "",
      language: "en-GB",
    },
    isFormValid: true,
  }),

  methods: {
    async handleSubmit() {
      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: `${ApiEndpoints.NLP_TESTER_DATASET}/${this.fields.language}`,
        values: this.fields,
        actionId: Actions.NLP_TESTER_DATASET_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.NLP_TESTER_DATASET_CREATE, {});

        this.$router.push({
          name: Routes.NLP_TESTER_CATEGORIES,
          params: {
            language: this.fields.language,
            datasetId: this.fields.datasetId,
          },
        });

        pushNotify(Notify.SUCCESS, "Dataset created successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields.datasetId}
          label="Name"
          rules={[isRequired]}
        />
        <LanguageCodeSelect vModel={this.fields.language} />
      </VForm>
    );
  },
});

export default NlpTesterDatasetsCreateForm;
