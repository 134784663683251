import Dropdowns from "@/constants/dropdowns";
import DropdownsState from "@/models/store/DropdownsState";

const state: DropdownsState = {
  [Dropdowns.MODULES]: [],
  [Dropdowns.FLOW]: [],
  [Dropdowns.FLOW_OVERVIEW]: [],
  [Dropdowns.TENANTS]: [],
  [Dropdowns.LANGUAGES]: [],
  [Dropdowns.NOTIFY_GROUPS]: [],
  [Dropdowns.GRAMMARS]: [],
  [Dropdowns.COMPONENTS]: [],
  [Dropdowns.LINES]: [],
  [Dropdowns.BOTDEPLOY_SERVERS]: [],
  [Dropdowns.BOTDEPLOY_CONFIGS]: [],
  [Dropdowns.NLP_DATASETS]: [],
  [Dropdowns.LANGUAGE_CODES]: [],
  [Dropdowns.TAGS]: [],
  [Dropdowns.TARIFFS]: [],
  [Dropdowns.INFRASTRUCTURE_TENANTS]: [],
  [Dropdowns.FLOW_LANGUAGES]: [],
  [Dropdowns.COMPONENT_TYPES]: [],
  [Dropdowns.TIME_ZONES]: [],
};

export default state;
