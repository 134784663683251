import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";

import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VAutocomplete } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const TenantInfrastructureSelect = Vue.extend({
  props: {
    value: Object,
  },

  data: () => ({
    instance: "",
    tenantId: "",
  }),

  computed: {
    ...mapGetters(Modules.DROPDOWNS, [Getters.GET_DROPDOWN_DATA]),

    dropdownData(): any {
      return this.GET_DROPDOWN_DATA(Dropdowns.INFRASTRUCTURE_TENANTS);
    },

    instancesList(): string[] {
      return this.dropdownData.map(({ instanceUrl }: any) => instanceUrl) ?? [];
    },

    instanceTenantsList(): any[] {
      const currentInstance = this.dropdownData.find(
        ({ instanceUrl }: any) => instanceUrl === this.instance
      );

      if (!currentInstance) {
        return [];
      }

      return Object.entries(currentInstance?.data).map(([key, val]) => ({
        text: val,
        value: key,
      }));
    },
  },

  methods: {
    ...mapActions(Modules.DROPDOWNS, [Actions.DROPDOWN_DATA_FETCH]),

    getValue(): any {
      return { idTenant: this.tenantId, instance: this.instance };
    },
  },

  created() {
    this.instance = this.value?.instance ?? "master";
    this.tenantId = this.value?.idTenant ?? "";

    this.DROPDOWN_DATA_FETCH({
      dropdown: Dropdowns.INFRASTRUCTURE_TENANTS,
      url: ApiEndpoints.INSTANCES_TENANT_LIST,
      dataModificator: (data: any) => data,
    });
  },

  render(): VNode {
    return (
      <div>
        <VAutocomplete
          label="Instance"
          items={this.instancesList}
          vModel={this.instance}
        />
        <VAutocomplete
          label="Tenant"
          items={this.instanceTenantsList}
          vModel={this.tenantId}
          disabled={!this.instance}
        />
      </div>
    );
  },
});

export default TenantInfrastructureSelect;
