import TagColor from "@/models/TagColor";

const TAG_COLOR_PALETTE: TagColor[] = [
  { id: "0", bg: "primary", text: "white" },
  { id: "1", bg: "secondary", text: "primary" },
  { id: "2", bg: "tertiary", text: "white" },
  { id: "3", bg: "success", text: "white" },
  { id: "4", bg: "warning", text: "white" },
  { id: "5", bg: "error", text: "white" },
  { id: "6", bg: "accent", text: "white" },
  { id: "7", bg: "gray", text: "primary" },
];

export default TAG_COLOR_PALETTE;
