const DataGrids = {
  DIAL_MODULES: "DIAL_MODULES",
  LINES: "LINES",
  DIAL_LOGS: "DIAL_LOGS",
  LINE_QUEUE: "LINE_QUEUE",
  CALL_SCRIPTS: "CALL_SCRIPTS",
  FLOW_VERSION_HISTORY: "FLOW_VERSION_HISTORY",
  CAMPAIGNS: "CAMPAIGNS",
  GRAMMARS: "GRAMMARS",
  USERS: "USERS",
  TENANTS: "TENANTS",
  TENANT_TARIFFS: "TENANT_TARIFFS",
  LINE_TARIFFS: "LINE_TARIFFS",
  AUDIT: "AUDIT",
  PERMISSION_GROUPS: "PERMISSION_GROUPS",
  BOTDEPLOY_MODULES: "BOTDEPLOY_MODULES",
  BOTDEPLOY_SERVERS: "BOTDEPLOY_SERVERS",
  BOTDEPLOY_CONFIGS: "BOTDEPLOY_CONFIGS",
  NLP_TESTER_DATASETS: "NLP_TESTER_DATASETS",
  NLP_TESTER_CATEGORIES: "NLP_TESTER_CATEGORIES",
  NLP_TESTER_MODELS: "NLP_TESTER_MODELS",
  LANGUAGES: "LANGUAGES",
  TAGS: "TAGS",
  CONVERSATION_LOGS: "CONVERSATION_LOGS",
};

export default DataGrids;
